import { FC } from 'react';
import { RadarHeader } from '../components/RadarHeader';
import styles from './styles.module.scss';

export const RadarSignals: FC = () => {
  return (
    <div className={styles.pageWrapper}>
      <RadarHeader title="Signals" />
      <div className={styles.pageContent}>
        <div className={styles.placeholder}>No new signals identified (yet).</div>
      </div>
    </div>
  );
};

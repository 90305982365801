import { DocumentReviewers } from 'components/Transactions';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';

import { InputLabel, Spinner } from 'components';

import {
  getClientsEffect,
  getTransactionDocumentEffect,
  getTransactionDocumentsFilterEffect,
  requestGetTeamListEffect,
  updateTransactionDocumentPermissionEffect,
} from 'store/effects';
import { getTransactionSelector } from 'store/selectors/transaction';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FilePermissionsMultiSelect } from './FilePermissionsMultiSelect';
import { getShareDrawerInfoSelector } from 'pages/Properties/Feed/Drawers/ShareDrawer/selectors';
import { READY } from 'settings/constants/apiState';
import { getFilteredClients } from './helper';
import { showSuccessMessage } from 'helpers';
import { getUserId, getUserRolesMapSelector } from 'store/selectors/user';
import { getAgentTeamDetailSelector } from 'store/selectors/agentTeamDetail';

export const PermissionsContent = (props) => {
  const dispatch = useDispatch();
  const { documentId, onClose, fullAccess, ownerId, isFileOwner } = props;
  const { transaction } = useSelector(getTransactionSelector);
  const userId = useSelector(getUserId);
  const { clients: invitedClients } = useSelector(getShareDrawerInfoSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const agentDetail = useSelector(getAgentTeamDetailSelector);

  const [loading, setLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);

  const [accessToEveryone, setAccessToEveryone] = useState(false);

  const [internalAccess, setInternalAccess] = useState(false);

  const [permissionUserIds, setPermissionUserIds] = useState<number[]>([]);
  const [clientUserIds, setClientUserIds] = useState<string[]>([]);

  const showClientVaultSection = fullAccess;
  const canManageInternalAccess =
    isAgent && agentDetail?.data.TeamId === transaction?.CreatorTeamId;

  const clients = useMemo(() => {
    if (invitedClients.state === READY) {
      return getFilteredClients(invitedClients.data);
    }

    return [];
  }, [invitedClients]);

  const allParticipantsIds = useMemo(
    () => (transaction?.Participants || []).map((participant) => participant?.Id),
    [transaction],
  );

  const { clientsInAccessPermission, filteredClients } = useMemo(() => {
    const clientsInAccessPermission = clients.filter(
      ({ Id }) => permissionUserIds.includes(Id) && allParticipantsIds.includes(Id),
    );

    const filteredClients = clients.filter(({ Id }) => !allParticipantsIds.includes(Id));

    return { clientsInAccessPermission, filteredClients };
  }, [allParticipantsIds, permissionUserIds, clients]);

  const fetchDetails = async () => {
    try {
      setLoading(true);

      if (isAgent) {
        await dispatch(getClientsEffect({}));
        await dispatch(requestGetTeamListEffect({}, { silent: true }));
      }

      const transactionResponse = await dispatch(
        getTransactionDocumentEffect({ documentId }, { silent: true }),
      );

      const result = transactionResponse?.data?.result || {};

      let usersWithAccess = result.AccessToEveryone
        ? allParticipantsIds
        : (result?.UsersWithAccess || []).map((u) => u?.Id);

      let defaultClientIds = (result?.ClientWithAccess || []).map((u) => u?.UserId);
      defaultClientIds = defaultClientIds.filter((id) => !usersWithAccess.includes(id));

      setAccessToEveryone(result.AccessToEveryone);
      setInternalAccess(result.InternalAccess || result.AccessToEveryone);
      setPermissionUserIds(usersWithAccess);
      setClientUserIds(defaultClientIds);

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [dispatch, allParticipantsIds]);

  const onUpdatePermissions = () => {
    setIsPending(true);

    const clientsInPermission = clientsInAccessPermission.map(({ Id }) => Id);

    const permissionData = {
      transactionId: transaction.Id,
      documentId,
      agents: {
        accessToEveryone,
        usersWithAccess: permissionUserIds,
        internalAccess,
      },
      clients: {
        usersWithAccess: [...clientsInPermission, ...clientUserIds],
      },
    };

    dispatch(
      updateTransactionDocumentPermissionEffect(permissionData, {}, (err) => {
        if (!err) {
          onClose();
          showSuccessMessage('Permissions updated successfully');
          dispatch(getTransactionDocumentsFilterEffect());
        }
        setIsPending(false);
      }),
    );
  };

  return (
    <div className={styles.documentReviewersPermission}>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className={`${styles.box} ${styles.reviewersBox}`}>
            <DocumentReviewers
              value={permissionUserIds}
              setEveryoneAccess={setAccessToEveryone}
              setInternalAccess={setInternalAccess}
              accessToEveryone={accessToEveryone}
              internalAccess={internalAccess}
              onChange={setPermissionUserIds}
              ownerId={ownerId}
              fullAccess={fullAccess}
              canManageInternalAccess={canManageInternalAccess}
            />
          </div>

          {showClientVaultSection && (
            <div className={styles.box}>
              <InputLabel className={styles.label} label={'Add to Client Vault'} />

              <FilePermissionsMultiSelect
                placeholder={'Search client...'}
                entityList={filteredClients.map((client) => ({
                  label: `${client.FirstName} ${client.LastName}`,
                  value: client.Id,
                }))}
                entity={clientUserIds}
                setEntity={setClientUserIds}
                propClassName={styles.permissionClientMultiSelect}
                multiSelectWrapperClassName={styles.permissionClientSelectWrapper}
                clientsInAccessPermission={clientsInAccessPermission.map((client) => ({
                  label: `${client.FirstName} ${client.LastName}`,
                  value: client.Id,
                }))}
              />
            </div>
          )}

          <div className={styles.buttonContainer}>
            <Button
              className={classNames(
                styles.button,
                isPending ? styles.buttonDisabled : styles.buttonEnabled,
              )}
              onClick={onUpdatePermissions}
              disabled={isPending}
              loading={isPending}
            >
              Save
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

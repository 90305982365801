import styles from './styles.module.scss';
import { Icons } from 'pages/Workshop/Icons';

import { Avatar } from 'components';
import { getPlaceHolder } from '../helper';

type EntityListType = { value: any; label: any }[];

type PermissionListItemType = {
  item: { value: any; label: any };
  entityList?: EntityListType;
  hideCheckbox?: boolean;
  onUncheckItem?: () => void;
};
export const PermissionListItem = ({
  item,
  entityList,
  hideCheckbox = false,
  onUncheckItem = undefined,
}: PermissionListItemType) => {
  const entityListLabel = hideCheckbox
    ? item.label
    : entityList?.find((e) => e.value === item)?.label;

  if (!hideCheckbox && !entityListLabel) return <></>;

  const key = hideCheckbox ? item.value : item;
  return (
    <div className={styles.selectedItemsWrapper} key={key}>
      <div className={styles.leftItemContainer}>
        <Avatar
          placeholder={getPlaceHolder(entityListLabel)}
          avatarClassName={styles.avatarCustom}
        />

        <span className={styles.itemText}>{entityListLabel}</span>
      </div>

      {!hideCheckbox && (
        <Icons
          variant={Icons.CLOSE}
          className={styles.crossIcon}
          color="#747475"
          onClick={onUncheckItem}
        />
      )}
    </div>
  );
};

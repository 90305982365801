import React, { useRef, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { Input } from 'components-antd';
import { Book } from 'components/Icons';
import { RadarHeader } from 'pages/Radar/components/RadarHeader';
import { Task } from 'pages/Workshop/Transactions/TransactionTasks/components';
import { TaskModal } from 'pages/Workshop/Transactions/TransactionTasks/components/TaskModal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { resetTransactionTaskEffect, setTransactionTaskParamsEffect } from 'store/effects';
import {
  deleteCampaignTaskTemplateEffect,
  requestGetCampaignByIdEffect,
  requestGetCampaignMasterSwitchEffect,
  requestGetPublishedKitsEffect,
  requestUpdateCampaignEffect,
} from 'store/effects/radarKits';
import {
  getPublishedKitsSelector,
  selectedSequenceSelector,
  selectedSequenceStateSelector,
} from 'store/selectors/radarKits';
import {
  Campaign,
  CampaignEntityType,
  CampaignKits,
  CampaignTaskTemplate,
  PublishedKit,
} from 'store/reducers/radarKits/types';
import { AddKitsModal } from '../AddKitsModal';
import { ChecklistIcon, DeleteIcon, LibraryIcon, PencilIcon, PencilSmallIcon } from '../components';
import { SequenceAssignedClients } from '../components/SequenceAssignedClients';
import { SequenceCard } from '../components/SequenceCard';
import styles from '../NewSequence/styles.module.scss';
import { useOutsideClick } from 'hooks';
import { PreviewKitsModal } from '../PreviewKitsModal';
import { Wrapper } from 'components';
import { ChangePlanFrequencyModal } from './ChangePlanFrequencyModal/ChangePlanFrequencyModal';
import { IDLE, PENDING } from 'settings/constants/apiState';
import { resetSelectedCampaignAction } from 'store/actions/radarKits';
import { useSequenceMeta } from 'pages/Radar/RadarKits/hooks/useSequenceMeta.hook';

export type SelectedKit = {
  Kits: PublishedKit['Kits'] | undefined;
} & CampaignKits;

type SequenceDetails = {
  title: string | null;
  description: string | null;
};

export const ViewSequence: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { isSequenceOwner } = useSequenceMeta();

  const sequenceData = useSelector(selectedSequenceSelector);
  const state = useSelector(selectedSequenceStateSelector);
  const [isPending, setIsPending] = useState(true);
  const [campaignMembersMasterSwitch, setCampaignMembersMasterSwitch] = useState<boolean>(false);
  const [campaignMembersMasterSwitchLoading, setCampaignMembersMasterSwitchLoading] =
    useState<boolean>(true);
  const [taskTemplateEditData, setTaskTemplateEditData] = useState<{
    templateId?: number;
    editData?: CampaignTaskTemplate;
  } | null>(null);
  const dispatch = useDispatch();
  // Start Form Data
  const [sequenceDetails, setSequenceDetails] = useState<SequenceDetails>({
    title: sequenceData?.Name || null,
    description: sequenceData?.Description || null,
  });

  // End Form Data
  const [isChangeFrequencyModalOpen, setIsChangeFrequencyModalOpen] = useState<boolean>(false);
  const [changeFrequencyPayload, setChangeFrequencyPayload] = useState<any>(null);
  const inputFieldRef = useRef<any>();

  useEffect(() => {
    if (state !== PENDING && sequenceData) {
      setIsPending(false);
    }
  }, [state, sequenceData]);

  useEffect(() => {
    dispatch(
      requestGetCampaignMasterSwitchEffect(id, {}, (err, res) => {
        if (!err) {
          setCampaignMembersMasterSwitch(res.data.result.Active);
        }
        setCampaignMembersMasterSwitchLoading(false);
      }),
    );
  }, []);

  const { data: publishedKits } = useSelector(getPublishedKitsSelector);
  // Fetch Published Kits
  useEffect(() => {
    dispatch(requestGetPublishedKitsEffect({}, { silent: !!publishedKits }));
  }, []);

  const selectedKits = useMemo<SelectedKit[]>(() => {
    if (!publishedKits || !sequenceData?.CampaignKits) return [];
    return sequenceData.CampaignKits.map((campaignKit) => {
      const kit = publishedKits.find((item) => item.KitId === campaignKit.KitId);
      return {
        ...campaignKit,
        Kits: kit?.Kits,
      };
    });
  }, [publishedKits, sequenceData]);

  const tasks = useMemo<CampaignTaskTemplate[]>(() => {
    if (!sequenceData?.TaskTemplates?.length) return [];
    return sequenceData?.TaskTemplates;
  }, [sequenceData]);

  const [isAddKitsModalOpen, setIsAddKitsModalOpen] = useState<boolean>(false);
  const [isAddTasksModalOpen, setIsAddTasksModalOpen] = useState<boolean>(false);
  const [isPreviewKitsModalOpen, setIsPreviewKitsModalOpen] = useState<boolean>(false);
  const [previewKitId, setPreviewKitId] = useState<number | null>(null);
  const [isEditingHeader, setIsEditingHeader] = useState<boolean>(false);

  const handleOpenAddKitsModal = () => setIsAddKitsModalOpen(true);
  const handleCloseAddKitsModal = () => setIsAddKitsModalOpen(false);

  const handleOpenAddTasksModal = () => setIsAddTasksModalOpen(true);
  const handleCloseAddTasksModal = () => {
    setIsAddTasksModalOpen(false);
    setTaskTemplateEditData(null);
    dispatch(setTransactionTaskParamsEffect({ isTaskForm: false, isTaskDetail: false }));
    dispatch(resetTransactionTaskEffect());
  };

  const navigateToAssignedClients = (e: any) => {
    e.stopPropagation();
    history.push(link.toRadarKitSequenceAssignedClients(id));
  };

  const onChangeTitle = (e) => {
    setSequenceDetails((prev) => ({ ...prev, title: e.target.value }));
  };

  const onChangeDescription = (e) => {
    setSequenceDetails((prev) => ({ ...prev, description: e.target.value }));
  };

  useOutsideClick([inputFieldRef], () => {
    saveTitleDescription();
  });

  const saveTitleDescription = () => {
    dispatch(
      requestUpdateCampaignEffect(
        {
          Id: Number(id),
          ...{ Name: sequenceDetails?.title || '' },
          ...{ Description: sequenceDetails?.description || '' },
        },
        { silent: true },
      ),
    );
    setIsEditingHeader(false);
  };

  const resetPageState = () => {
    setCampaignMembersMasterSwitch(false);
    setSequenceDetails({
      title: null,
      description: null,
    });
    setTaskTemplateEditData(null);
    setIsEditingHeader(false);
    setPreviewKitId(null);
    setIsPreviewKitsModalOpen(false);
    setIsAddTasksModalOpen(false);
    setIsAddKitsModalOpen(false);
    setChangeFrequencyPayload(null);
    setIsChangeFrequencyModalOpen(false);
    dispatch(resetSelectedCampaignAction());
  };
  const refreshSequenceState = useCallback(
    (cb?) => {
      resetPageState();
      dispatch(
        requestGetCampaignByIdEffect(
          id,
          { silent: !!sequenceData },
          (err, res: { data: { result: Campaign } }) => cb && cb(err, res),
        ),
      );
    },
    [id, dispatch, sequenceData],
  );

  useEffect(() => {
    if (
      id &&
      ((!sequenceData && state === IDLE) || (sequenceData?.Id && sequenceData?.Id !== Number(id)))
    ) {
      refreshSequenceState((err, res) => {
        if (!err && sequenceDetails.title === null && sequenceDetails.description === null) {
          setSequenceDetails({
            title: res.data?.result.Name || '',
            description: res.data?.result.Description || '',
          });
        }
      });
    }
  }, [id, sequenceData]);

  useEffect(() => {
    if (
      id &&
      sequenceData?.Name &&
      sequenceData?.Description &&
      sequenceDetails.title === null &&
      sequenceDetails.description === null
    ) {
      setSequenceDetails({
        title: sequenceData?.Name,
        description: sequenceData?.Description,
      });
    }
  }, [id, sequenceData?.Name, sequenceData?.Description]);

  return (
    <>
      <div className={styles.pageWrapper}>
        <RadarHeader headerClassName={styles.headerPadding}>
          <div ref={inputFieldRef} className={styles.titleInputContainer}>
            <div className={styles.titleInput}>
              {isEditingHeader ? (
                <Input
                  className={styles.input}
                  placeholder="New Plan"
                  value={sequenceDetails.title || ''}
                  onChange={onChangeTitle}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key == 'Escape') {
                      saveTitleDescription();
                    }
                  }}
                  variant="square"
                />
              ) : (
                <p className={styles.titleText}>{sequenceDetails?.title || 'New Sequence'}</p>
              )}
              {!isEditingHeader && isSequenceOwner && (
                <div
                  className={styles.editHeaderIcon}
                  onClick={() => setIsEditingHeader((prev) => !prev)}
                >
                  <PencilIcon />
                </div>
              )}
            </div>
            <div className={classNames(styles.descriptionInput)}>
              {isEditingHeader ? (
                <Input
                  className={styles.input}
                  placeholder="Add description"
                  value={sequenceDetails.description || ''}
                  onChange={onChangeDescription}
                  variant="square"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key == 'Escape') {
                      saveTitleDescription();
                    }
                  }}
                />
              ) : (
                <p className={styles.descriptionText}>{sequenceDetails?.description || ''}</p>
              )}
            </div>
          </div>
        </RadarHeader>
        <Wrapper
          className={styles.pageContent}
          isPending={isPending || campaignMembersMasterSwitchLoading}
        >
          <SequenceAssignedClients
            handleClick={navigateToAssignedClients}
            sequenceId={id}
            isActive={campaignMembersMasterSwitch}
            setCampaignMembersMasterSwitch={setCampaignMembersMasterSwitch}
          />
          <div className={styles.cardsWrapper}>
            <SequenceCard
              isSequenceOwner={isSequenceOwner}
              type={CampaignEntityType.Kit}
              title="KITs"
              handleAdd={handleOpenAddKitsModal}
              items={selectedKits}
              placeholder="No KITs added."
              icon={<LibraryIcon />}
              dropdownOnSelect={(kitId, frequencyId, newFrequencyName, prevFrequenceName) => {
                setChangeFrequencyPayload({
                  campaignId: Number(id),
                  kitId,
                  frequencyId,
                  newFrequencyName,
                  prevFrequenceName,
                });
                setIsChangeFrequencyModalOpen(true);
              }}
              dropdownOptions={[
                {
                  label: 'Preview',
                  icon: <Book color="#FF576D" className={styles.center} />,
                  onSelect: (kitId) => {
                    setPreviewKitId(Number(kitId));
                    setIsPreviewKitsModalOpen(true);
                  },
                },
                {
                  label: 'Delete',
                  icon: <DeleteIcon />,
                  onSelect: (kitId: string) => {
                    dispatch(
                      requestUpdateCampaignEffect(
                        {
                          Id: Number(id),
                          deleted: [Number(kitId)],
                        },
                        { silent: true },
                      ),
                    );
                  },
                },
              ]}
            />
            <SequenceCard
              isSequenceOwner={isSequenceOwner}
              type={CampaignEntityType.Task}
              title="Tasks"
              handleAdd={handleOpenAddTasksModal}
              placeholder="No tasks added."
              icon={<ChecklistIcon />}
              items={tasks}
              dropdownOnSelect={() => {}}
              dropdownOptions={[
                {
                  label: 'Edit',
                  onSelect: (templateId) => {
                    setTaskTemplateEditData({
                      templateId: Number(templateId),
                      editData: tasks.find((task) => task.Id === Number(templateId)),
                    });
                    setIsAddTasksModalOpen(true);
                  },
                  icon: <PencilSmallIcon />,
                },
                {
                  label: 'Delete',
                  onSelect: (taskId) => {
                    dispatch(
                      deleteCampaignTaskTemplateEffect(
                        {
                          id: taskId,
                        },
                        {},
                        (err) => {
                          if (!err) {
                            dispatch(requestGetCampaignByIdEffect(id, { silent: true }));
                          }
                        },
                      ),
                    );
                  },
                  icon: <DeleteIcon />,
                },
              ]}
            />
          </div>
        </Wrapper>
      </div>
      {isAddKitsModalOpen && (
        <AddKitsModal
          isOpen={isAddKitsModalOpen}
          closeModal={handleCloseAddKitsModal}
          title="Add KITs"
          selectedKits={selectedKits}
          sequenceId={id}
        />
      )}
      <TaskModal
        isOpen={isAddTasksModalOpen}
        onCloseModal={() => handleCloseAddTasksModal()}
        isNew={taskTemplateEditData?.templateId ? false : true}
        isTemplate={true}
        templateId={taskTemplateEditData?.templateId}
        isCampaignTask={true}
        campaignId={Number(id)}
        editData={taskTemplateEditData?.editData}
      />
      <Task />
      {isChangeFrequencyModalOpen && (
        <ChangePlanFrequencyModal
          payload={changeFrequencyPayload}
          handleClose={() => {
            setIsChangeFrequencyModalOpen(false);
            refreshSequenceState();
          }}
          selectedKits={selectedKits}
        />
      )}
      {isPreviewKitsModalOpen && previewKitId && (
        <PreviewKitsModal
          isOpen={isPreviewKitsModalOpen}
          closeModal={() => {
            setIsPreviewKitsModalOpen(false);
            setPreviewKitId(null);
          }}
          kitId={previewKitId}
          title="Preview"
        />
      )}
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Avatar } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import styles from './styles.module.scss';

const Reviewer = (props) => {
  const {
    className,
    value,
    reviewers,
    onChange,
    avatarPlaceholder,
    label,
    nameClass,
    disabled,
    isFileOwner,
    isRole,
    isChecked,
    subText,
  } = props;

  const name = `${value?.FirstName} ${value?.LastName}`;

  return (
    <div
      testid="reviewer_item"
      className={classNames(styles.reviewer, className, { [styles.disabledReviewer]: disabled })}
    >
      <div className={styles.nameRow}>
        <Avatar
          src={value?.AvatarUrl}
          placeholder={
            avatarPlaceholder || (
              <div className={styles.avatarPlaceholder}>{convertNameToAvatarPlaceholder(name)}</div>
            )
          }
          avatarClassName={styles.avatar}
        />

        <div
          className={classNames(styles.reviewerNameWrapper, {
            [styles.alignNameCenter]: !isRole && isFileOwner,
          })}
        >
          <div testid="name" className={`${styles.name} ${nameClass}`}>
            {label || name}

            {isRole ? (
              <div testid="role" className={styles.role}>
                {`${value?.Role?.DisplayTitle || value?.Role?.Title}`}
              </div>
            ) : (
              subText && (
                <div testid="subText" className={styles.subText}>
                  {subText}
                </div>
              )
            )}
          </div>

          {isFileOwner && <div className={styles.ownerMember}>File Owner</div>}
        </div>
      </div>

      <Checkbox
        disabled={disabled}
        onChange={onChange}
        checked={isChecked || !reviewers || reviewers.includes(value?.Id)}
        testid="reviewer_checkbox"
        labelClassName={`${styles.reviewerCheckmarkLabel} ${
          disabled ? styles.disabledCheckbox : ''
        }`}
        checkboxWrapperClassName={
          disabled ? styles.disabledCheckboxWrapper : styles.checkboxWrapper
        }
        checkboxClassName={styles.reviewerCheckbox}
      />
    </div>
  );
};

Reviewer.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Role: PropTypes.shape({
      Title: PropTypes.string,
    }),
  }),
  reviewers: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  avatarPlaceholder: PropTypes.node,
  label: PropTypes.string || PropTypes.node,
  isRole: PropTypes.bool,
  isChecked: PropTypes.bool,
  nameClass: PropTypes.string,
  disabled: PropTypes.bool,
  subText: PropTypes.string,
};

Reviewer.defaultProps = {
  className: '',
  value: {},
  reviewers: undefined,
  onChange: () => {},
  avatarPlaceholder: undefined,
  label: undefined,
  isRole: true,
  isChecked: false,
  disabled: false,
  nameClass: '',
  subText: '',
};

export default Reviewer;

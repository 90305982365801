import { Accordion } from 'components';
import { OptionGroup } from './components/OptionGroup';
import { NotificationsMediums } from './components/NotificationsMediums';
import ClientKitNotifications from './components/ClientKitNotifications';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotificationSettingsSelector, getUserRoleSelector } from 'store/selectors/user';
import {
  mapItemTitles,
  NotificationSubVariants,
  TimeOptions,
  TimeType,
} from 'settings/constants/notifications';
import { GroupTitle } from '../OldNotifications/components';
import { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { updateNotificationsSettingsEffect } from 'store/effects';
import { getUserRolesMapSelector } from 'store/selectors/user';
import styles from './styles.module.scss';
import { getKitsMasterControl } from 'api/kits';
import { Role } from 'settings/constants/roles';
import { cloneDeep } from 'lodash-es';

export const Notifications = () => {
  const userNotificationsSettings = useSelector(getUserNotificationSettingsSelector);
  const [notificationGroups, setNotificationGroups] = useState(userNotificationsSettings);
  const dispatch = useDispatch();
  const firstStepInputGroup = [NotificationSubVariants.HasMatchScore];
  const role = useSelector(getUserRoleSelector);

  const variantChangeHandler = ({
    checkedValues,
    groupIndex,
    itemIndex,
  }: {
    checkedValues: CheckboxValueType[];
    groupIndex: number;
    itemIndex: number;
  }) => {
    const groups = JSON.parse(JSON.stringify(notificationGroups)).filter(
      (group) => !(group.Type === 'Concierge' && [Role.Agent, Role.Client].includes(role)),
    );
    Object.keys(groups[groupIndex].NotificationItems[itemIndex].Variants).forEach((key) => {
      groups[groupIndex].NotificationItems[itemIndex].Variants[key] = !!checkedValues.includes(key);
    });

    Update(groups, [
      {
        ...groups[groupIndex],
        NotificationItems: [groups[groupIndex].NotificationItems[itemIndex]],
      },
    ]);
  };

  const userInputChangeHandler = ({
    checkedValue,
    groupIndex,
    itemIndex,
    inputValue,
  }: {
    checkedValue: CheckboxValueType;
    groupIndex: number;
    itemIndex: number;
    inputValue?: number | string;
  }) => {
    const groups = JSON.parse(JSON.stringify(notificationGroups)).filter(
      (group) => !(group.Type === 'Concierge' && [Role.Agent, Role.Client].includes(role)),
    );

    groups[groupIndex].NotificationItems[itemIndex].SubVariants = groups[
      groupIndex
    ].NotificationItems[itemIndex]?.SubVariants?.map((subVar) => ({
      ...subVar,
      ...(inputValue !== null && checkedValue === subVar.Key
        ? { Value: inputValue }
        : { Value: subVar.Value }),
    }));

    Update(groups, [
      {
        ...groups[groupIndex],
        NotificationItems: [groups[groupIndex].NotificationItems[itemIndex]],
      },
    ]);
  };
  const Update = (groups, payload) => {
    const revert = cloneDeep(notificationGroups);
    setNotificationGroups(groups);
    dispatch(
      updateNotificationsSettingsEffect(payload, {}, (err) => {
        if (err) {
          setNotificationGroups(revert);
        }
      }),
    );
  };
  const subVariantChangeHandler = ({
    checkedValues,
    groupIndex,
    itemIndex,
    inputValue,
    selectedValue,
  }: {
    checkedValues: CheckboxValueType[];
    groupIndex: number;
    itemIndex: number;
    inputValue?: number | string;
    selectedValue?: TimeType;
  }) => {
    const groups = JSON.parse(JSON.stringify(notificationGroups)).filter(
      (group) => !(group.Type === 'Concierge' && [Role.Agent, Role.Client].includes(role)),
    );

    const previousSelectedValues = groups[groupIndex].NotificationItems[
      itemIndex
    ]?.SubVariants?.filter((opt) => opt.Checked).map(({ Key }) => Key);

    const isLessLength = checkedValues.length < previousSelectedValues.length;
    const isAllKeySelected = checkedValues.includes('All') || checkedValues.includes('AllTasks');
    const disableAllCheck = ['Properties'].includes(groups[groupIndex].Type);

    groups[groupIndex].NotificationItems[itemIndex].SubVariants = groups[
      groupIndex
    ].NotificationItems[itemIndex]?.SubVariants?.map((subVar, index, SubVariantDetails) => {
      const shouldUncheckAll = isLessLength && isAllKeySelected && !disableAllCheck;
      const shouldCheckAll =
        !isAllKeySelected &&
        checkedValues.length === SubVariantDetails.length - 1 &&
        !disableAllCheck;

      const isAllKey = subVar.Key === 'All' || subVar.Key === 'AllTasks';

      return {
        ...subVar,

        ...(checkedValues ? { Checked: checkedValues.includes(subVar.Key) } : {}),

        ...(isAllKeySelected && !disableAllCheck
          ? { Checked: true }
          : { Checked: checkedValues.includes(subVar.Key) }),

        ...(shouldUncheckAll && !checkedValues.includes(subVar.Key) ? { Checked: false } : {}),
        ...(shouldCheckAll && isAllKey ? { Checked: true } : {}),
        ...(shouldUncheckAll && isAllKey ? { Checked: false } : {}),

        ...(!disableAllCheck &&
        ((previousSelectedValues.includes('All') && !checkedValues.includes('All')) ||
          (previousSelectedValues.includes('AllTasks') && !checkedValues.includes('AllTasks')))
          ? { Checked: false }
          : {}),

        ...(inputValue !== undefined && checkedValues.includes(subVar.Key)
          ? { Value: inputValue }
          : {}),

        ...(selectedValue && checkedValues.includes(subVar.Key)
          ? { SelectedValue: selectedValue }
          : {}),
      };
    });

    const updatedItems = onlyRealtimeOrOnceDay(
      [groups[groupIndex].NotificationItems[itemIndex]],
      [notificationGroups[groupIndex].NotificationItems[itemIndex]],
      checkedValues as string[],
    );

    Update(groups, [
      {
        ...groups[groupIndex],
        NotificationItems: updatedItems,
      },
    ]);
  };
  const onlyRealtimeOrOnceDay = (
    notificationPref,
    lastNotificationPref,
    checkedValue: string[] = [],
  ) => {
    const pref = notificationPref[0];

    const notifyPrefOptions: string[] = [
      NotificationSubVariants.Realtime,
      NotificationSubVariants.OnceDay,
    ];

    const isValidCheckedValue =
      checkedValue.length && notifyPrefOptions.includes(`${checkedValue[0]}`);

    if (isValidCheckedValue) {
      let key = 'SubVariants';
      let { SubVariants } = pref;
      let lastPrefSubVariants = lastNotificationPref[0].SubVariants;

      if (pref.SecondSubVariants && pref.SecondSubVariants.length) {
        key = 'SecondSubVariants';
        SubVariants = pref.SecondSubVariants;
        lastPrefSubVariants = lastNotificationPref[0].SecondSubVariants;
      }

      const optionValue = SubVariants[0].Key;

      if (notifyPrefOptions.includes(optionValue)) {
        const [firstKey, lastKey] = lastPrefSubVariants.map((pref) =>
          pref.Checked ? pref.Key : null,
        );

        if (firstKey) SubVariants[0].Checked = false;
        if (lastKey) SubVariants[1].Checked = false;

        pref[key] = SubVariants;
        return [pref];
      }
    }

    return notificationPref;
  };

  const secondSubVariantChangeHandler = ({
    checkedValues,
    groupIndex,
    itemIndex,
    inputValue,
  }: {
    checkedValues: CheckboxValueType[];
    groupIndex: number;
    itemIndex: number;
    inputValue?: number;
  }) => {
    const groups = JSON.parse(JSON.stringify(notificationGroups)).filter(
      (group) => !(group.Type === 'Concierge' && [Role.Agent, Role.Client].includes(role)),
    );

    groups[groupIndex].NotificationItems[itemIndex].SecondSubVariants = groups[
      groupIndex
    ].NotificationItems[itemIndex]?.SecondSubVariants?.map((secondSubVar) => ({
      ...secondSubVar,
      ...(checkedValues ? { Checked: checkedValues.includes(secondSubVar.Key) } : {}),
      ...(inputValue !== null &&
      inputValue !== undefined &&
      checkedValues.includes(secondSubVar.Key)
        ? { Value: inputValue }
        : {}),
    }));
    const updatedItems = onlyRealtimeOrOnceDay(
      [groups[groupIndex].NotificationItems[itemIndex]],
      [notificationGroups[groupIndex].NotificationItems[itemIndex]],
      checkedValues as string[],
    );
    Update(groups, [
      {
        ...groups[groupIndex],
        NotificationItems: updatedItems,
      },
    ]);
  };

  const getTheFirstStepOptions = (item) => {
    if (item?.SubVariants?.length) {
      return item.SubVariants.map((subVar, index) => {
        if (
          firstStepInputGroup.includes(subVar.Key) ||
          (item.SubVariants.filter((subVar) => firstStepInputGroup.includes(subVar.Key)).length &&
            (subVar.Key === NotificationSubVariants.All ||
              subVar.Key === NotificationSubVariants.AllTasks))
        ) {
          const isInput = subVar.Key === NotificationSubVariants.HasMatchScore;

          return {
            isInput,
            inputText: isInput ? mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[1] : '',
            label: isInput
              ? mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[0]
              : mapItemTitles.SubVariants[subVar.Key],
            value: subVar.Key,
            checked: subVar.Checked,
            inputValue: subVar.Value,
            inputPlaceholder: '99',
          };
        }
        if (subVar.Key === NotificationSubVariants.QuoteRequestRemind) {
          return {
            inputText: mapItemTitles.SubVariants[subVar.Key],
            value: subVar.Key,
            checked: subVar.Checked,
            inputValue: subVar.Value,
            dropdownOptions: TimeOptions,
            selectedValue: subVar.SelectedValue,
          };
        }

        if (subVar.Key === NotificationSubVariants.TourUpcoming) {
          return {
            isInput: true,
            inputText: mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[1],
            label: mapItemTitles.SubVariants[subVar.Key]?.split('-')?.[0],
            value: subVar.Key,
            checked: subVar.Checked,
            inputValue: subVar.Value,
            inputPlaceholder: '99',
          };
        }
        return {
          label: mapItemTitles.SubVariants[subVar.Key],
          value: subVar.Key,
          checked: subVar.Checked,
        };
      });
    }
  };

  const getTheSecondStepOptions = (item) => {
    if (item.SecondSubVariants?.length) {
      return item.SecondSubVariants.map((secondSubVar) => {
        if (
          [
            NotificationSubVariants.TheDayOf,
            NotificationSubVariants.DaysInAdvance,
            NotificationSubVariants.Realtime,
            NotificationSubVariants.OnceDay,
          ].includes(secondSubVar.Key)
        ) {
          return {
            isInput: secondSubVar.Key === NotificationSubVariants.DaysInAdvance,
            inputText: mapItemTitles.SubVariants[secondSubVar.Key],
            label: mapItemTitles.SubVariants[secondSubVar.Key],
            value: secondSubVar.Key,
            checked: secondSubVar.Checked,
            inputValue: secondSubVar.Value,
          };
        }
        return {
          label: mapItemTitles.SubVariants[secondSubVar.Key],
          value: secondSubVar.Key,
          checked: secondSubVar.Checked,
        };
      });
    }
  };

  const [kitMasterControl, setKitMasterControl] = useState<boolean>(false);
  const { isClient, isAgent } = useSelector(getUserRolesMapSelector);
  const [customNotificationsGroup, setCustomNotificationsGroup] = useState(notificationGroups);

  const fetchClientKitControl = async () => {
    const response = await getKitsMasterControl();
    if (response.status === 200) {
      setKitMasterControl(response.data.MasterControl);
    }
  };

  useEffect(() => {
    if (isClient) fetchClientKitControl();
  }, []);

  useEffect(() => {
    if (isClient) {
      const clientNotifications = notificationGroups.map((item) => {
        if (item.Type === 'Forms') {
          const updatedNotificationItems = item?.NotificationItems?.map((data) => {
            if (data.Types.includes('FormDocumentSignedByEveryone')) {
              return {
                ...data,
                Text: 'A form sent to me is signed by all parties and complete.',
              };
            }
            return data;
          });

          return {
            ...item,
            NotificationItems: updatedNotificationItems,
          };
        }
        return item;
      });
      setCustomNotificationsGroup(clientNotifications);
    }

    if (isAgent) {
      const agentNotifications = notificationGroups.map((item) => {
        if (item.Type === 'Forms') {
          return { ...item, Type: 'Electronic Signatures' };
        }
        return item;
      });
      setCustomNotificationsGroup(agentNotifications);
    }
  }, [notificationGroups]);

  const getNotificationItems = () => {
    return JSON.parse(
      JSON.stringify(
        role === Role.Client || role === Role.Agent ? customNotificationsGroup : notificationGroups,
      ),
    )
      .filter((group) => !(group.Type === 'Concierge' && [Role.Agent, Role.Client].includes(role)))
      .map((group, groupIndex) => {
        return (
          <Accordion
            titleClassName={styles.notificationTitle}
            testid={group.Type.toLowerCase()}
            key={group.Type}
            title={<GroupTitle text={group.Type} />}
          >
            <div className={styles.accordionBody}>
              <h4 className={styles.subTitle}>Notify me when:</h4>
              {isClient && group?.Type === 'General' ? (
                <ClientKitNotifications
                  emailChecked={kitMasterControl}
                  emailCheckChangeHandler={setKitMasterControl}
                />
              ) : (
                <></>
              )}
              {group.NotificationItems.map((item, itemIndex) => (
                <OptionGroup
                  disableAllCheck={['Properties'].includes(group?.Type)}
                  fieldTitle={item.Text}
                  firstStepBoxTitle={item.SubText}
                  secondStepBoxTitle={item.SecondSubText}
                  key={item.Types}
                  options={Object.keys(item.Variants).map((key) => {
                    return {
                      label: mapItemTitles.PerType[key]().Title,
                      value: key,
                      checked: item.Variants[key],
                    };
                  })}
                  hasFirstStep={!!item.SubVariants?.length}
                  hasSecondStep={!!item.SecondSubVariants?.length}
                  isFirstStepInputGroup={
                    !!item.SubVariants?.filter((subVar) => firstStepInputGroup.includes(subVar.Key))
                      .length
                  }
                  allowMultipleSelection={
                    !!item.SubVariants?.filter(
                      (subVar) => subVar.Key === NotificationSubVariants.TourUpcoming,
                    ).length
                  }
                  isFirstStepDropDownGroup={
                    !!item.SubVariants?.filter(
                      (subVar) => subVar.Key === NotificationSubVariants.QuoteRequestRemind,
                    ).length
                  }
                  isSecondStepNumberGroup={
                    !!item.SecondSubVariants?.filter(
                      (secondSubVar) => secondSubVar.Key === NotificationSubVariants.Realtime,
                    ).length
                  }
                  firstStepOptions={getTheFirstStepOptions(item)}
                  secondStepOptions={getTheSecondStepOptions(item)}
                  onMainCheckboxChange={(checkedValues: CheckboxValueType[]) =>
                    variantChangeHandler({ checkedValues, groupIndex, itemIndex })
                  }
                  onStepOneCheckboxChange={(checkedValues, inputValue, selectedValue) =>
                    subVariantChangeHandler({
                      checkedValues,
                      groupIndex,
                      itemIndex,
                      inputValue,
                      selectedValue,
                    })
                  }
                  onUserInputChange={(checkedValue, inputValue) =>
                    userInputChangeHandler({ checkedValue, groupIndex, itemIndex, inputValue })
                  }
                  onStepTwoCheckboxChange={(
                    checkedValues: CheckboxValueType[],
                    inputValue: number,
                  ) =>
                    secondSubVariantChangeHandler({
                      checkedValues,
                      groupIndex,
                      itemIndex,
                      inputValue,
                    })
                  }
                />
              ))}
            </div>
          </Accordion>
        );
      });
  };

  return (
    <div className={styles.notifications}>
      <h3 className={styles.pageTitle}>Notifications</h3>
      {/*INFO: mediums like sms, email etc. */}
      <NotificationsMediums />
      {getNotificationItems()}
    </div>
  );
};

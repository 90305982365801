import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

const Icons = (props) => {
  const { className, variant, onClick, color } = props;

  const getIcons = () => {
    switch (variant) {
      case Icons.FOLDER: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <rect opacity="0.09" width="32" height="32" rx="16" fill="#747475" />
            <path
              d="M7 10V21C7 22.1046 7.89543 23 9 23H23C24.1046 23 25 22.1046 25 21V12C25 10.8954 24.1046 10 23 10H16.5L14.5 8H9C7.89543 8 7 8.89543 7 10Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.UPLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66748 11.333V12.6663C2.66748 13.02 2.80796 13.3591 3.058 13.6092C3.30805 13.8592 3.64719 13.9997 4.00081 13.9997H12.0008C12.3544 13.9997 12.6936 13.8592 12.9436 13.6092C13.1937 13.3591 13.3341 13.02 13.3341 12.6663V11.333"
              stroke={color ?? '#FF576D'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66748 6.00098L8.00081 2.66764L11.3341 6.00098"
              stroke={color ?? '#FF576D'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10.667V2.66699"
              stroke={color ?? '#FF576D'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.UPLOAD_2: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_53716_69402)">
              <path
                d="M4.25984 11C3.39528 11 2.56612 10.673 1.95479 10.091C1.34345 9.50901 1 8.71964 1 7.89655C1 7.07347 1.34345 6.28409 1.95479 5.70208C2.56612 5.12007 3.39528 4.7931 4.25984 4.7931C4.46403 3.88771 5.06138 3.09206 5.92047 2.58118C6.34585 2.32822 6.82268 2.15279 7.32375 2.06491C7.82482 1.97702 8.3403 1.9784 8.84077 2.06897C9.34124 2.15953 9.8169 2.33751 10.2406 2.59274C10.6643 2.84798 11.0277 3.17546 11.3101 3.5565C11.5925 3.93754 11.7883 4.36468 11.8864 4.81351C11.9845 5.26235 11.983 5.72411 11.8819 6.17241H12.5748C13.218 6.17241 13.8349 6.42672 14.2897 6.8794C14.7445 7.33207 15 7.94603 15 8.58621C15 9.22638 14.7445 9.84034 14.2897 10.293C13.8349 10.7457 13.218 11 12.5748 11H11.8819"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 9L8 7L10 9"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 7.5L8 13.5"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_53716_69402">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.FOLDER_2: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 3.6V11.4C2 12.2837 2.71635 13 3.6 13H12.4C13.2837 13 14 12.2837 14 11.4V5.6C14 4.71634 13.2837 4 12.4 4H8.5L7 2H3.6C2.71634 2 2 2.71634 2 3.6Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.LINK_FORM: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 12V12.9C4 13.7837 4.71634 14.5 5.6 14.5H12.9C13.7837 14.5 14.5 13.7837 14.5 12.9V5.80059C14.5 5.60681 14.4297 5.41962 14.3021 5.27378L11.239 1.7732C11.0871 1.59958 10.8677 1.5 10.637 1.5H5.6C4.71634 1.5 4 2.21634 4 3.1V5"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.5 1.5V3.9C10.5 4.78366 11.2163 5.5 12.1 5.5H14"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <path
              d="M7.5 6L10 8.5L7.5 11"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 8.5L2.5 8.5"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect opacity="0.09" width="32" height="32" rx="16" fill="#747475" />
            <path
              d="M18 7V11C18 11.2652 18.1054 11.5196 18.2929 11.7071C18.4804 11.8946 18.7348 12 19 12H23"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 25H11C10.4696 25 9.96086 24.7893 9.58579 24.4142C9.21071 24.0391 9 23.5304 9 23V9C9 8.46957 9.21071 7.96086 9.58579 7.58579C9.96086 7.21071 10.4696 7 11 7H18L23 12V23C23 23.5304 22.7893 24.0391 22.4142 24.4142C22.0391 24.7893 21.5304 25 21 25Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 13H14"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 17H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 21H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOTS: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66536 17.3327C7.40174 17.3327 7.9987 16.7357 7.9987 15.9993C7.9987 15.263 7.40174 14.666 6.66536 14.666C5.92898 14.666 5.33203 15.263 5.33203 15.9993C5.33203 16.7357 5.92898 17.3327 6.66536 17.3327Z"
              fill={color || '#303030'}
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.0013 17.3327C16.7377 17.3327 17.3346 16.7357 17.3346 15.9993C17.3346 15.263 16.7377 14.666 16.0013 14.666C15.2649 14.666 14.668 15.263 14.668 15.9993C14.668 16.7357 15.2649 17.3327 16.0013 17.3327Z"
              fill={color || '#303030'}
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M25.3333 17.3327C26.0697 17.3327 26.6667 16.7357 26.6667 15.9993C26.6667 15.263 26.0697 14.666 25.3333 14.666C24.597 14.666 24 15.263 24 15.9993C24 16.7357 24.597 17.3327 25.3333 17.3327Z"
              fill={color || '#303030'}
              stroke={color || '#303030'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.OPEN: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Frame">
              <path
                id="Vector"
                d="M2 12.6671C2.91212 12.1405 3.94678 11.8633 5 11.8633C6.05322 11.8633 7.08788 12.1405 8 12.6671C8.91212 12.1405 9.94678 11.8633 11 11.8633C12.0532 11.8633 13.0879 12.1405 14 12.6671"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_2"
                d="M2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916C8.91212 3.47255 9.94678 3.19531 11 3.19531C12.0532 3.19531 13.0879 3.47255 14 3.99916"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_3"
                d="M2 4V12.6667"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_4"
                d="M8 4V12.6667"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector_5"
                d="M14 4V12.6667"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      case Icons.DOWNLOAD: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 11.334V12.6673C2.66797 13.0209 2.80844 13.3601 3.05849 13.6101C3.30854 13.8602 3.64768 14.0007 4.0013 14.0007H12.0013C12.3549 14.0007 12.6941 13.8602 12.9441 13.6101C13.1942 13.3601 13.3346 13.0209 13.3346 12.6673V11.334"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66797 7.33398L8.0013 10.6673L11.3346 7.33398"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66602V10.666"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_69414_500033)">
              <path
                d="M2.66797 13.3322H5.33464L12.3346 6.33223C12.6883 5.97861 12.8869 5.499 12.8869 4.9989C12.8869 4.4988 12.6883 4.01919 12.3346 3.66557C11.981 3.31194 11.5014 3.11328 11.0013 3.11328C10.5012 3.11328 10.0216 3.31194 9.66797 3.66557L2.66797 10.6656V13.3322Z"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.33594L11.6667 7.0026"
                stroke="#FF576D"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_69414_500033">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.DELETE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66748 4.66797H13.3341"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66748 7.33203V11.332"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33252 7.33203V11.332"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33249 4.66797L3.99916 12.668C3.99916 13.0216 4.13963 13.3607 4.38968 13.6108C4.63973 13.8608 4.97887 14.0013 5.33249 14.0013H10.6658C11.0194 14.0013 11.3586 13.8608 11.6086 13.6108C11.8587 13.3607 11.9992 13.0216 11.9992 12.668L12.6658 4.66797"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PLUS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 8H13" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <path d="M8 13L8 3" stroke={color} strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.SEARCH: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66667 11.3333C9.24399 11.3333 11.3333 9.24399 11.3333 6.66667C11.3333 4.08934 9.24399 2 6.66667 2C4.08934 2 2 4.08934 2 6.66667C2 9.24399 4.08934 11.3333 6.66667 11.3333Z"
              stroke="#747475"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 14L10 10"
              stroke="#747475"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILTER: {
        return (
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.72559 1.82925C1.44271 1.50596 1.6723 1 2.10188 1H17.8981C18.3277 1 18.5573 1.50596 18.2744 1.82925L12 9V14.691C12 14.8804 11.893 15.0535 11.7236 15.1382L8.72361 16.6382C8.39116 16.8044 8 16.5627 8 16.191V9L1.72559 1.82925Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.USER: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3337 4.66536C11.3337 6.50631 9.84127 7.9987 8.00033 7.9987C6.15938 7.9987 4.66699 6.50631 4.66699 4.66536C4.66699 2.82442 6.15938 1.33203 8.00033 1.33203C9.84127 1.33203 11.3337 2.82442 11.3337 4.66536Z"
              stroke="#FF576D"
              strokeWidth="2"
            />
            <path
              d="M2.66699 14.0013C2.99938 12.2206 4.52757 10.668 6.22244 10.668H9.7781C11.473 10.668 13.0013 12.2206 13.3337 14.0013"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MOVE: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 3.6V11.4C2 12.2837 2.71635 13 3.6 13H12.4C13.2837 13 14 12.2837 14 11.4V5.6C14 4.71634 13.2837 4 12.4 4H8.5L7 2H3.6C2.71634 2 2 2.71634 2 3.6Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M5.5 8.5H10M10 8.5L8 6.5M10 8.5L8 10.5"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.BACK_ARROW: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="44" height="44" rx="22" fill="#F4F5F6" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.194 28.6943C25.602 28.2867 25.602 27.6259 25.194 27.2184L19.9699 22L25.194 16.7817C25.602 16.3741 25.602 15.7133 25.194 15.3057C24.7859 14.8981 24.1244 14.8981 23.7164 15.3057L17.806 21.2096C17.5886 21.4267 17.487 21.7158 17.5013 22.0002C17.4871 22.2844 17.5887 22.5733 17.806 22.7904L23.7164 28.6943C24.1244 29.1019 24.786 29.1019 25.194 28.6943Z"
              fill="#262626"
            />
          </svg>
        );
      }
      case Icons.PERMISSIONS: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 7.33203H3.33333C2.59695 7.33203 2 7.92898 2 8.66536V13.332C2 14.0684 2.59695 14.6654 3.33333 14.6654H12.6667C13.403 14.6654 14 14.0684 14 13.332V8.66536C14 7.92898 13.403 7.33203 12.6667 7.33203Z"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66666 7.33203V4.66536C4.66666 3.78131 5.01785 2.93346 5.64297 2.30834C6.26809 1.68322 7.11593 1.33203 7.99999 1.33203C8.88404 1.33203 9.73189 1.68322 10.357 2.30834C10.9821 2.93346 11.3333 3.78131 11.3333 4.66536V7.33203"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            {...props}
          >
            <g clipPath="url(#clip0_42719_6422)">
              <path
                d="M24 8L8 24"
                stroke={props.stroke || '#515151'}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 8L24 24"
                stroke={props.stroke || '#515151'}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_42719_6422">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div onClick={onClick} className={classNames(styles.icons, className)}>
      {getIcons()}
    </div>
  );
};

Icons.UPLOAD = 'upload';
Icons.UPLOAD_2 = 'upload_2';
Icons.FOLDER_2 = 'folder_2';
Icons.LINK_FORM = 'linkForm';
Icons.FILE = 'file';
Icons.DOTS = 'dots';
Icons.OPEN = 'open';
Icons.DOWNLOAD = 'download';
Icons.EDIT = 'edit';
Icons.DELETE = 'delete';
Icons.PLUS = 'plus';
Icons.SEARCH = 'search';
Icons.FILTER = 'filter';
Icons.USER = 'user';
Icons.FOLDER = 'folder';
Icons.MOVE = 'move';
Icons.BACK_ARROW = 'back_arrow';
Icons.PERMISSIONS = 'permissions';
Icons.CLOSE = 'close';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

Icons.defaultProps = {
  className: '',
  variant: '',
  onClick: () => {},
  color: '#FFFFFF',
};

export default Icons;

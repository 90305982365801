import { useEffect, useMemo, useState } from 'react';
import Space from 'antd/lib/space';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Text, OptionType, Tooltip, Option } from 'components-antd';
import { caseInsensitiveFilter } from 'helpers';
import { updatePreFormResponseEffect } from 'store/effects/formProcess';
import {
  getFormMetaSelector,
  getPreFormSelector,
  getSmartFormDataSelector,
} from 'store/selectors/requestFormProcess';
import { Input, PropertyAddress } from 'components';
import { PropertyLocation } from 'types';

import { ICON_VARIANT_TYPE, Icons } from '../../../Icons';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { LinkPopover } from './components/LinkPopover';
import { FieldContainer } from './components/FieldContainer';

import { Navigation } from '../components';

import {
  getClientsWithParticipants,
  getExlcudedParticipants,
  getTransactionClients,
  groupByTransactionOptions,
  inferStatus,
  manageTransactionRemove,
} from 'pages/FormProcess/helper';

interface ClientOrTransactionProps {
  propertyTransactionId?: number;
  clientId?: number;
  isProject?: boolean;
  setShowClientOrTransaction: (show: boolean) => void;
  onContinue: () => void;
  propertyAddress?: any;
}

export const getClientOptions = (clients): OptionType[] =>
  clients?.map((client) => ({
    label: `${client.FirstName} ${client.LastName}`,
    value: client.Id,
    key: String(client.Id),
  })) || [];

export const getTransactionOptions = (transactions): OptionType[] => {
  const groupedTransactions = groupByTransactionOptions(transactions);
  return (
    groupedTransactions?.map((transaction) => {
      return {
        label: transaction.address || transaction.name,
        value: transaction.Id,
        key: String(transaction.Id),
        status: transaction.status,
        participants: transaction.participants,
      };
    }) || []
  );
};

export const getClientNamesFromClients = (clientIds: number[], clients) => {
  let name = '';

  if (clients?.length) {
    name = clients
      .filter((c) => clientIds.includes(c.Id))
      .map((c) => `${c.FirstName} ${c.LastName}`)
      .sort()
      .join(', ')
      .trim();
  }

  return name;
};

export const ClientOrTransaction = ({
  propertyTransactionId,
  clientId,
  isProject,
  setShowClientOrTransaction,
  onContinue,
  propertyAddress,
}: ClientOrTransactionProps) => {
  const dispatch = useDispatch();

  const { clients: metaClients, transactions } = useSelector(getFormMetaSelector);
  const { clientIds, transactionId, address, signatories, isSequentialSignature } =
    useSelector(getPreFormSelector);
  const { formRoles } = useSelector(getSmartFormDataSelector);

  const [clients, setClients] = useState(metaClients || []);

  const [linkClient, setLinkClient] = useState(false);
  const [linkTransaction, setLinkTransaction] = useState(false);
  const [linkAddress, setLinkAddress] = useState(false);

  useEffect(() => {
    setClients(metaClients || []);
  }, [metaClients]);

  useEffect(() => {
    if (clientIds?.length) {
      let clientsList = clients;

      let isListModified = false;
      if (transactionId && transactions?.length) {
        clientsList = getClientsWithParticipants(transactions, transactionId, clientsList);
        isListModified = metaClients?.length !== clientsList.length;
      }

      if (isListModified) {
        setTimeout(() => setClients(clientsList), 300);
      }

      const existingClientIds = clientIds.map((clientId) => +clientId);
      handleChange({
        clientIds,
        clientName: getClientNamesFromClients(existingClientIds, clientsList),
      });
    }
  }, []);

  useEffect(() => {
    if (clientIds?.length) {
      !linkClient && setLinkClient(true);
    }
  }, [clientIds]);

  const handleChange = (response: {}) => {
    dispatch(updatePreFormResponseEffect(response));
  };

  const updateClientFromTransaction = (transactionId: number) => {
    if (transactionId && transactions?.length) {
      const transaction = transactions.find((t) => t.Id === transactionId);
      if (transaction?.transactionClients?.length) {
        if (transaction.participants.length) {
          const updatedSignatories = signatories || {};
          let updatedSignatory = false;

          transaction.participants.forEach((p) => {
            const formRole = formRoles.find((f) => f.RoleId === p.formRoleId);
            if (formRole?.RoleId && !updatedSignatories?.[formRole.RoleId]) {
              let signatureSequence = 1;
              if (isSequentialSignature) {
                signatureSequence = Object.keys(updatedSignatories).length + 1;
              }
              updatedSignatories[formRole.RoleId] = {
                FormRole: formRole.RoleId,
                SignatureSequence: signatureSequence,
              };
              updatedSignatory = true;
            }
          });

          if (updatedSignatory) {
            dispatch(
              updatePreFormResponseEffect({
                signatories: Object.keys(updatedSignatories).length
                  ? updatedSignatories
                  : undefined,
              }),
            );
          }
        }

        handleChange({
          clientIds: transaction.transactionClients,
          clientName: getClientNamesFromClients(transaction.transactionClients, clients),
        });
        !linkClient && setLinkClient(true);
      } else {
        handleChange({
          clientIds: [],
          clientName: '',
        });
      }
    }
  };

  const handleDefaultTransaction = () => {
    if (propertyTransactionId && transactions?.length) {
      const transaction = transactions.find((t) => t.Id === propertyTransactionId);

      if (transaction) {
        handleChange({
          transactionId: transaction.Id,
          clientAddress: transaction.address,
        });
        updateClientFromTransaction(transaction.Id);
        setShowClientOrTransaction(false);
      }
    }
  };

  const handleDefaultClient = () => {
    if (clientId) {
      handleChange({
        clientIds: [clientId],
        clientName: getClientNamesFromClients([clientId], clients),
      });
      setShowClientOrTransaction(false);
    }
  };

  useEffect(() => {
    if (propertyTransactionId && transactions?.length) handleDefaultTransaction();
  }, [propertyTransactionId, transactions]);

  useEffect(() => {
    if (clientId) handleDefaultClient();
  }, [clientId, transactions]);

  useEffect(() => {
    if (propertyAddress) {
      !linkAddress && setLinkAddress(true);
      handleAddressUpdate(propertyAddress);
    }
  }, [propertyAddress]);

  const handleAddressUpdate = (address: PropertyLocation) => {
    handleChange({
      address,
    });
  };

  const handleMenuClick = (menuProps, setOpen) => {
    const { key } = menuProps;

    const menuActions = {
      transaction: () => setLinkTransaction(true),
      client: () => setLinkClient(true),
      newAddress: () => setLinkAddress(true),
    };

    menuActions[key] && menuActions[key]();
    setOpen(false);
  };

  const getButtonText = () => {
    const hasTransaction = linkTransaction || transactionId;
    const hasClient = linkClient || clientIds?.length;
    const hasAddress = !isProject && (linkAddress || address);

    if (!hasTransaction && !hasClient && !hasAddress) return 'Skip';

    return 'Continue';
  };

  const onRemoveTransactionField = () => {
    const { newClientIds, newClientNames, hadNonExistantClients } = manageTransactionRemove(
      transactions,
      transactionId,
      metaClients,
      clientIds,
      clients,
    );

    if (hadNonExistantClients) {
      setClients(metaClients || []);
    }

    handleChange({
      transactionId: undefined,
      clientAddress: undefined,
      ...(newClientIds && { clientIds: newClientIds, clientName: newClientNames }),
    });

    setLinkTransaction(false);
  };

  return (
    <>
      <Text className={styles.questionHeader}>Do you want to link this form to anything?</Text>

      <div className={styles.pageContent}>
        <Space direction="vertical" size="middle" className={styles.linkListContainer}>
          {linkTransaction || transactionId ? (
            <FieldContainer
              label="Transaction"
              icon={ICON_VARIANT_TYPE.DoubleArrow}
              removeClass={styles.svgBtn}
              onRemoveField={onRemoveTransactionField}
            >
              <Select
                value={transactionId}
                labelInValue
                onChange={(option) => {
                  const label = option.key.split('---')[0];

                  const { clientIds, clientParticipants } = getTransactionClients(
                    transactions,
                    option.value,
                  );

                  const nonExistantClients = getExlcudedParticipants(
                    clientParticipants,
                    metaClients,
                  );

                  const allClients = [...nonExistantClients, ...(metaClients || [])];
                  const clientName = getClientNamesFromClients(clientIds, allClients);

                  if (nonExistantClients.length) {
                    setClients(allClients);
                  } else {
                    setClients(metaClients || []);
                  }

                  handleChange({
                    transactionId: option.value,
                    clientAddress: label,
                    address: undefined,

                    clientIds,
                    clientName,
                  });
                }}
                showSearch
                size="large"
                className={classNames(styles.listBoxInput, styles.transactionBoxInput)}
                filterOption={(inputValue, option) => {
                  const label = option?.props?.key?.split('---')[0];
                  return caseInsensitiveFilter(inputValue, label);
                }}
                placeholder="Select Transaction"
                suffixIcon={<Icons variant={ICON_VARIANT_TYPE.Arrow} stroke="#747475" />}
                popupClassName={styles.transactionOptionDropdown}
              >
                {getTransactionOptions(transactions).map(({ label, value, status }) => (
                  <Option
                    key={`${label}---${value}`}
                    label={label}
                    value={value}
                    className={styles.transactionOption}
                  >
                    <div className={styles.transactionOptionLabel}>{label}</div>
                    <div className={styles.transactionOptionStatus}>{inferStatus(status)}</div>
                  </Option>
                ))}
              </Select>
            </FieldContainer>
          ) : (
            <></>
          )}

          {linkClient || clientIds?.length ? (
            <FieldContainer
              label="Client"
              inputClass={styles.multipleSelectInput}
              icon={ICON_VARIANT_TYPE.User}
              removeClass={styles.svgBtn}
              onRemoveField={() => {
                handleChange({ clientIds: undefined, clientName: undefined });
                setLinkClient(false);
              }}
            >
              <Select
                mode="multiple"
                value={clientIds}
                onChange={(option) =>
                  handleChange({
                    clientIds: option,
                    clientName: getClientNamesFromClients(option, clients),
                  })
                }
                showSearch
                size="large"
                className={styles.listBoxInput}
                options={getClientOptions(clients)}
                filterOption={(inputValue, option) =>
                  caseInsensitiveFilter(inputValue, option?.props?.label)
                }
                suffixIcon={<Icons variant={ICON_VARIANT_TYPE.Arrow} />}
                maxTagCount={'responsive'}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                    <span>{`+${omittedValues.length}`}</span>
                  </Tooltip>
                )}
                placeholder="Select Client(s)"
              />
            </FieldContainer>
          ) : (
            <></>
          )}

          {!isProject &&
            (linkAddress || address ? (
              <FieldContainer
                inputClass={styles.addressSelectInput}
                inputIconClass={styles.addressInputIcon}
                icon={ICON_VARIANT_TYPE.Address}
                removeClass={classNames(styles.svgBtn, styles.addressSvgBtn)}
                onRemoveField={() => {
                  setLinkAddress(false);
                  handleChange({ address: undefined });
                }}
              >
                <PropertyAddress
                  className={styles.propertyAddress}
                  address={address}
                  location={address}
                  updateLocation={handleAddressUpdate}
                  showUnit={false}
                  valuesWrapperClassName={styles.addressInput}
                  searchWrapperClassName={styles.addressSearchWrapper}
                  variant={Input.LIGHT_FULL}
                />
              </FieldContainer>
            ) : (
              <></>
            ))}
        </Space>

        <LinkPopover
          showTransaction={!linkTransaction && !transactionId && !linkAddress && !address}
          showClient={!linkClient && !clientIds?.length}
          showAddress={!isProject && !linkAddress && !address && !linkTransaction && !transactionId}
          onMenuClick={handleMenuClick}
        />

        <Navigation
          buttonText={getButtonText()}
          onContinue={onContinue}
          className={styles.customButton}
        />
      </div>
    </>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  getDynamicFormSelector,
  getLazyLoadedDocumentSelector,
} from 'store/selectors/requestFormProcess';
import { Spinner } from 'components';
import { dynamicFormScaleChangeEffect } from 'store/effects/formProcess';
import { SignatureContainer, SignaturePage, SignatureWidgets } from './components';
import { PageWrapper } from 'components-antd';
import { DynamicHeader } from '../../DynamicHeader';
import { FORM_PROCESS_SCREEN } from 'app-constants';
import { SendDocument } from 'pages/FormProcess/Ancillary';
import { dynamicManager } from '../../DynamicManager';

import styles from './styles.module.scss';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BundleDataType } from 'types';
import { getAllClausesEffect } from 'store/effects/templates';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { getAllDocumentsWithPages } from '../DynamicEditor/helper';
import { PrintFormPDF } from 'pages/FormProcess/components';

export const DynamicViewer = () => {
  const dispatch = useDispatch();
  const anonymousDetails = dynamicManager.getAnonymousDetails();

  const {
    screen,
    meta: { link } = {},
    isCanceled,
    isCompleted,
    isPreview,
    hideFooter: footerHidden,

    dynamicFormDocuments,
  } = useSelector(getDynamicFormSelector);
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { pdfDocuments } = useSelector(getLazyLoadedDocumentSelector);

  const [loading, setLoading] = useState(false);
  const pdfDocRefs = useRef({});
  const pageContentRef = useRef(null);

  const { state: formState } = useLocation<BundleDataType>();
  const state = useMemo(() => formState, []);
  const allDocuments = dynamicFormDocuments || state?.allDocuments || [];
  const { formProcessPublicId } = useParams<{ formProcessPublicId: string }>();

  const isMultipleDocument = (dynamicFormDocuments?.length as number) > 1;
  const isBundle = dynamicManager.isBundleForm();

  const windowState = window.history.state;
  const isWebView = state?.isWebView || windowState?.isWebView;

  const isDeclined = screen === FORM_PROCESS_SCREEN.DeclineSign;
  const hideFooter = isCanceled || isCompleted || isPreview || footerHidden || isDeclined;

  useEffect(() => {
    if (isAgent) {
      dispatch(getAllClausesEffect());
    }
  }, []);

  if (!allDocuments?.length) return <Spinner />;

  const isSendDocument = screen === FORM_PROCESS_SCREEN.SendDocument;

  return (
    <PageWrapper
      version={hideFooter ? 2 : 3}
      showFooter={false}
      mainPageContentStyle={classNames(styles.mainContainer, {
        [styles.mainPageContainer]: hideFooter,
        [styles.sendDocument]: isSendDocument,
      })}
      pageContainerClassName={classNames(styles.dynamicViewerPageContainer, {
        [styles.webViewPageContainer]: isWebView && !isSendDocument,
        [styles.webViewSendDocumentPageContainer]: isWebView && isSendDocument,
        [styles.sendDocumentPageContainer]: !isWebView && isSendDocument,
      })}
      pageContentClassName={isSendDocument ? styles.sendDocumentPageContent : ''}
      layoutContentClassName={isSendDocument ? styles.sendDocumentLayoutContent : ''}
      pageContentRef={pageContentRef}
    >
      <DynamicHeader
        isSendDocument={isSendDocument}
        isMultipleDocument={isMultipleDocument}
        pdfDocRefs={pdfDocRefs}
      />

      {isSendDocument ? (
        <SendDocument
          {...(isBundle
            ? {
                formProcessPublicId: dynamicManager.getFormProcessPublicId(),
                formDocumentPublicId: dynamicFormDocuments?.[0].PublicId,
              }
            : { ...dynamicManager.getAuthData() })}
          back={dynamicManager.gotBackToPreviousScreen}
          handleExit={dynamicManager.handleFormExit}
          handleDone={dynamicManager.progressForm}
          dynamicForm
          isBundle={isBundle}
        />
      ) : (
        <PrintFormPDF
          className={styles.pdfDocument}
          classNameDocumentPage={`${styles.documentPage} ${styles.pageScrollwiseDocument}`}
          classNamePdfDocument={styles.dynamicViewerPDFDocument}
          pdfPageClassName={styles.pdfPage}
          pageRender={(props) => (isDeclined ? <></> : <SignaturePage {...props} />)}
          widgets={<SignatureWidgets allDocuments={allDocuments} hideDownload={isCompleted} />}
          pdfLoading={loading}
          pdfDocRefs={pdfDocRefs}
          isDynamicView={true}
          pdfDocuments={pdfDocuments || []}
          pageContentRef={pageContentRef}
          allDocuments={getAllDocumentsWithPages(allDocuments)}
        >
          <SignatureContainer />
        </PrintFormPDF>
      )}
    </PageWrapper>
  );
};

import { createSelector } from 'reselect';
import { PENDING } from 'settings/constants/apiState';
import {
  IPropertyReferenceDataState,
  IPropertyReferenceDetailsState,
  IPropertyReferenceNeighborhoodActivitiesState,
  IPropertyReferenceNeighborhoodState,
  IPropertyReferenceStatsState,
} from 'store/reducers/radarPortfolio/types';

const localState = ({ radarPortfolio }: { radarPortfolio }) => radarPortfolio;

export const getPropertyReferenceDataSelector = createSelector(
  localState,
  ({ propertyReferenceData }) =>
    (propertyReferenceData?.data?.Error
      ? { data: [] }
      : propertyReferenceData) as IPropertyReferenceDataState,
);

export const getPropertyReferenceStatsSelector = createSelector(
  localState,
  ({ propertyReferenceStats }) =>
    (propertyReferenceStats?.data?.Error
      ? { data: {} }
      : propertyReferenceStats) as IPropertyReferenceStatsState,
);

export const getPropertyReferenceDetailsSelector = createSelector(
  localState,
  ({ propertyReferenceDetails }) => propertyReferenceDetails as IPropertyReferenceDetailsState,
);

export const getAvmAdjustmentSelector = createSelector(
  localState,
  ({ propertyReferenceAvmAdjustment }) => ({
    avmAdjustments: propertyReferenceAvmAdjustment.data,
    isPending: propertyReferenceAvmAdjustment.state === PENDING,
  }),
);

export const getPropertyReferenceNeighborhoodSelector = createSelector(
  localState,
  ({ propertyReferenceNeighborhood }) =>
    propertyReferenceNeighborhood as IPropertyReferenceNeighborhoodState,
);

export const getPropertyReferenceNeighborhoodActivitiesSelector = createSelector(
  localState,
  ({ propertyReferenceNeighborhoodActivities }) =>
    propertyReferenceNeighborhoodActivities as IPropertyReferenceNeighborhoodActivitiesState,
);

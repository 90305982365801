import React from 'react';

import styles from './styles.module.scss';
import { Modal } from 'antd';
import { ModalClose } from 'components/Icons';
import classNames from 'classnames';
import { PastDataType } from 'pages/Radar/RadarKits/AssignedClientsView/useCampaignClientExecInfo.hook';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  replyRecord: PastDataType;
};

export const ReplyKitsModal: React.FC<Props> = ({ isOpen, closeModal, replyRecord }) => {
  const sortedReplies = replyRecord?.replies?.sort(
    (a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime(),
  );
  const reply = sortedReplies?.[0];

  return (
    <>
      <Modal
        open={isOpen}
        centered
        maskStyle={{
          background: 'rgba(0, 0, 0, 0.60)',
          backdropFilter: 'blur(20px)',
        }}
        closable
        footer={null}
        destroyOnClose
        onCancel={closeModal}
        closeIcon={<ModalClose />}
        title={replyRecord?.name?.title || ''}
        wrapClassName={styles.wrapper}
        width={1002}
      >
        <div className={styles.subtitle}>KIT Reply</div>
        <div className={styles.contentWrapper}>
          <div className={classNames(styles.content, styles.tableContainer)}>
            <div dangerouslySetInnerHTML={{ __html: reply?.HTMLBody || '' }} />
          </div>
        </div>
      </Modal>
    </>
  );
};

import { createSelector } from 'reselect';

const localState = ({ clientDetail }) => clientDetail;

export const getClientDetailsSelector = createSelector(
  localState as any,
  (clientDetail) => clientDetail,
);

export const getTeamListForEntity = createSelector(getClientDetailsSelector, (clientDetail) => {
  return (clientDetail?.data?.notesEntities || []).map((item) => ({
    Id: item.Id,
    FirstName: item.FirstName,
    LastName: item.LastName,
    Role: item.Roles[0],
    SearchableName: `${item?.FirstName} ${item?.LastName}`,
    Type: 'Contact',
    Email: item?.Email,
    Source: 'Agent',
  }));
});

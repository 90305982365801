import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { getHeaderConfig } from 'settings/navigation/config/components/header';
import { routes } from 'settings/navigation/routes';
import { MosaikLogo } from 'components/Icons';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { LocalStorage } from 'services';

interface HeaderLogoProps {
  absoluteHeader?: boolean;
}

export const HeaderLogo = ({ absoluteHeader }: HeaderLogoProps) => {
  const { pathname } = useLocation();

  const [minimizeLogo, setMinimizeLogo] = useState(false);
  const [logoText, setLogoText] = useState<string | undefined>('');
  const [link, setLink] = useState<string | undefined>();
  const { isClient } = useSelector(getUserRolesMapSelector);

  useEffect(() => {
    const config = getHeaderConfig(pathname);
    if (config) {
      setMinimizeLogo(config.minimizeLogo || false);
      setLogoText(config.logoText);
      setLink(config.headerLink);
    } else {
      if (minimizeLogo) {
        setMinimizeLogo(false);
      }
      if (logoText) {
        setLogoText('');
      }
      if (link) {
        setLink(undefined);
      }
    }
    if (
      pathname.includes(routes.properties) ||
      pathname.includes(routes.clientDashboard) ||
      pathname.includes(routes.vault) ||
      ((pathname.includes(routes.transactions) || pathname.includes(routes.projects)) &&
        pathname.includes('/overview')) ||
      pathname === routes.index
    )
      setMinimizeLogo(isClient);
  }, [pathname, isClient, minimizeLogo, logoText, link]);

  const brokerageLogo = LocalStorage.getBrokerageLogo();

  return (
    <div className={classNames(styles.logo, { [styles.minimizedLogo]: minimizeLogo })}>
      <Link
        data-testid="mosaik-logo"
        to={link || routes.index}
        className={classNames(styles.headerLogoContainer, {
          [styles.absoluteHeader]: absoluteHeader,
        })}
      >
        {brokerageLogo && brokerageLogo !== '' ? (
          <img src={brokerageLogo} className={styles.brokerageLogo} />
        ) : (
          <>
            {minimizeLogo ? (
              <MosaikLogo primary={false} className={styles.headerLogo} />
            ) : (
              <span>
                <MosaikLogo primary={true} className={styles.primaryLogo} />
                <MosaikLogo primary={false} className={styles.secondaryLogo} />
              </span>
            )}
            {logoText && <span className={styles.logoText}>{logoText}</span>}
          </>
        )}
      </Link>
    </div>
  );
};

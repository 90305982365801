import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  PermissionTypes,
  PermissionTypesLabel,
  TransactionScope,
} from 'app-constants/enums/permissionSettings';
import { DynamicDropdown } from '../../DynamicDropdown';

import { TransactionScopeModal } from '../TransactionScopeModal';

import styles from './styles.module.scss';
import { ConfirmationModal } from 'components-antd';

export const PermissionTypeDropdown = (props) => {
  const {
    Id: memberId,
    Name: memberName,
    permissionDetails,
    setExpandedRowKeys,
    setPermissionDetails,
    AddToTransaction: alreadyAddedInTransaction,
  } = props;

  const permissionMember = permissionDetails[memberId];
  const isAutomaticallySelected = permissionMember && 'addToPast' in permissionMember;

  const [selectedType, setSelectedType] = useState(
    alreadyAddedInTransaction || isAutomaticallySelected
      ? PermissionTypes.AutomaticallyAdd
      : PermissionTypes.ViewOnly,
  );

  useEffect(() => {
    if (
      !isAutomaticallySelected &&
      !alreadyAddedInTransaction &&
      selectedType === PermissionTypes.AutomaticallyAdd
    ) {
      setSelectedType(PermissionTypes.ViewOnly);
    } else if (
      !permissionMember &&
      alreadyAddedInTransaction &&
      selectedType === PermissionTypes.ViewOnly
    ) {
      setSelectedType(PermissionTypes.AutomaticallyAdd);
    }
  }, [isAutomaticallySelected]);

  const [openScopeModal, setOpenScopeModal] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const toggleScopeModal = () => setOpenScopeModal((prev) => !prev);
  const toggleConfirmModal = () => setOpenConfirmation((prev) => !prev);

  const onSelectPermissionType = (value) => {
    setSelectedType(value);

    const isViewOnlyValue = value === PermissionTypes.ViewOnly;
    const isAutomaticallySelected = value === PermissionTypes.AutomaticallyAdd;

    if (alreadyAddedInTransaction && isViewOnlyValue) {
      toggleConfirmModal();
    } else if (!alreadyAddedInTransaction && isAutomaticallySelected) {
      toggleScopeModal();
    } else {
      modifyRowKeys(value);
      setPermissionDetails(memberId, {});
    }
  };

  const modifyTransactionScope = (addToPast) => {
    setPermissionDetails(memberId, { addToPast, addToTransaction: true });
  };

  const modifyRowKeys = (type) => {
    setExpandedRowKeys(memberId, type);
  };

  const onScopeCancel = () => {
    toggleScopeModal();
    setSelectedType(PermissionTypes.ViewOnly);
  };

  const onScopeConfirm = (scope) => {
    toggleScopeModal();
    modifyTransactionScope(scope === TransactionScope.All);
    modifyRowKeys(selectedType);
  };

  const onViewOnlyTypeConfirm = () => {
    modifyRowKeys(PermissionTypes.ViewOnly);
    setPermissionDetails(memberId, { addToTransaction: false });
    toggleConfirmModal();
  };

  const onViewOnlyTypeCancel = () => {
    toggleConfirmModal();
    setSelectedType(PermissionTypes.AutomaticallyAdd);
  };

  return (
    <Fragment>
      <TransactionScopeModal
        isOpen={openScopeModal}
        onCancel={onScopeCancel}
        onConfirm={onScopeConfirm}
        memberName={memberName}
      />

      <ConfirmationModal
        width={430}
        className={styles.permissionTypeConfirmModal}
        confirmText={`Are you sure you want to modify access permission to "View Only"?`}
        open={openConfirmation}
        onOk={onViewOnlyTypeConfirm}
        onCancel={onViewOnlyTypeCancel}
        closable={false}
        confirmLoading={false}
      />

      <DynamicDropdown
        label={PermissionTypesLabel[selectedType]}
        value={selectedType}
        setValue={(value) => {
          onSelectPermissionType(value as PermissionTypes);
        }}
        options={Object.keys(PermissionTypes).map((item) => ({
          label: PermissionTypesLabel[item],
          value: item,
        }))}
        dropdownContainerClassName={
          selectedType === PermissionTypes.AutomaticallyAdd ? styles.fullWidthContainer : ''
        }
      />
    </Fragment>
  );
};

import React from 'react';

import { Collapse, Panel } from 'components-antd';
import { Minus, Plus } from 'components/Icons';

import styles from './styles.module.scss';

type FilterSectionProps = {
  title: string;
  children: React.ReactNode;
  defaultActive?: boolean;
};

export const FilterSection: React.FC<FilterSectionProps> = ({
  title,
  children,
  defaultActive = false,
}) => {
  const getCollapseIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <Minus className={styles.btnWrapper} />
    ) : (
      <Plus className={styles.btnWrapper} />
    );
  };

  return (
    <Collapse
      ghost
      expandIcon={getCollapseIcon}
      expandIconPosition="end"
      className={styles.filterSection}
      defaultActiveKey={defaultActive && [title]}
    >
      <Panel key={title} header={<div className={styles.title}>{title}</div>}>
        {children}
      </Panel>
    </Collapse>
  );
};

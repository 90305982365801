import { Table } from 'components-antd';
import styles from './styles.module.scss';
import { teamTableColumns } from './tableColumns';
import { useEffect, useMemo, useState } from 'react';
import { MemberTypes, PermissionTypes } from 'app-constants/enums/permissionSettings';
import { ManageRoleAndAccess } from './ManageRoleAndAccess';
import { useSelector } from 'react-redux';
import { getTeamTransactionPermissionstDataArray } from 'store/selectors/teamList';

import { getTransactionRolesDataDashboardSelector } from 'store/selectors/adminPanel';
import { getMembersByType, isContinueDisabled } from './helper';
import _ from 'lodash';
import { PermissionButtonsFooter } from './PermissionButtonsFooter';
import { updateTeamTransactionPermissions } from 'api/teamList';
import { AxiosResponse } from 'axios';

type AgentMembersTableType = {
  memberType: string;
  searchValue: string;
  loadingMembers: boolean;
  refetchPermissions: () => void;
};

export const AgentMembersTable = (props: AgentMembersTableType) => {
  const { memberType, searchValue, loadingMembers, refetchPermissions } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [permissionUpdatedDeails, setPermissionUpdatedDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const teamTransactionPermissions = useSelector(getTeamTransactionPermissionstDataArray);
  const transactionRoles = useSelector(getTransactionRolesDataDashboardSelector);

  const manageExpandedRows = (includePermissionDetails = true) => {
    const list = getMembersByType(teamTransactionPermissions, memberType);
    const expandedIds = list
      .filter(
        ({ AddToTransaction, Id }) =>
          AddToTransaction || (includePermissionDetails && permissionUpdatedDeails[Id]),
      )
      .map(({ Id }) => Id);

    setExpandedRowKeys(expandedIds);
  };

  useEffect(() => {
    manageExpandedRows();
  }, [teamTransactionPermissions, memberType]);

  const dataSource = useMemo(() => {
    const list = getMembersByType(teamTransactionPermissions, memberType);

    if (searchValue) {
      return list.filter(({ Name }) => Name.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return list;
  }, [teamTransactionPermissions, memberType, searchValue]);

  const onSelectType = (rowId, type) => {
    if (type === PermissionTypes.AutomaticallyAdd) {
      setExpandedRowKeys((prev) => [...prev, rowId]);
    } else if (expandedRowKeys.includes(rowId)) {
      const keys = expandedRowKeys.filter((value) => value !== rowId);
      setExpandedRowKeys(keys);
    }
  };

  const managePermissionDetails = (memberId, details) => {
    let permissionDetails = { ...permissionUpdatedDeails };

    if (_.isEmpty(details) && permissionDetails[memberId]) {
      delete permissionDetails[memberId];
    } else {
      permissionDetails[memberId] = permissionDetails[memberId] ?? {};
      permissionDetails[memberId] = { ...permissionDetails[memberId], ...details };
    }

    setPermissionUpdatedDetails(permissionDetails);
  };

  const handleReset = () => {
    setPermissionUpdatedDetails({});
    manageExpandedRows(false);
  };

  const handleSave = async () => {
    const promises: Promise<AxiosResponse<any, any>>[] = [];

    for (let memberId in permissionUpdatedDeails) {
      const memberDetails = permissionUpdatedDeails[memberId];
      const { addToPast, accessLevel, roleId, ...restDetails } = memberDetails;

      promises.push(
        updateTeamTransactionPermissions({
          memberId,
          ...restDetails,
          ...((accessLevel || roleId) && { addToPastTransactions: addToPast }),
        }),
      );
    }

    setLoading(true);
    try {
      const responses = await Promise.all(promises);
      setLoading(false);
      setPermissionUpdatedDetails({});
      refetchPermissions();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Table
        className={`${styles.agentMembersTable}`}
        columns={teamTableColumns({
          memberType,
          transactionRoles,
          permissionUpdatedDeails,
          setExpandedRowKeys: onSelectType,
          setPermissionDetails: managePermissionDetails,
        })}
        dataSource={dataSource}
        loading={loadingMembers}
        rowKey={'Id'}
        pagination={{
          total: dataSource?.length,
          defaultPageSize: dataSource?.length,
        }}
        expandable={{
          expandedRowKeys,
          expandedRowRender: (record) => (
            <ManageRoleAndAccess
              {...record}
              permissionUpdatedDetails={permissionUpdatedDeails}
              setPermissionDetails={managePermissionDetails}
            />
          ),
          showExpandColumn: false,
        }}
      />

      {!_.isEmpty(permissionUpdatedDeails) && (
        <PermissionButtonsFooter
          handleReset={handleReset}
          handleContinue={handleSave}
          loading={loading || loadingMembers}
          disabled={isContinueDisabled(permissionUpdatedDeails) || loading || loadingMembers}
        />
      )}
    </>
  );
};

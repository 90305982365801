import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchPreferencesSendValues } from 'store/selectors/app';

import { Modal } from 'components';
import { LocationService } from 'services';
import { EDIT } from 'settings/constants/mode';
import { appOpenModalEffect } from 'store/effects';
import { updateSearchInstanceEffect } from 'store/effects/mySearches';
import { getSavedSearchesEffect, setSearchCriteriaEffect } from 'store/effects/search';
import { getCurrentContextSelector } from 'store/selectors/context';
import { getSearchInstancesEffect } from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import { getFeedCriteriaSelectorV3, getFeedMetaSelectorV3 } from 'store/selectors/feedv3';
import { useSearchInstanceV3Effect } from '../ClientFeed/hooks/useSearchInstanceV3Effect';
import Criteria from './Criteria';
import styles from './styles.module.scss';
import { selectedSearchAction } from 'store/actions/feedv3';
import { getUserRolesMapSelector } from 'store/selectors/user';
import { useAgentSearchInstance } from '../AgentFeed/hooks/useAgentSearchInstance';
import CommutesModal from 'pages/OnBoardingWizard/components/StageParts/StagePrefs/CommutesModal';
import { SchoolsModal } from 'pages/OnBoardingWizard/components/StageParts/StageNeighbors/Options/SchoolsModal';
import { getClientsSearchInstancesEffect } from 'store/effects/clientSearches';
import { pendingPropertyStatus } from '../../../settings/constants/properties';
import { isEqual } from 'lodash-es';

const locationSrv = new LocationService();

const CriteriaModal = () => {
  locationSrv.setLocation(location);
  const modal = useSelector(getModalSelector);
  const dispatch = useDispatch();
  const { isAgent } = useSelector(getUserRolesMapSelector);
  const [isPending, setIsPending] = useState(false);
  const getSearchInstanceV3 = useSearchInstanceV3Effect();
  const [softCriteria, setSoftCriteria] = useState({});
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const { mode, selectedSearch, isClientMatches, selectedClientSavedSearch } =
    useSelector(getFeedMetaSelectorV3);
  const Id = selectedSearch?.id;
  const isEditMode = mode === EDIT;
  const searchContext = useSelector(getCurrentContextSelector);
  const getAgentSearchInstance = useAgentSearchInstance();

  const cfg = { contextKey: searchContext?.ContextKey };

  const editModeCallback = (err, resp, criterias) => {
    if (!err) {
      if (isAgent) {
        getAgentSearchInstance({ criterias, id: Id }, undefined, selectedClientSavedSearch);
        if (selectedClientSavedSearch) {
          dispatch(getClientsSearchInstancesEffect());
        } else {
          dispatch(getSavedSearchesEffect());
        }
        dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: false }));
        setIsPending(false);
      } else {
        dispatch(
          getSearchInstancesEffect(cfg, {}, (err, result) => {
            if (!err) {
              const searchActive = result?.data?.result?.find(
                (item) => item?.Id === selectedSearch?.id,
              );
              const item = {
                id: searchActive?.Id,
                name: searchActive?.Name,
                criterias: searchActive?.DefaultPropertySearchPreferences,
              };
              dispatch(selectedSearchAction({ selectedSearch: item }));
              dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: false }));
              setIsPending(false);
            }
          }),
        );
      }
    }
  };

  const searchCallback = (values) => {
    const searchPrefs = getSearchPreferencesSendValues({ softCriteria });

    const criterias = {
      ...values,
      ...searchPrefs,
    };

    dispatch(appOpenModalEffect({ id: CriteriaModal.id, open: false }));
    if (isAgent) {
      getAgentSearchInstance({ criterias, id: Id }, undefined, undefined, isClientMatches);
    } else {
      getSearchInstanceV3({
        criterias,
        id: Id,
      });
    }
  };

  const onEditModeSubmit = (values) => {
    const searchPrefs = getSearchPreferencesSendValues({ softCriteria });
    setIsPending(true);
    const criterias = {
      id: Id,
      ...values,
      ...searchPrefs,
    };
    dispatch(
      updateSearchInstanceEffect(criterias, {}, (err, resp) =>
        editModeCallback(err, resp, criterias),
      ),
    );
  };

  const onSubmit = (values) => {
    const isCass = values?.Locations?.some(({ deliveryLine1 }) => deliveryLine1);
    if (!isCass && (!values?.HomeType || values?.HomeType?.length < 1)) {
      values.HomeType = ['Single', 'Townhouse', 'Condo'];
    }
    values.Status = values?.Status?.flatMap((status) =>
      status.value === 'Pending'
        ? pendingPropertyStatus
            .filter(
              (pendingStatus) =>
                !values.Status.some(
                  (existingStatus) => existingStatus.value === pendingStatus.value,
                ),
            )
            .concat(status) // Add "Pending" back after filtering duplicates
        : status,
    );

    values.SoldDateRange = isEqual(
      values?.Status?.map(({ value }) => value),
      ['Closed'],
    )
      ? values.SoldDateRange
      : undefined;

    dispatch(setSearchCriteriaEffect(values));
    if (isEditMode) {
      onEditModeSubmit(values);
    } else {
      searchCallback(values);
    }
    const ref = document.getElementById('searchProperties');
    if (ref) {
      document.getElementById('searchProperties').scrollTop = 0;
    }
  };

  const onClose = () => {
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  if (
    modal?.id !== CriteriaModal?.id &&
    modal?.id !== CommutesModal?.id &&
    modal?.id !== SchoolsModal?.id
  )
    return null;

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      className={styles.container}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
      headerClassName={styles.headerClassName}
      label="Edit Search Criteria"
    >
      <div className={styles.drawerInner}>
        <Criteria
          onSubmit={onSubmit}
          search={criteria}
          softCriteria={softCriteria}
          setSoftCriteria={setSoftCriteria}
          isPending={isPending}
          isEditMode={isEditMode}
        />
      </div>
    </Modal>
  );
};

export default CriteriaModal;

CriteriaModal.id = 'criteriaModal';

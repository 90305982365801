import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import AutoComplete from 'antd/lib/auto-complete';

import { FORM_RECIPIENTS, allReadySelected, incorrectEmail } from 'app-constants';
import { OptionType, Row, Select, Text } from 'components-antd';
import { updatePreFormResponseEffect } from 'store/effects/formProcess';
import { isValidEmail, sortOptions, validationToast } from 'pages/FormProcess/helper';
import { getFormMetaSelector, getPreFormSelector } from 'store/selectors/requestFormProcess';

import { caseInsensitiveFilter } from 'helpers';

import styles from './styles.module.scss';
import { Plus, RemoveFile } from 'components/Icons';
import { Navigation } from '../components';
import { InfoTooltip } from '../components/InfoTooltip';

export const FormCopy = ({ onContinue }) => {
  const dispatch = useDispatch();

  const { copyRecipients } = useSelector(getPreFormSelector);
  const { agents, clients } = useSelector(getFormMetaSelector);

  const recipientsList = [...(agents || []), ...(clients || [])];
  const [recipients, setRecipients] = useState<string[]>([]);

  useEffect(() => {
    if (copyRecipients) {
      setRecipients(copyRecipients.map((rec) => rec.id || ''));
    }
  }, [copyRecipients]);

  const getRecipientOptions = (): OptionType[] =>
    recipientsList.map((recipient) => ({
      label: `${recipient.FirstName} ${recipient.LastName}`,
      value: String(recipient.Id),
      key: String(recipient.Id),
    }));

  const [options, setOptions] = useState<OptionType[]>(getRecipientOptions());

  const isEmail = (email) => {
    return isValidEmail(email.trim());
  };

  const handleRecipientSearch = (value, index) => {
    if (isEmail(value)) {
      setOptions([{ label: value, value }]);
    }
    if (value) {
      const updatedRecipient = [...recipients];
      updatedRecipient[index] = value;
      setRecipients(updatedRecipient);
    } else {
      handleRecipientDeselect(index);
      setOptions(getRecipientOptions());
    }
  };

  const handleRecipientDeselect = (index) => {
    const defaultRecipients = [...(copyRecipients || [])];

    if (defaultRecipients[index]) {
      defaultRecipients[index].id = '';
    }
    updateRecipient(defaultRecipients);
  };

  const updateRecipient = (updatedRecipient) => {
    dispatch(
      updatePreFormResponseEffect({
        copyRecipients: updatedRecipient,
      }),
    );
  };

  const handleRecipientTypeChange = (index, type) => {
    const updatedRecipient = [...(copyRecipients || [])];

    if (updatedRecipient[index]) {
      updatedRecipient[index].type = type;
      updateRecipient(updatedRecipient);
    }
  };

  const handleRecipientBlur = (index) => {
    setOptions(getRecipientOptions());
    const copyRecipient = copyRecipients || [];

    if (recipients[index]) {
      const searchText = recipients[index];

      if (copyRecipient[index]?.id !== searchText && searchText && !isEmail(searchText)) {
        validationToast(incorrectEmail);
        const updatedRecipient = [...recipients];
        updatedRecipient[index] = '';

        copyRecipient[index].id = '';
        setRecipients(updatedRecipient);
        updateRecipient(copyRecipient);
      } else if (isEmail(searchText)) {
        copyRecipient[index].id = searchText;
        updateRecipient(copyRecipient);
      }
    }
  };

  const getRecipientValue = (id?) => {
    if (id) {
      const recipient = recipientsList.find((rec) => rec.Id == id);
      if (recipient?.FirstName || recipient?.Email) {
        return recipient?.FirstName
          ? `${recipient?.FirstName} ${recipient?.LastName}`
          : recipient?.Email;
      } else {
        return id;
      }
    }
  };

  const handleRecipientSelect = (index, id) => {
    let updatedCopyRecipient = [...(copyRecipients || [])];

    if (updatedCopyRecipient[index]) {
      if (isNaN(Number(id)) && !isEmail(id)) {
        updatedCopyRecipient[index].id = '';
        validationToast(incorrectEmail);
      } else if (updatedCopyRecipient.some((recipient) => recipient.id === id)) {
        updatedCopyRecipient[index].id = '';
        validationToast(allReadySelected);
      } else {
        updatedCopyRecipient[index].id = id;
      }

      updateRecipient(updatedCopyRecipient);
    }
  };

  const getRecipientTypeOptions = (): OptionType[] => [
    {
      label: 'Send Final Copy',
      value: FORM_RECIPIENTS.SEND_COPY,
    },
    {
      label: 'Send All Updates',
      value: FORM_RECIPIENTS.SEND_ALL_UPDATES,
    },
  ];

  const hasRecipients = copyRecipients?.length;
  const canNotAddMore = hasRecipients && !copyRecipients?.[copyRecipients?.length - 1]?.id;

  useEffect(() => {
    if (!hasRecipients) addEmptyRecipient();
  }, []);

  const addEmptyRecipient = () => {
    if (canNotAddMore) {
      return;
    }
    updateRecipient([...(copyRecipients || []), { type: FORM_RECIPIENTS.SEND_COPY }]);
  };

  const getFilteredOptions = (id) => {
    const otherSelectedOptions = (copyRecipients || []).filter((o) => o.id !== id).map((o) => o.id);

    return options.filter((o) => o.value && !otherSelectedOptions.includes(String(o.value)));
  };

  return (
    <>
      <Text className={styles.questionHeader}>
        Does anyone else need a copy?{' '}
        <InfoTooltip text="If there is anyone who needs a copy of this document that isn't a signer, please enter their name or email address here. They will receive a copy once the document is completed and signed by all parties." />
      </Text>

      <div className={styles.pageContent}>
        <div className={styles.copyRecipientsRow}>
          {copyRecipients?.map((rec, index) => (
            <div className={styles.fieldWrapper} key={`array-${rec.id}-${index}`}>
              <div className={styles.field}>
                <div className={styles.label}>Recipient</div>
                <AutoComplete
                  key={`recipient-search-${rec.id}`}
                  value={getRecipientValue(recipients[index])}
                  onSelect={(e) => handleRecipientSelect(index, e)}
                  onSearch={(e) => handleRecipientSearch(e, index)}
                  onBlur={() => handleRecipientBlur(index)}
                  placeholder="Enter name or email address"
                  bordered={false}
                  className={classNames(styles.listBoxInput, styles.userIdSelect)}
                  options={sortOptions(getFilteredOptions(rec.id), 'label')}
                  filterOption={(inputValue, option) =>
                    caseInsensitiveFilter(inputValue, option?.label as string)
                  }
                />
              </div>

              <div className={styles.field}>
                <Select
                  large
                  disabled={!rec.id}
                  key={`recipient-type-${rec.id || 'new'}`}
                  value={rec.type}
                  bordered={false}
                  className={classNames(styles.listBoxInput, styles.userTypeSelect)}
                  options={getRecipientTypeOptions()}
                  onChange={(e) => handleRecipientTypeChange(index, e)}
                />
              </div>

              {index > 0 && (
                <RemoveFile
                  className={styles.removeButton}
                  onClick={() => {
                    copyRecipients.splice(index, 1);
                    updateRecipient(copyRecipients);
                  }}
                />
              )}
            </div>
          ))}
        </div>

        <div
          className={classNames(styles.add, canNotAddMore && styles.disabled)}
          onClick={addEmptyRecipient}
        >
          <Plus color="#515151" className={styles.addIcon} />
          <div className={styles.addText}>Add Another</div>
        </div>

        <Navigation onContinue={onContinue} />
      </div>
    </>
  );
};

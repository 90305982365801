import { Fragment, useEffect, useState } from 'react';

import { AccessLevel, AccessLevelLabel } from 'app-constants/enums/permissionSettings';
import { ConfirmationModal } from 'components-antd';

import { DynamicDropdown } from '../../../DynamicDropdown';

import styles from './style.module.scss';

type AccessLevelType = {
  TransactionAccess: boolean;
  TransactionRole?: { Title: string; Id: string };
  Id: number;
  Name: string;
  onUpdateDetails: (details, cb) => void;
  loading: boolean;
  accessLevel: string;
  onChangeRoleAccess: (data) => void;
  accessRoleId?: string;
};

export const AccessLevelView = ({
  Name,
  loading,
  accessLevel,
  accessRoleId,
  TransactionRole,
  onChangeRoleAccess,
}: AccessLevelType) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedAccessLevel, setSelectedAccessLevel] = useState(accessLevel);

  const { FullAccess } = AccessLevel;

  const modifyAccessLevel = (accessLevelValue) => {
    onChangeRoleAccess({
      accessLevel: accessLevelValue,
      transactionRoleId: accessRoleId,
      transactionAccess: accessLevelValue === AccessLevel.FullAccess,
    });
  };

  const onAccessLevelConfirm = () => {
    modifyAccessLevel(selectedAccessLevel);
    setConfirmationOpen(false);
  };

  const onCancel = () => {
    setConfirmationOpen(false);
  };

  const onChangeAccess = (value) => {
    if (!TransactionRole) {
      modifyAccessLevel(value);
    } else {
      setSelectedAccessLevel(value);
      setConfirmationOpen(true);
    }
  };

  return (
    <Fragment>
      <DynamicDropdown
        label={accessLevel ? AccessLevelLabel[accessLevel] : 'Select Access Level'}
        value={accessLevel || ''}
        setValue={onChangeAccess}
        options={Object.keys(AccessLevel).map((item) => ({
          label: AccessLevelLabel[item],
          value: item,
        }))}
        titleClass={!accessLevel ? styles.titlePlaceholder : styles.accessLevelLabel}
      />

      <ConfirmationModal
        width={430}
        className={styles.accessLevelModal}
        confirmText={`Are you sure you want to grant ${Name} ${
          selectedAccessLevel === FullAccess ? "'Full Access'" : "'Limited Access'"
        } to transaction?`}
        open={confirmationOpen}
        onOk={onAccessLevelConfirm}
        onCancel={onCancel}
        closable={false}
        confirmLoading={loading}
      />
    </Fragment>
  );
};

import React from 'react';

import styles from './styles.module.scss';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

type valuationData = {
  change: string;
  changeType: string;
  month: number;
  year: number;
  value: number;
};

type Props = {
  historyAttom: valuationData[];
  isClient: boolean;
};

export const Table: React.FC<Props> = ({ historyAttom, isClient }) => {
  const formatMonthYear = (month: number, year: number): string => {
    const date = new Date(year, month - 1); // Create a date object
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' }); // Format it as "Jan 2024"
  };

  if (!historyAttom.length) return null;
  const disableAgent = historyAttom.every((elm: any) => elm?.manual?.value === undefined);

  return (
    <div
      className={classNames(
        styles.valuationTable,
        { [styles.notClient]: !isClient },
        { [styles.disableAgent]: disableAgent },
      )}
    >
      <div className={styles.header}>
        <div>Month</div>
        {isClient && !disableAgent ? <div>Agent</div> : null}
        <div>{isClient ? 'AVM' : 'Value'}</div>
        {isClient && <div>Average</div>}
      </div>

      <div className={styles.data}>
        {historyAttom.map((item: any) => (
          <div className={styles.row}>
            <div className={styles.col}>
              {isClient
                ? formatMonthYear(
                    item?.manual?.month || item?.attom?.month,
                    item?.manual?.year || item?.attom?.year,
                  )
                : formatMonthYear(item?.month, item?.year)}
            </div>
            {isClient ? (
              <>
                {!disableAgent && (
                  <div className={styles.col}>
                    {item?.manual?.value ? (
                      <NumberFormat
                        thousandSeparator
                        displayType="text"
                        value={item?.manual?.value}
                        prefix="$"
                      />
                    ) : (
                      '-'
                    )}
                    <span className={classNames(styles.change, styles[item?.manual?.changeType])}>
                      {item?.manual?.change}
                    </span>
                  </div>
                )}
                <div className={styles.col}>
                  {item?.attom?.value ? (
                    <NumberFormat
                      thousandSeparator
                      displayType="text"
                      value={item?.attom?.value || '-'}
                      prefix="$"
                    />
                  ) : (
                    '-'
                  )}
                  <span className={classNames(styles.change, styles[item?.attom?.changeType])}>
                    {item?.attom?.change}
                  </span>
                </div>
                <div className={styles.col}>
                  {(
                    item?.manual?.value && item?.attom?.value
                      ? (item?.manual?.value + item?.attom?.value) / 2
                      : item?.manual?.value || item?.attom?.value
                  ) ? (
                    <NumberFormat
                      thousandSeparator
                      displayType="text"
                      value={
                        item?.manual?.value && item?.attom?.value
                          ? (item?.manual?.value + item?.attom?.value) / 2
                          : item?.manual?.value || item?.attom?.value
                      }
                      prefix="$"
                    />
                  ) : (
                    '-'
                  )}
                </div>
              </>
            ) : (
              <div className={styles.col}>
                {item.value ? (
                  <NumberFormat
                    thousandSeparator
                    displayType="text"
                    value={item.value}
                    prefix="$"
                  />
                ) : (
                  '-'
                )}
                <span className={classNames(styles.change, styles[item.changeType])}>
                  {' '}
                  {item.change !== '0%' && item.change !== '0.00' ? item.change : ''}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

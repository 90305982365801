export enum Categories {
  NEW_LISTING = 'New Listing',
  PRICE_REDUCTION = 'Price Reduction',
  PRICE_INCREASE = 'Price Increase',
  BACK_ON_THE_MARKET = 'Back on the Market',
  WITHDRAWN = 'Withdrawn',
  UNDER_CONTRACT = 'Under Contract',
  EXPIRED = 'Expired',
  SOLD = 'Sold',
}

export type FormValues = {
  categories: {
    [key in Categories]: boolean;
  };
  tags: Record<string, boolean>;
};

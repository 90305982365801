import PropTypes from 'prop-types';

export const Icons = ({ testid, className, variant, onClick, color, svgClass }) => {
  const renderIcon = () => {
    switch (variant) {
      case Icons.PLUS: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path d="M4 8H12" stroke="#2B59DA" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 12L8 4" stroke="#2B59DA" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.ADD: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 10H15" stroke={color ?? '#515151'} strokeWidth="2" strokeLinecap="round" />
            <path
              d="M10 15L10 5"
              stroke={color ?? '#515151'}
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        );
      }
      case Icons.FILES: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M13.2857 18H4.71429C4.25963 18 3.82359 17.8127 3.5021 17.4793C3.18061 17.1459 3 16.6937 3 16.2222V3.77778C3 3.30628 3.18061 2.8541 3.5021 2.5207C3.82359 2.1873 4.25963 2 4.71429 2H10L15 7V16.2222C15 16.6937 14.8194 17.1459 14.4979 17.4793C14.1764 17.8127 13.7404 18 13.2857 18Z"
              fill="white"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.2857 21H9.71429C9.25963 21 8.82359 20.8127 8.5021 20.4793C8.18061 20.1459 8 19.6937 8 19.2222V6.77778C8 6.30628 8.18061 5.8541 8.5021 5.5207C8.82359 5.1873 9.25963 5 9.71429 5H15L20 10V19.2222C20 19.6937 19.8194 20.1459 19.4979 20.4793C19.1764 20.8127 18.7404 21 18.2857 21Z"
              fill="white"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 5V9C15 9.26522 15.1054 9.51957 15.2929 9.70711C15.4804 9.89464 15.7348 10 16 10H20"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.UPLOAD: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="20" height="20" fill="#252D44" />
            <path
              d="M3.3335 14.166V15.8327C3.3335 16.2747 3.50909 16.6986 3.82165 17.0112C4.13421 17.3238 4.55814 17.4993 5.00016 17.4993H15.0002C15.4422 17.4993 15.8661 17.3238 16.1787 17.0112C16.4912 16.6986 16.6668 16.2747 16.6668 15.8327V14.166"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.8335 7.50065L10.0002 3.33398L14.1668 7.50065"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 3.33398V13.334"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENT: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.668 4V9.33333C18.668 9.68695 18.8084 10.0261 19.0585 10.2761C19.3085 10.5262 19.6477 10.6667 20.0013 10.6667H25.3346"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.668 28H9.33464C8.62739 28 7.94911 27.719 7.44902 27.219C6.94892 26.7189 6.66797 26.0406 6.66797 25.3333V6.66667C6.66797 5.95942 6.94892 5.28115 7.44902 4.78105C7.94911 4.28095 8.62739 4 9.33464 4H18.668L25.3346 10.6667V25.3333C25.3346 26.0406 25.0537 26.7189 24.5536 27.219C24.0535 27.719 23.3752 28 22.668 28Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOCUMENTS: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 9H10"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 13H15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 17H15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MORE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="12" r="1.5" fill="#262626" />
            <circle cx="12" cy="12" r="1.5" fill="#262626" />
            <circle cx="19" cy="12" r="1.5" fill="#262626" />
          </svg>
        );
      }
      case Icons.COMMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.1237 16.0581L6.04377 16.4498L6.24163 15.9851L5.98506 15.5501L5.1237 16.0581ZM3.48275 19.912L2.56268 19.5203H2.56268L3.48275 19.912ZM4.12275 20.5744L4.48267 21.5074H4.48267L4.12275 20.5744ZM8.16834 19.0137L8.65043 18.1376L8.24266 17.9132L7.80841 18.0807L8.16834 19.0137ZM19.0195 12C19.0195 15.866 15.8855 19 12.0195 19V21C16.9901 21 21.0195 16.9706 21.0195 12H19.0195ZM12.0195 5C15.8855 5 19.0195 8.13401 19.0195 12H21.0195C21.0195 7.02944 16.9901 3 12.0195 3V5ZM5.01952 12C5.01952 8.13401 8.15352 5 12.0195 5V3C7.04896 3 3.01952 7.02944 3.01952 12H5.01952ZM5.98506 15.5501C5.37169 14.51 5.01952 13.2977 5.01952 12H3.01952C3.01952 13.6647 3.47242 15.2267 4.26234 16.5661L5.98506 15.5501ZM4.40283 20.3038L6.04377 16.4498L4.20363 15.6663L2.56268 19.5203L4.40283 20.3038ZM3.76283 19.6414C4.17514 19.4824 4.57595 19.8972 4.40283 20.3038L2.56268 19.5203C2.04332 20.7401 3.24575 21.9846 4.48267 21.5074L3.76283 19.6414ZM7.80841 18.0807L3.76283 19.6414L4.48267 21.5074L8.52826 19.9467L7.80841 18.0807ZM12.0195 19C10.7962 19 9.64894 18.687 8.65043 18.1376L7.68624 19.8898C8.97264 20.5977 10.4506 21 12.0195 21V19Z"
              fill="#303030"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.5 12C9.5 12.8284 8.82843 13.5 8 13.5C7.17157 13.5 6.5 12.8284 6.5 12C6.5 11.1716 7.17157 10.5 8 10.5C8.82843 10.5 9.5 11.1716 9.5 12ZM13.5 12C13.5 12.8284 12.8284 13.5 12 13.5C11.1716 13.5 10.5 12.8284 10.5 12C10.5 11.1716 11.1716 10.5 12 10.5C12.8284 10.5 13.5 11.1716 13.5 12ZM16 13.5C16.8284 13.5 17.5 12.8284 17.5 12C17.5 11.1716 16.8284 10.5 16 10.5C15.1716 10.5 14.5 11.1716 14.5 12C14.5 12.8284 15.1716 13.5 16 13.5Z"
              fill="#303030"
            />
          </svg>
        );
      }
      case Icons.ARCHIVE: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.54415 4.69957L2.55132 10.6781C2.51733 10.78 2.5 10.8868 2.5 10.9943V14.6654C2.5 15.7699 3.39543 16.6654 4.5 16.6654H15.5C16.6046 16.6654 17.5 15.7699 17.5 14.6654V10.9943C17.5 10.8868 17.4827 10.78 17.4487 10.6781L15.4558 4.69958C15.1836 3.88289 14.4193 3.33203 13.5585 3.33203H6.44152C5.58066 3.33203 4.81638 3.88289 4.54415 4.69957Z"
              stroke={color || '#515151'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.16797 8.33203H7.08464V8.7487C7.08464 10.3595 8.39047 11.6654 10.0013 11.6654V11.6654C11.6121 11.6654 12.918 10.3595 12.918 8.7487V8.33203H15.8346"
              stroke={color || '#515151'}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GREEN_LIGHT_CHECKMARK: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              d="M4 10L7 13L14 6"
              stroke="#00B152"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GREEN_LIGHT_CHECKMARK_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#00B152" />
            <g opacity="0.8">
              <path
                d="M11 17L14 20L21 13"
                stroke="#00B152"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      // case Icons.GREEN_LIGHT_DOUBLE_CHECKMARK: {
      //   return (
      //     <svg
      //       xmlns="http://www.w3.org/2000/svg"
      //       width="18"
      //       height="18"
      //       viewBox="0 0 18 18"
      //       fill="none"
      //     >
      //       <path
      //         d="M1 10L4 13L11 6"
      //         stroke="#00B152"
      //         strokeWidth="2"
      //         strokeLinecap="round"
      //         strokeLinejoin="round"
      //       />
      //       <path
      //         d="M7 10L10 13L17 6"
      //         stroke="#00B152"
      //         strokeWidth="2"
      //         strokeLinecap="round"
      //         strokeLinejoin="round"
      //       />
      //     </svg>
      //   );
      // }
      case Icons.MESSAGE: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.31866 13.3824L5.23873 13.7741L5.43659 13.3094L5.18002 12.8744L4.31866 13.3824ZM3.02841 16.4127L2.10834 16.0209H2.10834L3.02841 16.4127ZM3.6684 17.0751L4.02832 18.008L4.02833 18.008L3.6684 17.0751ZM6.85585 15.8454L7.33795 14.9693L6.93017 14.7449L6.49593 14.9124L6.85585 15.8454ZM15.7318 10.0007C15.7318 13.1303 13.1948 15.6673 10.0652 15.6673V17.6673C14.2994 17.6673 17.7318 14.2348 17.7318 10.0007H15.7318ZM10.0652 4.33398C13.1948 4.33398 15.7318 6.87104 15.7318 10.0007H17.7318C17.7318 5.76647 14.2994 2.33398 10.0652 2.33398V4.33398ZM4.39851 10.0007C4.39851 6.87104 6.93556 4.33398 10.0652 4.33398V2.33398C5.83099 2.33398 2.39851 5.76647 2.39851 10.0007H4.39851ZM5.18002 12.8744C4.68359 12.0326 4.39851 11.0515 4.39851 10.0007H2.39851C2.39851 11.4185 2.78431 12.7493 3.45729 13.8904L5.18002 12.8744ZM3.94848 16.8044L5.23873 13.7741L3.39858 12.9906L2.10834 16.0209L3.94848 16.8044ZM3.30849 16.1421C3.72078 15.983 4.1216 16.3978 3.94848 16.8044L2.10834 16.0209C1.58897 17.2407 2.79142 18.4852 4.02832 18.008L3.30849 16.1421ZM6.49593 14.9124L3.30848 16.1421L4.02833 18.008L7.21578 16.7784L6.49593 14.9124ZM10.0652 15.6673C9.07453 15.6673 8.14605 15.414 7.33795 14.9693L6.37376 16.7215C7.46975 17.3246 8.72894 17.6673 10.0652 17.6673V15.6673Z"
              fill="#515151"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.96558 10C7.96558 10.6904 7.40593 11.25 6.71558 11.25C6.02522 11.25 5.46558 10.6904 5.46558 10C5.46558 9.30964 6.02522 8.75 6.71558 8.75C7.40593 8.75 7.96558 9.30964 7.96558 10ZM11.2989 10C11.2989 10.6904 10.7393 11.25 10.0489 11.25C9.35855 11.25 8.79891 10.6904 8.79891 10C8.79891 9.30964 9.35855 8.75 10.0489 8.75C10.7393 8.75 11.2989 9.30964 11.2989 10ZM13.3822 11.25C14.0726 11.25 14.6322 10.6904 14.6322 10C14.6322 9.30964 14.0726 8.75 13.3822 8.75C12.6919 8.75 12.1322 9.30964 12.1322 10C12.1322 10.6904 12.6919 11.25 13.3822 11.25Z"
              fill="#515151"
            />
          </svg>
        );
      }
      case Icons.CONDO: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M20 28V6H5V28H20ZM20 28H27.9976V13L19.9976 11"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 28V23H15V28"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 10.5C9 10.2239 9.22386 10 9.5 10H10.5C10.7761 10 11 10.2239 11 10.5V11.5C11 11.7761 10.7761 12 10.5 12H9.5C9.22386 12 9 11.7761 9 11.5V10.5Z"
              fill="#515151"
            />
            <path
              d="M9 14.5C9 14.2239 9.22386 14 9.5 14H10.5C10.7761 14 11 14.2239 11 14.5V15.5C11 15.7761 10.7761 16 10.5 16H9.5C9.22386 16 9 15.7761 9 15.5V14.5Z"
              fill="#515151"
            />
            <path
              d="M9 18.5C9 18.2239 9.22386 18 9.5 18H10.5C10.7761 18 11 18.2239 11 18.5V19.5C11 19.7761 10.7761 20 10.5 20H9.5C9.22386 20 9 19.7761 9 19.5V18.5Z"
              fill="#515151"
            />
            <path
              d="M14 10.5C14 10.2239 14.2239 10 14.5 10H15.5C15.7761 10 16 10.2239 16 10.5V11.5C16 11.7761 15.7761 12 15.5 12H14.5C14.2239 12 14 11.7761 14 11.5V10.5Z"
              fill="#515151"
            />
            <path
              d="M14 14.5C14 14.2239 14.2239 14 14.5 14H15.5C15.7761 14 16 14.2239 16 14.5V15.5C16 15.7761 15.7761 16 15.5 16H14.5C14.2239 16 14 15.7761 14 15.5V14.5Z"
              fill="#515151"
            />
            <path
              d="M14 18.5C14 18.2239 14.2239 18 14.5 18H15.5C15.7761 18 16 18.2239 16 18.5V19.5C16 19.7761 15.7761 20 15.5 20H14.5C14.2239 20 14 19.7761 14 19.5V18.5Z"
              fill="#515151"
            />
            <path
              d="M23 15.5C23 15.2239 23.2239 15 23.5 15H24.5C24.7761 15 25 15.2239 25 15.5V16.5C25 16.7761 24.7761 17 24.5 17H23.5C23.2239 17 23 16.7761 23 16.5V15.5Z"
              fill="#515151"
            />
            <path
              d="M23 19.5C23 19.2239 23.2239 19 23.5 19H24.5C24.7761 19 25 19.2239 25 19.5V20.5C25 20.7761 24.7761 21 24.5 21H23.5C23.2239 21 23 20.7761 23 20.5V19.5Z"
              fill="#515151"
            />
            <path
              d="M23 23.5C23 23.2239 23.2239 23 23.5 23H24.5C24.7761 23 25 23.2239 25 23.5V24.5C25 24.7761 24.7761 25 24.5 25H23.5C23.2239 25 23 24.7761 23 24.5V23.5Z"
              fill="#515151"
            />
          </svg>
        );
      }
      case Icons.CLOSE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_69414_501700)">
              <path
                d="M18 6L6 18"
                stroke={color ?? '#747475'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke={color ?? '#747475'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_69414_501700">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.CLOSE_BG: {
        return (
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#F4F5F6" />
            <path d="M25 25L15 15" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
            <path d="M15 25L25 15" stroke="#262626" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.NEW_FEEDBACK: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_7081_55172)">
              <path
                d="M2 18V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H15C15.7957 2 16.5587 2.31607 17.1213 2.87868C17.6839 3.44129 18 4.20435 18 5V11C18 11.7956 17.6839 12.5587 17.1213 13.1213C16.5587 13.6839 15.7957 14 15 14H6L2 18Z"
                stroke={color || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 5.5V7.5"
                stroke={color || '#FF576D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle cx="10" cy="10.5" r="1" fill={color || '#FF576D'} />
            </g>
            <defs>
              <clipPath id="clip0_7081_55172">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.PENDING_APPROVAL: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3V2C4.44772 2 4 2.44772 4 3H5ZM5 17H4C4 17.5523 4.44772 18 5 18V17ZM15 17V18C15.5523 18 16 17.5523 16 17H15ZM15 3H16C16 2.44772 15.5523 2 15 2V3ZM4 3C4 5.75471 4.84613 7.23208 5.74074 8.27579C5.94812 8.51773 6.1555 8.73362 6.32483 8.91235C6.50275 9.10016 6.636 9.24426 6.74374 9.38193C6.93907 9.63151 7 9.79494 7 10H9C9 9.20506 8.68593 8.61849 8.31875 8.14932C8.14525 7.92761 7.95038 7.72016 7.77673 7.53686C7.5945 7.3445 7.42688 7.16977 7.25926 6.97421C6.65387 6.26792 6 5.24529 6 3H4ZM7 10C7 10.2051 6.93907 10.3685 6.74374 10.6181C6.636 10.7557 6.50275 10.8998 6.32483 11.0876C6.1555 11.2664 5.94812 11.4823 5.74074 11.7242C4.84613 12.7679 4 14.2453 4 17H6C6 14.7547 6.65387 13.7321 7.25926 13.0258C7.42688 12.8302 7.5945 12.6555 7.77673 12.4631C7.95038 12.2798 8.14525 12.0724 8.31875 11.8507C8.68593 11.3815 9 10.7949 9 10H7ZM5 18H15V16H5V18ZM16 17C16 14.2453 15.1539 12.7679 14.2593 11.7242C14.0519 11.4823 13.8445 11.2664 13.6752 11.0876C13.4973 10.8998 13.364 10.7557 13.2563 10.6181C13.0609 10.3685 13 10.2051 13 10H11C11 10.7949 11.3141 11.3815 11.6812 11.8507C11.8548 12.0724 12.0496 12.2798 12.2233 12.4631C12.4055 12.6555 12.5731 12.8302 12.7407 13.0258C13.3461 13.7321 14 14.7547 14 17H16ZM13 10C13 9.79494 13.0609 9.63151 13.2563 9.38193C13.364 9.24426 13.4973 9.10016 13.6752 8.91235C13.8445 8.73362 14.0519 8.51773 14.2593 8.27579C15.1539 7.23208 16 5.75471 16 3H14C14 5.24529 13.3461 6.26792 12.7407 6.97421C12.5731 7.16977 12.4055 7.3445 12.2233 7.53686C12.0496 7.72016 11.8548 7.92761 11.6812 8.14932C11.3141 8.61849 11 9.20506 11 10H13ZM15 2H5V4H15V2Z"
              fill="#EC455E"
            />
            <path
              d="M4 3H16"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 17H16"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M10 12C9.5 12 8 13.5 8 15H12C12 13.5 10.5 12 10 12Z" fill="#EC455E" />
            <path d="M10 8C9.75 8 9 7.5 9 7H11C11 7.5 10.25 8 10 8Z" fill="#EC455E" />
          </svg>
        );
      }
      case Icons.PENDING_APPROVAL_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#EC455E" />
            <path
              d="M14 16C14 14 11 14 11 9H21C21 14 18 14 18 16C18 18 21 18 21 23H11C11 18 14 18 14 16Z"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 9H22"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 23H22"
              stroke="#EC455E"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M16 18C15.5 18 14 19.5 14 21H18C18 19.5 16.5 18 16 18Z" fill="#EC455E" />
            <path d="M16 14C15.75 14 15 13.5 15 13H17C17 13.5 16.25 14 16 14Z" fill="#EC455E" />
          </svg>
        );
      }
      case Icons.PENDING_DULL: {
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.25 9C7.25 7.25 4.625 7.25 4.625 2.875H13.375C13.375 7.25 10.75 7.25 10.75 9C10.75 10.75 13.375 10.75 13.375 15.125H4.625C4.625 10.75 7.25 10.75 7.25 9Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.75 2.875H14.25"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.75 15.125H14.25"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 10.75C8.5625 10.75 7.25 12.0625 7.25 13.375H10.75C10.75 12.0625 9.4375 10.75 9 10.75Z"
              fill="#AAABAB"
            />
            <path
              d="M9 7.25C8.78125 7.25 8.125 6.8125 8.125 6.375H9.875C9.875 6.8125 9.21875 7.25 9 7.25Z"
              fill="#AAABAB"
            />
          </svg>
        );
      }
      case Icons.EDIT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 15H12.1314L20.3515 6.77998C20.7667 6.36472 21 5.80152 21 5.21426C21 4.627 20.7667 4.0638 20.3515 3.64854C19.9362 3.23329 19.373 3 18.7857 3C18.1985 3 17.6353 3.23329 17.22 3.64854L9 11.8686V15Z"
              fill="white"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.4368 4.43164L19.5682 7.56308"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 6H7.5C6.11929 6 5 7.11929 5 8.5V16.5C5 17.8807 6.11929 19 7.5 19H15.5C16.8807 19 18 17.8807 18 16.5V14"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EDIT_ROUND: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#747475" fillOpacity="0.1" />
            <path
              d="M10 22H13.1314L21.3515 13.78C21.7667 13.3647 22 12.8015 22 12.2143C22 11.627 21.7667 11.0638 21.3515 10.6485C20.9362 10.2333 20.373 10 19.7857 10C19.1985 10 18.6353 10.2333 18.22 10.6485L10 18.8686V22Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17.437 11.4319L20.5684 14.5633"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DELETE: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.00122 7H20.0012"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0012 11V17"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.9988 11V17"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.99878 7L5.99878 19C5.99878 19.5304 6.20949 20.0391 6.58457 20.4142C6.95964 20.7893 7.46835 21 7.99878 21H15.9988C16.5292 21 17.0379 20.7893 17.413 20.4142C17.7881 20.0391 17.9988 19.5304 17.9988 19L18.9988 7"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 7V4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3H14C14.2652 3 14.5196 3.10536 14.7071 3.29289C14.8946 3.48043 15 3.73478 15 4V7"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.MEGA_PHONE: {
        return (
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.1363 2.50059C12.3556 4.33901 8.74472 6.80982 2.94961 8.28897C2.14692 8.49385 1.62821 9.28708 1.80045 10.0974L2.00836 11.0755C2.1806 11.8859 2.9771 12.3995 3.79373 12.2602C9.68945 11.2544 13.9931 12.0429 15.454 13.4049C15.858 13.7815 16.4097 14.1485 16.9499 14.0337C17.4901 13.9188 17.835 13.3878 17.7201 12.8476L15.4331 2.08799C15.3183 1.54777 14.7873 1.20293 14.247 1.31775C13.7068 1.43258 13.3521 1.99224 13.1363 2.50059Z"
              stroke="#262626"
              strokeWidth="2"
              strokeLinejoin="round"
            />
            <path
              d="M5.35864 12.4082L8.30714 17.705C8.52066 18.0886 8.95939 18.2881 9.3888 18.1968L10.2764 18.0081C10.9345 17.8682 11.2695 17.1314 10.9423 16.5436L8.29309 11.7845"
              stroke="#262626"
              strokeWidth="2"
            />
          </svg>
        );
      }
      case Icons.ARROW_LEFT: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 5L7 10L12 15"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ARROW_DOWN: {
        return (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 4.5L6 8.5L10 4.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.ARROW_UP: {
        return (
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 7.5L6 3.5L10 7.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CONCIERGE_ATTORNEY: {
        return (
          <svg
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 19.6C21 17.2804 22.8804 15.4 25.2 15.4H55.1616C56.3019 15.4 57.3931 15.8636 58.1846 16.6843L73.023 32.0686C73.7781 32.8515 74.2 33.8967 74.2 34.9843V75.6C74.2 77.9196 72.3196 79.8 70 79.8H25.2C22.8804 79.8 21 77.9196 21 75.6V19.6Z"
              fill="#51BFE1"
            />
            <path
              d="M41.8298 84.5302C40.1896 82.89 40.1896 80.2307 41.8298 78.5905L74.3901 46.0302C76.0303 44.39 78.6896 44.39 80.3298 46.0302L98.8627 64.5632C100.503 66.2034 100.503 68.8627 98.8627 70.5029L66.3024 103.063C64.6622 104.703 62.0029 104.703 60.3627 103.063L41.8298 84.5302Z"
              fill="#928CDA"
            />
            <path
              d="M74.1999 46.2207V75.6001C74.1999 77.9197 72.3195 79.8001 69.9999 79.8001H40.9854C41.1874 79.362 41.469 78.9516 41.8301 78.5906L74.1999 46.2207Z"
              fill="#2E69C0"
            />
          </svg>
        );
      }
      case Icons.VIEW: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 22L5.16667 20.6387C6.522 19.7871 8.14466 19.7871 9.5 20.6387C10.8553 21.4902 12.478 21.4902 13.8333 20.6387C15.1887 19.7871 16.8113 19.7871 18.1667 20.6387C19.522 21.4902 21.1447 21.4902 22.5 20.6387C23.8553 19.7871 25.478 19.7871 26.8333 20.6387L29 22"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M22.836 19.9803C23.0615 18.8999 23.0544 17.7797 22.8151 16.7026C22.5758 15.6255 22.1104 14.6188 21.4534 13.7568C20.7964 12.8949 19.9644 12.1997 19.0189 11.7226C18.0734 11.2454 17.0384 10.9985 15.9905 11C14.9425 11.0015 13.9082 11.2514 12.9639 11.7313C12.0196 12.2112 11.1895 12.9087 10.5347 13.7725C9.87986 14.6364 9.41712 15.6444 9.1806 16.7222C8.94409 17.8 8.93982 18.9202 9.16812 20"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M3 27V5C3 4.44772 3.44772 4 4 4H28C28.5523 4 29 4.44772 29 5V27C29 27.5523 28.5523 28 28 28H4C3.44772 28 3 27.5523 3 27Z"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOTS: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F4F5F6" />
            <circle cx="9" cy="16" r="1.5" fill="#262626" />
            <circle cx="16" cy="16" r="1.5" fill="#262626" />
            <circle cx="23" cy="16" r="1.5" fill="#262626" />
          </svg>
        );
      }
      case Icons.DOTSWHITE: {
        return (
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="#FFFFFF"
            xmlns="http://www.w3.org/2000/svg"
            className={svgClass}
          >
            <rect width="44" height="44" rx="16" fill="#FFFFFFF"></rect>
            <circle cx="12" cy="22" r="1.5" fill="#262626"></circle>
            <circle cx="21" cy="22" r="1.5" fill="#262626"></circle>
            <circle cx="30" cy="22" r="1.5" fill="#262626"></circle>
          </svg>
        );
      }
      case Icons.BOOKMARK: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0007 5.33398H20.0006C20.7079 5.33398 21.3862 5.61494 21.8863 6.11503C22.3864 6.61513 22.6673 7.29341 22.6673 8.00065V26.6673L16.0006 22.6673L9.33398 26.6673V8.00065C9.33398 7.29341 9.61494 6.61513 10.115 6.11503C10.6151 5.61494 11.2934 5.33398 12.0007 5.33398Z"
              fill="#FF576D"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TRASH: {
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66797 4.66602H13.3346"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6.66797 7.33398V11.334"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.33203 7.33398V11.334"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.33203 4.66602L3.9987 12.666C3.9987 13.0196 4.13917 13.3588 4.38922 13.6088C4.63927 13.8589 4.97841 13.9993 5.33203 13.9993H10.6654C11.019 13.9993 11.3581 13.8589 11.6082 13.6088C11.8582 13.3588 11.9987 13.0196 11.9987 12.666L12.6654 4.66602"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 4.66667V2.66667C6 2.48986 6.07024 2.32029 6.19526 2.19526C6.32029 2.07024 6.48986 2 6.66667 2H9.33333C9.51014 2 9.67971 2.07024 9.80474 2.19526C9.92976 2.32029 10 2.48986 10 2.66667V4.66667"
              stroke="#FF576D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.FILTER: {
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.2256 4.996C2.94272 4.67271 3.1723 4.16675 3.60188 4.16675H16.3981C16.8277 4.16675 17.0573 4.67271 16.7744 4.996L11.6667 10.8334V15.5244C11.6667 15.7138 11.5597 15.8869 11.3903 15.9716L9.05694 17.1383C8.72449 17.3045 8.33333 17.0628 8.33333 16.6911V10.8334L3.2256 4.996Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.USER: {
        return (
          <svg
            width="24"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.75 6.25C13.75 8.32107 12.0711 10 10 10C7.92893 10 6.25 8.32107 6.25 6.25C6.25 4.17893 7.92893 2.5 10 2.5C12.0711 2.5 13.75 4.17893 13.75 6.25Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M4.375 17.1875C4.72557 15.1843 6.33733 13.4375 8.12489 13.4375H11.875C13.6626 13.4375 15.2744 15.1843 15.625 17.1875"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.GRID: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="5" width="7" height="5" rx="1" stroke="#262626" strokeWidth="2" />
            <rect x="3" y="14" width="7" height="5" rx="1" stroke="#262626" strokeWidth="2" />
            <rect x="14" y="14" width="7" height="5" rx="1" stroke="#262626" strokeWidth="2" />
            <rect x="14" y="5" width="7" height="5" rx="1" stroke="#262626" strokeWidth="2" />
          </svg>
        );
      }

      case Icons.LIST: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4.5" cy="6" r="2" fill="#515151" />
            <circle cx="4.5" cy="12" r="2" fill="#515151" />
            <circle cx="4.5" cy="18" r="2" fill="#515151" />
            <path
              d="M10 6H20"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 12H20"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 18H20"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }

      case Icons.TEAM_USER: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7057 8.2607C15.7057 10.3075 14.0465 11.9667 11.9997 11.9667C9.95294 11.9667 8.2937 10.3075 8.2937 8.2607C8.2937 6.21393 9.95294 4.55469 11.9997 4.55469C14.0465 4.55469 15.7057 6.21393 15.7057 8.2607Z"
              stroke="#515151"
              strokeWidth="2"
            />
            <path
              d="M6.44067 19.0703C6.78713 17.0905 8.37999 15.3643 10.1466 15.3643H13.8527C15.6193 15.3643 17.2123 17.0905 17.5587 19.0703"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.3853 7.02539C19.8448 7.27026 20.957 8.53966 20.957 10.0688C20.957 11.598 19.8448 12.8674 18.3853 13.1123"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.4138 15.9844C20.8849 15.9844 22.2114 17.4219 22.4999 19.0705"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.6145 7.02539C4.15492 7.27026 3.04275 8.53966 3.04275 10.0688C3.04275 11.598 4.15492 12.8674 5.6145 13.1123"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M4.58569 15.9844C3.11461 15.9844 1.78809 17.4219 1.49959 19.0705"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.TABLE_VIEW: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="4.5" cy="6" r="2" fill="#262626" />
            <circle cx="4.5" cy="12" r="2" fill="#262626" />
            <circle cx="4.5" cy="18" r="2" fill="#262626" />
            <path
              d="M10 6H20"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 12H20"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 18H20"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CARD_VIEW: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="3" y="5" width="7" height="5" rx="1" stroke="#515151" strokeWidth="2" />
            <rect x="3" y="14" width="7" height="5" rx="1" stroke="#515151" strokeWidth="2" />
            <rect x="14" y="14" width="7" height="5" rx="1" stroke="#515151" strokeWidth="2" />
            <rect x="14" y="5" width="7" height="5" rx="1" stroke="#515151" strokeWidth="2" />
          </svg>
        );
      }
      case Icons.ALERT: {
        return (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z"
              fill="#EC455E"
            />
            <path
              d="M9 6.33203V8.9987"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="9" cy="12" r="0.5" fill="#EC455E" stroke="white" />
          </svg>
        );
      }
      case Icons.ALERT_BG: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#EC455E" />
            <g clipPath="url(#clip0_34245_177710)">
              <path
                d="M23.75 16C23.75 11.7198 20.2802 8.25 16 8.25C11.7198 8.25 8.25 11.7198 8.25 16C8.25 20.2802 11.7198 23.75 16 23.75C20.2802 23.75 23.75 20.2802 23.75 16Z"
                stroke="#EC455E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 16C16 14.6983 16 14.1767 16 12.875"
                stroke="#EC455E"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle
                cx="1.25"
                cy="1.25"
                r="1.25"
                transform="matrix(1 0 0 -1 14.75 21)"
                fill="#EC455E"
              />
            </g>
            <defs>
              <clipPath id="clip0_34245_177710">
                <rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 6 26)" />
              </clipPath>
            </defs>
          </svg>
        );
      }
      case Icons.TASK_COMMENT: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.18229 16.0581L6.10236 16.4498L6.30022 15.9851L6.04365 15.5501L5.18229 16.0581ZM3.54135 19.912L2.62128 19.5203H2.62128L3.54135 19.912ZM4.18134 20.5744L4.54126 21.5074H4.54126L4.18134 20.5744ZM8.22693 19.0137L8.70902 18.1376L8.30125 17.9132L7.86701 18.0807L8.22693 19.0137ZM19.0781 12C19.0781 15.866 15.9441 19 12.0781 19V21C17.0487 21 21.0781 16.9706 21.0781 12H19.0781ZM12.0781 5C15.9441 5 19.0781 8.13401 19.0781 12H21.0781C21.0781 7.02944 17.0487 3 12.0781 3V5ZM5.07811 12C5.07811 8.13401 8.21212 5 12.0781 5V3C7.10755 3 3.07811 7.02944 3.07811 12H5.07811ZM6.04365 15.5501C5.43029 14.51 5.07811 13.2977 5.07811 12H3.07811C3.07811 13.6647 3.53101 15.2267 4.32093 16.5661L6.04365 15.5501ZM4.46142 20.3038L6.10236 16.4498L4.26222 15.6663L2.62128 19.5203L4.46142 20.3038ZM3.82142 19.6414C4.23373 19.4824 4.63454 19.8972 4.46142 20.3038L2.62128 19.5203C2.10191 20.7401 3.30435 21.9846 4.54126 21.5074L3.82142 19.6414ZM7.86701 18.0807L3.82142 19.6414L4.54126 21.5074L8.58685 19.9467L7.86701 18.0807ZM12.0781 19C10.8548 19 9.70754 18.687 8.70902 18.1376L7.74483 19.8898C9.03123 20.5977 10.5092 21 12.0781 21V19Z"
              fill={color}
            />
          </svg>
        );
      }
      case Icons.HISTORY: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 8V12L14 14"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.05078 11.0001C3.27487 8.80013 4.30104 6.75968 5.93351 5.26803C7.56598 3.77639 9.69048 2.93795 11.9017 2.9127C14.1128 2.88744 16.2559 3.67713 17.922 5.1311C19.5882 6.58507 20.6607 8.60155 20.935 10.7958C21.2092 12.99 20.6661 15.2085 19.4091 17.0278C18.1522 18.8472 16.2694 20.1401 14.1201 20.6599C11.9707 21.1797 9.70519 20.8901 7.75578 19.8461C5.80636 18.8022 4.3095 17.0772 3.55078 15.0001M3.05078 20.0001V15.0001H8.05078"
              stroke={color}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.GREEN_LIGHT_DOUBLE_CHECKMARK: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle opacity="0.1" cx="16" cy="16" r="16" fill="#00B152" />
            <g opacity="0.8">
              <path
                d="M8 17L11 20L18 13"
                stroke="#00B152"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 17L17 20L24 13"
                stroke="#00B152"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
      }
      case Icons.OFFER: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#D6D6D7" fillOpacity="0.25" />
            <path
              d="M10.1665 11.8346H21.8332M21.8332 11.8346L18.9165 8.91797M21.8332 11.8346L18.9165 14.7513"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.8333 20.1667H10.1666M10.1666 20.1667L13.0833 17.25M10.1666 20.1667L13.0833 23.0833"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PROJECT_DOCUMENT: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M8 5.00022H6.94444C6.42875 5.00022 5.93417 5.20508 5.56951 5.56973C5.20486 5.93439 5 6.42896 5 6.94466V18.0558C5 18.5715 5.20486 19.066 5.56951 19.4307C5.93417 19.7954 6.42875 20.0002 6.94444 20.0002L17.0556 20C17.5713 20 18.0658 19.7951 18.4305 19.4305C18.7951 19.0658 19 18.5713 19 18.0556V6.94444C19 6.42875 18.7951 5.93417 18.4305 5.56951C18.0658 5.20486 17.5713 5 17.0556 5H16"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 3H10.5C9.39543 3 8.5 3.89543 8.5 5C8.5 6.10457 9.39543 7 10.5 7H13.5C14.6046 7 15.5 6.10457 15.5 5C15.5 3.89543 14.6046 3 13.5 3Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 11H14.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.5 15H14.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PROJECT_OVERVIEW: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
          >
            <path
              d="M10 6H8.22222C7.63285 6 7.06762 6.23413 6.65087 6.65087C6.23413 7.06762 6 7.63285 6 8.22222L6 21.7778C6 22.3671 6.23413 22.9324 6.65087 23.3491C7.06762 23.7659 7.63285 24 8.22222 24H19.7778C20.3671 24 20.9324 23.7659 21.3491 23.3491C21.7659 22.9324 22 22.3671 22 21.7778V8.22222C22 7.63285 21.7659 7.06762 21.3491 6.65087C20.9324 6.23413 20.3671 6 19.7778 6H18"
              stroke="#F4F6FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.5 4H12.5C11.3954 4 10.5 4.89543 10.5 6C10.5 7.10457 11.3954 8 12.5 8H15.5C16.6046 8 17.5 7.10457 17.5 6C17.5 4.89543 16.6046 4 15.5 4Z"
              stroke="#F4F6FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 13H17"
              stroke="#F4F6FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 18H17"
              stroke="#F4F6FB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.CALENDAR: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#D6D6D7" fillOpacity="0.31" />
            <path
              d="M20.5 10H11.5C10.6716 10 10 10.6716 10 11.5V20.5C10 21.3284 10.6716 22 11.5 22H20.5C21.3284 22 22 21.3284 22 20.5V11.5C22 10.6716 21.3284 10 20.5 10Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 9V11"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13 9V11"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 14H22"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.RESET: {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 19V15H8"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20 5V9H16"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.80965 15.507C5.56908 17.064 6.81344 18.3325 8.35564 19.1217C9.89785 19.9109 11.6546 20.1781 13.3617 19.8833C15.0688 19.5884 16.6341 18.7473 17.8222 17.4865C19.0103 16.2258 19.757 14.6134 19.9501 12.8918"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.1904 8.49303C18.4309 6.93596 17.1866 5.66748 15.6444 4.8783C14.1022 4.08911 12.3454 3.82187 10.6383 4.11674C8.9312 4.41161 7.36593 5.25266 6.17782 6.51345C4.98971 7.77425 4.24297 9.38665 4.04986 11.1082"
              stroke="#262626"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.LOCATION: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#D6D6D7" fillOpacity="0.31" />
            <ellipse cx="16" cy="15.1665" rx="2.5" ry="2.5" stroke="#515151" strokeWidth="2" />
            <path
              d="M22.6667 15.1667C22.6667 18.8846 18.9805 22.3721 17.0902 23.9162C16.4487 24.4401 15.5513 24.4401 14.9098 23.9162C13.0195 22.3721 9.33334 18.8846 9.33334 15.1667C9.33334 11.4848 12.3181 8.5 16 8.5C19.6819 8.5 22.6667 11.4848 22.6667 15.1667Z"
              stroke="#515151"
              strokeWidth="2"
            />
          </svg>
        );
      }
      case Icons.OFFER_2: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#D6D6D7" fillOpacity="0.25" />
            <path
              d="M17.6665 8.5V11.8333C17.6665 12.0543 17.7543 12.2663 17.9106 12.4226C18.0669 12.5789 18.2788 12.6667 18.4998 12.6667H21.8332"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.1665 23.5H11.8332C11.3911 23.5 10.9672 23.3244 10.6547 23.0118C10.3421 22.6993 10.1665 22.2754 10.1665 21.8333V10.1667C10.1665 9.72464 10.3421 9.30072 10.6547 8.98816C10.9672 8.67559 11.3911 8.5 11.8332 8.5H17.6665L21.8332 12.6667V21.8333C21.8332 22.2754 21.6576 22.6993 21.345 23.0118C21.0325 23.3244 20.6085 23.5 20.1665 23.5Z"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 13.5H14.3333"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 16.8334H18.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 20.1666H18.5"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.OFFER_3: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#D6D6D7" fillOpacity="0.25" />
            <path
              d="M17.6665 8.5V11.8333C17.6665 12.0543 17.7543 12.2663 17.9106 12.4226C18.0669 12.5789 18.2788 12.6667 18.4998 12.6667H21.8332"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.1665 23.5H11.8332C11.3911 23.5 10.9672 23.3244 10.6547 23.0118C10.3421 22.6993 10.1665 22.2754 10.1665 21.8333V10.1667C10.1665 9.72464 10.3421 9.30072 10.6547 8.98816C10.9672 8.67559 11.3911 8.5 11.8332 8.5H17.6665L21.8332 12.6667V21.8333C21.8332 22.2754 21.6576 22.6993 21.345 23.0118C21.0325 23.3244 20.6085 23.5 20.1665 23.5Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 13.5H14.3333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 16.8333H18.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.5 20.1667H18.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.EXPAND: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path
              d="M16 11V21"
              stroke="#515151"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.COLLAPSE: {
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      }
      case Icons.SEARCH: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.4444 15.8889C13.4513 15.8889 15.8889 13.4513 15.8889 10.4444C15.8889 7.43756 13.4513 5 10.4444 5C7.43756 5 5 7.43756 5 10.4444C5 13.4513 7.43756 15.8889 10.4444 15.8889Z"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9997 18.9997L14.333 14.333"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PREVIEW_MENU: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2 12.6671C2.91212 12.1405 3.94678 11.8633 5 11.8633C6.05322 11.8633 7.08788 12.1405 8 12.6671C8.91212 12.1405 9.94678 11.8633 11 11.8633C12.0532 11.8633 13.0879 12.1405 14 12.6671"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916C8.91212 3.47255 9.94678 3.19531 11 3.19531C12.0532 3.19531 13.0879 3.47255 14 3.99916"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 4V12.6667"
              stroke="#FF576D"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.DOWNLOAD_MENU: {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2.66699 11.332V12.6654C2.66699 13.019 2.80747 13.3581 3.05752 13.6082C3.30757 13.8582 3.6467 13.9987 4.00033 13.9987H12.0003C12.3539 13.9987 12.6931 13.8582 12.9431 13.6082C13.1932 13.3581 13.3337 13.019 13.3337 12.6654V11.332"
              stroke="#ED4F64"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4.66699 7.33203L8.00033 10.6654L11.3337 7.33203"
              stroke="#ED4F64"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 2.66797V10.668"
              stroke="#ED4F64"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      }
      case Icons.PLUS_CIRCLE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
            <path d="M16 21L16 11" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.MINUS_CIRCLE:
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="16" cy="16" r="16" fill="#F4F5F6" />
            <path d="M11 16H21" stroke="#515151" strokeWidth="2" strokeLinecap="round" />
          </svg>
        );
      case Icons.OPEN_LINK:
        return (
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 3H4.5C3.67157 3 3 3.67157 3 4.5V11.5C3 12.3284 3.67157 13 4.5 13H11.5C12.3284 13 13 12.3284 13 11.5V10.5"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9 3H13M13 3V7M13 3L8 8"
              stroke="#747475"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      case Icons.RESTRICTED:
        return (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.5097 5.41663C13.3138 4.68642 11.8191 4.16663 10.0003 4.16663C4.16699 4.16663 1.66699 9.51385 1.66699 9.99996C1.66699 10.3291 2.81316 12.8869 5.36417 14.5044M16.9645 7.59052C17.8975 8.74582 18.3337 9.79692 18.3337 9.99996C18.3337 10.4861 15.8337 15.8333 10.0003 15.8333C9.5814 15.8333 9.17966 15.8057 8.7948 15.7543"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.2752 11.038C12.0275 11.5799 11.5919 12.0179 11.0518 12.2687"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6667 8.13658C11.2244 7.74071 10.6403 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 10.6403 7.74071 11.2244 8.13658 11.6667"
              stroke="#AAABAB"
              strokeWidth="2"
            />
            <path
              d="M2.5 17.5L17.5 2.5"
              stroke="#AAABAB"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return null;
    }
  };
  return (
    <div testid={testid} onClick={onClick} className={className}>
      {renderIcon()}
    </div>
  );
};

Icons.ADD = 'add';
Icons.UPLOAD = 'upload';
Icons.DOCUMENT = 'document';
Icons.DOCUMENTS = 'documents';
Icons.MORE = 'more';
Icons.BOOKMARK = 'bookmark';
Icons.TRASH = 'trash';
Icons.COMMENT = 'comment';
Icons.ARCHIVE = 'archive';
Icons.GREEN_LIGHT_CHECKMARK = 'greenLightCheckmark';
Icons.GREEN_LIGHT_CHECKMARK_BG = 'greenLightCheckmarkBg';
Icons.GREEN_LIGHT_DOUBLE_CHECKMARK = 'greenLightDoubleCheckmark';
Icons.CLOSE = 'close';
Icons.CLOSE_BG = 'closeBg';
Icons.PENDING_APPROVAL = 'pendingApproval';
Icons.PENDING_APPROVAL_BG = 'pendingApproval_bg';
Icons.PENDING_DULL = 'pendingDull';
Icons.NEW_FEEDBACK = 'newFeedback';
Icons.CONDO = 'condo';
Icons.MESSAGE = 'message';
Icons.EDIT = 'edit';
Icons.EDIT_ROUND = 'editRound';
Icons.DELETE = 'delete';
Icons.MEGA_PHONE = 'megaPhone';
Icons.ARROW_LEFT = 'arrowLeft';
Icons.ARROW_DOWN = 'arrowDown';
Icons.ARROW_UP = 'arrowUp';
Icons.VIEW = 'view';
Icons.DOTS = 'dots';
Icons.DOTSWHITE = 'dotswhite';
Icons.CONCIERGE_ATTORNEY = 'conciergeAttorney';
Icons.FILTER = 'filter';
Icons.USER = 'user';
Icons.TEAM_USER = 'teamUser';
Icons.TABLE_VIEW = 'tableView';
Icons.CARD_VIEW = 'cardView';
Icons.GRID = 'grid';
Icons.LIST = 'list';
Icons.ALERT = 'alert';
Icons.ALERT_BG = 'alertBg';
Icons.CLOSING_INFO = 'info';
Icons.TASK_COMMENT = 'taskComment';
Icons.FILES = 'files';
Icons.HISTORY = 'history';
Icons.OFFER = 'offer';
Icons.OFFER_2 = 'offer_2';
Icons.OFFER_3 = 'offer_3';
Icons.EXPAND = 'expand';
Icons.COLLAPSE = 'collapse';
Icons.PROJECT_DOCUMENT = 'projectDocument';
Icons.PROJECT_OVERVIEW = 'projectOverview';
Icons.CALENDAR = 'calendar';
Icons.RESET = 'reset';
Icons.LOCATION = 'location';
Icons.SEARCH = 'search';
Icons.DOWNLOAD_MENU = 'downloadMenu';
Icons.PREVIEW_MENU = 'previewMenu';
Icons.PLUS_CIRCLE = 'plusCircle';
Icons.MINUS_CIRCLE = 'minusCircle';
Icons.OPEN_LINK = 'openLink';
Icons.RESTRICTED = 'restricted';
Icons.PLUS = 'plus';

Icons.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  testid: PropTypes.string,
  svgClass: PropTypes.string,
};

Icons.defaultProps = {
  svgClass: '',
  className: '',
  variant: '',
  color: '',
  testid: undefined,
  onClick: () => {},
};

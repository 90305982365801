import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { Area, AreaConfig } from '@ant-design/plots';
import styles from './styles.module.scss';
import moment from 'moment';
import { ArrowDown, ArrowUp } from './icons';
import { getAddressFormattedCassAddress } from 'helpers/cass';
import { useHistory } from 'react-router-dom';

type Props = {
  address: any;
  wrapperClassname?: string;
  valuationData: any;
};

const getConfig = (data: any) =>
  ({
    data,
    xField: 'day',
    yField: 'value',
    color: '#928CDA',
    responsive: true,
    autoFit: true,
    height: 80,
    padding: [20, 0],
    smooth: true,
    areaStyle: {
      fill: 'l(270) 0:#4673D100 1:#4673D1',
    },
    xAxis: false,
    yAxis: false,
    tooltip: {
      showTitle: false,
      showMarkers: true,
      formatter: (datum: any) => ({
        name: 'Value',
        value: `$${datum.value.toLocaleString()}`,
      }),
    },
  } as AreaConfig);

export const AddressDetails: React.FC<Props> = ({ address, wrapperClassname, valuationData }) => {
  const history = useHistory();

  const [addressValuationData, setAddressValuationData] = React.useState<any>(null);
  const [rentalValuationData, setRentalValuationData] = React.useState<any>(null);
  const isNotRecentAddress =
    address?.CreatedDate && moment().diff(moment(address?.CreatedDate), 'minutes') > 5;
  const { address1, address2 } = getAddressFormattedCassAddress(address?.address);

  useEffect(() => {
    if (valuationData) {
      if (valuationData?.addressValuations.valuations?.length > 0) {
        const addressData = valuationData?.addressValuations.valuations.map((valuation) => ({
          day: moment(valuation.UpdatedDate).format('DD MMM YYYY'),
          value: valuation?.homeValue ? Number(valuation.homeValue) : 0,
        }));
        const rentalData = valuationData?.addressValuations.valuations.map((valuation) => ({
          day: moment(valuation.UpdatedDate).format('DD MMM YYYY'),
          value: valuation?.rentalValue ? Number(valuation.rentalValue) : 0,
        }));
        setAddressValuationData(addressData);
        setRentalValuationData(rentalData);
      }
    }
  }, [valuationData]);

  const openAddress = useCallback(() => {
    history.push('/intel', {
      address1Param: address1,
      address2Param: address2,
      addressIdParam: address?.Id,
    });
  }, [address?.address]);

  const renderPlaceHolder = () => {
    return isNotRecentAddress ? (
      <div>-</div>
    ) : (
      <div>
        <div className={styles.placeholderContainer}>
          <div className={classNames(styles.placeholderText)}>Calculating...</div>
          <div className={styles.whiteOverlay} />
        </div>
        <div className={styles.placeholderLine} />
      </div>
    );
  };

  return (
    <div className={classNames(styles.addressWrapper, wrapperClassname)}>
      <div className={styles.header} onClick={openAddress}>
        <div className={styles.title}>{address1}</div>
        <div className={styles.subtitle}>{address2}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.plotContainer}>
          <>
            <div className={styles.title}>Estimated Home Value</div>
            <div className={classNames(styles.subtitle, styles.purple)}>
              {valuationData?.addressValuations?.homeValue
                ? '$' +
                  Number(valuationData?.addressValuations?.homeValue).toLocaleString('en-US', {
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  })
                : renderPlaceHolder()}

              {valuationData?.addressValuations?.homeValue &&
              valuationData?.addressValuations?.homeValue !== 0 &&
              addressValuationData?.length >= 2 ? (
                <span
                  className={
                    !valuationData?.addressValuations.homeValueUp &&
                    valuationData?.addressValuations.homePercent
                      ? styles.decrease
                      : styles.increase
                  }
                >
                  <div>
                    {!valuationData?.addressValuations?.homeValueUp &&
                    valuationData?.addressValuations?.homePercent ? (
                      <ArrowDown />
                    ) : (
                      <ArrowUp />
                    )}
                    {valuationData?.addressValuations?.homePercent
                      ? parseFloat(valuationData?.addressValuations?.homePercent).toFixed(2)
                      : 0}
                    %
                  </div>
                </span>
              ) : null}
            </div>

            {addressValuationData && (
              <div className={styles.plot}>
                <Area
                  className={styles.areaGraphPadding}
                  {...getConfig(addressValuationData)}
                ></Area>
              </div>
            )}
          </>
        </div>
        <div className={styles.plotContainer}>
          <>
            <div className={styles.title}>Estimated Rental Value</div>
            <div className={classNames(styles.subtitle, styles.aqua)}>
              {valuationData?.addressValuations?.rentalValue
                ? '$' +
                  Number(valuationData?.addressValuations?.rentalValue).toLocaleString('en-US', {
                    currency: 'USD',
                    maximumFractionDigits: 0,
                  })
                : renderPlaceHolder()}

              {valuationData?.addressValuations?.rentalValue &&
              valuationData?.addressValuations?.rentalValue !== 0 &&
              rentalValuationData?.length >= 2 ? (
                <span
                  className={
                    !valuationData?.addressValuations?.rentalValueUp &&
                    valuationData?.addressValuations?.rentalPercent
                      ? styles.decrease
                      : styles.increase
                  }
                >
                  <div>
                    {!valuationData?.addressValuations.rentalValueUp &&
                    valuationData?.addressValuations.rentalPercent ? (
                      <ArrowDown />
                    ) : (
                      <ArrowUp />
                    )}
                    {valuationData?.addressValuations?.rentalPercent
                      ? parseFloat(valuationData?.addressValuations?.rentalPercent).toFixed(2)
                      : 0}
                    %
                  </div>
                </span>
              ) : null}
            </div>

            {rentalValuationData && (
              <div>
                <Area
                  className={styles.areaGraphPadding}
                  {...getConfig(rentalValuationData)}
                ></Area>
              </div>
            )}
          </>
        </div>
      </div>

      <div className={styles.activityWrapper}>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>Neighborhood Activity</div>
          <div className={classNames(styles.activityCardContent, styles.orangeTitle)}>
            {valuationData?.neighborhoodActivities?.total !== 0 ||
            valuationData?.addressValuations?.homeValue ||
            valuationData?.addressValuations?.rentalValue ? (
              valuationData?.neighborhoodActivities?.total
            ) : (
              <div className={styles.dots}>...</div>
            )}
          </div>
        </div>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>Reminders</div>
          <div className={classNames(styles.activityCardContent, styles.redTitle)}>
            {valuationData?.reminders?.length || <div className={styles.dots}>...</div>}
          </div>
        </div>
        <div className={styles.activityCard}>
          <div className={styles.activityCardTitle}>New Intel</div>
          <div className={classNames(styles.activityCardContent, styles.greenTitle)}>
            <div className={styles.dots}>...</div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { combineReducers } from 'redux';
import propertyReferenceDetails from './propertyReferenceDetails';
import propertyReferenceData from './propertyReferenceData';
import propertyReferenceStats from './propertyReferenceStats';
import propertyReferenceNeighborhood from './propertyReferenceNeighborhood';
import propertyReferenceNeighborhoodActivities from './propertyReferenceNeighborhoodActivities';
import propertyReferenceAvmAdjustment from './propertyReferenceAvmAdjustment';

export default combineReducers({
  propertyReferenceData,
  propertyReferenceDetails,
  propertyReferenceStats,
  propertyReferenceNeighborhood,
  propertyReferenceNeighborhoodActivities,
  propertyReferenceAvmAdjustment,
});

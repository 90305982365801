import moment from 'moment';
import Sort from 'pages/Dashboard/components/Sort';
import { sortUsersEffect } from 'store/effects/adminPanel/users';
import { getUsersSortSelector } from 'store/selectors/adminPanel';

export const gridColumns = [
  {
    title: 'Name',
    propKey: 'FirstName',
    children: <Sort selector={getUsersSortSelector} effect={sortUsersEffect} field="Name" />,
    render: (firstName, { LastName }) => (
      <span>
        {firstName}
        &nbsp;
        {LastName}
      </span>
    ),
  },
  {
    title: 'Roles',
    propKey: 'Roles',
    render: (userRoles) => {
      const rolesString = userRoles.join(', '); // eslint-disable-line
      return <span>{rolesString}</span>;
    },
  },
  {
    title: 'Email',
    propKey: 'Email',
    children: <Sort selector={getUsersSortSelector} effect={sortUsersEffect} field="Email" />,
  },
  {
    title: 'Email Verified',
    propKey: 'IsEmailVerified',
    render: (IsEmailVerified) => <span>{IsEmailVerified ? 'Yes' : 'No'}</span>,
  },
  {
    title: 'MFA Enabled',
    propKey: 'IsMfaEnabled',
    render: (IsMfaEnabled) => <span>{IsMfaEnabled ? 'Yes' : 'No'}</span>,
  },
  {
    title: 'Created Date',
    propKey: 'CreatedDate',
    children: <Sort selector={getUsersSortSelector} effect={sortUsersEffect} field="CreatedDate" />,
    render: (CreatedDate) => <span>{moment(CreatedDate).format('MM-DD-YYYY')}</span>,
  },
];

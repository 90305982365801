import React from 'react';

const WorldGlobe = ({ color, ...props }: React.SVGAttributes<any>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.9996 17.0846C13.9116 17.0846 17.0829 13.9133 17.0829 10.0013C17.0829 6.08929 13.9116 2.91797 9.9996 2.91797C6.08758 2.91797 2.91626 6.08929 2.91626 10.0013C2.91626 13.9133 6.08758 17.0846 9.9996 17.0846Z"
      stroke={color || 'white'}
      strokeWidth="1.5"
    />
    <path
      d="M9.16666 13.7495C8.83334 13.4161 8.75 11.6661 8.75 10.8328C6.66666 9.99951 4.92904 8.19097 3.75 5.83283C5.5857 2.16143 9.6276 2.13681 12.9166 3.33283C12.9791 3.35555 12.4167 5.16615 12.0833 5.83281C11.6667 6.66615 11.25 7.08283 10.4167 7.4995C9.58334 7.91616 8.33334 7.91615 8.75 9.16615C9.16666 10.4161 10.8333 9.9995 12.0833 10.4162C13.0834 10.7495 13.3334 11.5273 12.9166 12.0828C14.1666 12.0828 15.5 12.5829 15.8334 12.9161C16.25 13.3329 15 15.4161 14.5834 15.8328C14.25 16.1661 11.8055 17.2218 10.8333 17.4995C10.2169 16.2666 10.2086 14.7914 9.16666 13.7495Z"
      fill={color || 'white'}
    />
  </svg>
);

export default WorldGlobe;

import classNames from 'classnames';

import styles from './style.module.scss';

type FormHeaderProps = {
  heading: string;
  className?: string;
};

export const DocumentModalHeader = ({ heading, className }: FormHeaderProps) => {
  return (
    <div className={classNames(styles.header, className)}>
      <h1 className={styles.heading}>{heading}</h1>
    </div>
  );
};

import { handleActions } from 'redux-actions';
import { ERROR, IDLE } from 'settings/constants/apiState';
import { get, cloneDeep } from 'lodash-es';
import { appLogoutAction } from 'store/actions/app';
import { sortItems } from 'store/reducers/adminPanel/helpers';
import {
  requestGetUsersDashboardAction,
  requestPostActivateUserAction,
  requestPostDeactivateUserAction,
  searchUsersDashboardAction,
  sortUsersDashboardAction,
  requestPutUserDashboardAction,
  toggleUserMFADashboardAction,
} from 'store/actions/adminPanel';

const initialData = {
  state: IDLE,
  data: null,
  filters: {
    searchString: '',
  },
  sort: {
    order: 'asc',
    fields: ['Name'],
  },
  meta: {},
};

export default handleActions(
  {
    [requestGetUsersDashboardAction]: (state, { payload }) => ({
      ...state,
      state: payload.state,
      data: get(payload, 'data.result', initialData.data),
      meta: get(payload, 'meta', initialData.meta),
    }),
    [searchUsersDashboardAction]: (state, { payload }) => ({
      ...state,
      filters: payload || state.filters,
    }),
    [sortUsersDashboardAction]: sortItems,
    [requestPostDeactivateUserAction]: (state, { payload }) => {
      const meta = get(payload, 'meta');
      const requestState = get(payload, 'state');
      const items = cloneDeep(state.data) || [];

      if (requestState === ERROR) {
        return { ...state };
      }

      if (meta?.id) {
        const itemIndexInList = items.findIndex((item) => item?.Id === meta?.id);
        if (itemIndexInList !== -1) {
          items[itemIndexInList].Deactivated = true;
        }
      }

      return {
        ...state,
        data: items,
      };
    },
    [requestPostActivateUserAction]: (state, { payload }) => {
      const meta = get(payload, 'meta');
      const requestState = get(payload, 'state');
      const items = cloneDeep(state.data) || [];

      if (requestState === ERROR) {
        return { ...state };
      }

      if (meta?.id) {
        const itemIndexInList = items.findIndex((item) => item?.Id === meta?.id);
        if (itemIndexInList !== -1) {
          items[itemIndexInList].Deactivated = false;
        }
      }

      return {
        ...state,
        data: items,
      };
    },
    [requestPutUserDashboardAction]: (state, { payload }) => {
      const meta = get(payload, 'meta');
      const requestState = get(payload, 'state');
      const items = cloneDeep(state.data) || [];

      if (requestState === ERROR) {
        return { ...state };
      }

      if (meta?.id) {
        const itemIndexInList = items.findIndex((item) => item?.Id === meta?.id);
        if (itemIndexInList !== -1) {
          items[itemIndexInList] = get(payload, 'data.user');
        }
      }

      return {
        ...state,
        data: items,
      };
    },
    [toggleUserMFADashboardAction]: (state, { payload }) => ({
      ...state,
      data: state.data.map((item) => {
        if (item.Id !== payload) return item;
        return { ...item, IsMfaEnabled: !item.IsMfaEnabled };
      }),
    }),
    [appLogoutAction]: () => initialData,
  },
  initialData,
);

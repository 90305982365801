import styles from './styles.module.scss';
import { RoleSelectView } from './RoleSelectView';
import { AccessLevelView } from './AccessLevelView';

import { AccessLevel } from 'app-constants/enums/permissionSettings';

export const ManageRoleAndAccess = (props: any) => {
  const { Id, permissionUpdatedDetails, TransactionRole, TransactionAccess, setPermissionDetails } =
    props;

  const { FullAccess, LimitedAccess } = AccessLevel;

  const memberDetails = permissionUpdatedDetails[Id];

  const onChangeRoleAccess = (details) => {
    setPermissionDetails(Id, details);
  };

  const componentProps = {
    onChangeRoleAccess,
    TransactionRole,
    ...props,
  };

  const getAccessLevel = () => {
    return (
      memberDetails?.accessLevel ||
      (TransactionRole ? (TransactionAccess ? FullAccess : LimitedAccess) : undefined)
    );
  };

  return (
    <div className={styles.manageRoleAndAccessWrapper}>
      <div className={styles.flexedItem}>
        <label>Default Transaction role:</label>
        <RoleSelectView
          {...componentProps}
          roleId={memberDetails?.roleId || TransactionRole?.Id}
          roleAccess={memberDetails?.accessLevel === FullAccess || TransactionAccess}
        />
      </div>

      <div className={styles.flexedItem}>
        <label>Default Access level:</label>
        <AccessLevelView
          {...componentProps}
          accessLevel={getAccessLevel()}
          accessRoleId={memberDetails?.roleId || TransactionRole?.Id}
        />
      </div>
    </div>
  );
};

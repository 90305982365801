import {
  getAddAvmAdjustmentValue,
  getAddressNeighborhoodActivity,
  getAddressValuationDetails,
  getAddValuationHomeValue,
  getAddValuationRentalValue,
  getCancelAvmAdjustmentValue,
  getEditAvmAdjustmentValue,
  getEditUpdateAddressValue,
} from 'api/intel';
import {
  getAddAvmAdjustmentAction,
  getAddressNeighborhoodActivityAction,
  getAddressValuationDetailsAction,
  getAddValuationValueAction,
  getCancelAvmAdjustmentAction,
  getEditAvmAdjustmentAction,
  getEditNeighborhoodRadiusAction,
  getReminderByIdAction,
} from 'store/actions/intel';
import Api from '../core/api';
import { getReminderById } from 'api/reminder';

export const requestGetAddressValuationDetailsEffect = (
  cfg,
  options?: { silent?: boolean; showError?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddressValuationDetailsAction,
    method: getAddressValuationDetails,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetRemindersClientEffect = (
  cfg,
  options?: { silent?: boolean; showError?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getReminderByIdAction,
    method: getReminderById,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestGetAddressNeighborhoodActivityEffect = (
  cfg,
  options: { silent?: boolean } = {},
  cb?: any,
) => {
  const requestParams = {
    action: getAddressNeighborhoodActivityAction,
    method: getAddressNeighborhoodActivity,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestEditUpdateAddressEffect = (cfg, options?: { silent?: boolean }, cb?: any) => {
  const requestParams = {
    action: getEditNeighborhoodRadiusAction,
    method: getEditUpdateAddressValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAddValuationHomeValueEffect = (
  cfg,
  options?: { silent?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddValuationValueAction,
    method: getAddValuationHomeValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAddValuationRentalValueEffect = (
  cfg,
  options?: { silent?: boolean },
  cb?: any,
) => {
  const requestParams = {
    action: getAddValuationValueAction,
    method: getAddValuationRentalValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestAddAvmAdjustmentEffect = (cfg, options?: { silent?: boolean }, cb?: any) => {
  const requestParams = {
    action: getAddAvmAdjustmentAction,
    method: getAddAvmAdjustmentValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestEditAvmAdjustmentEffect = (cfg, options?: { silent?: boolean }, cb?: any) => {
  const requestParams = {
    action: getEditAvmAdjustmentAction,
    method: getEditAvmAdjustmentValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export const requestCancelAvmAdjustmentEffect = (cfg, options?: { silent?: boolean }, cb?: any) => {
  const requestParams = {
    action: getCancelAvmAdjustmentAction,
    method: getCancelAvmAdjustmentValue,
  };

  let sendRequest = Api.execBase(requestParams);
  if (options?.silent) {
    sendRequest = Api.execResult(requestParams);
  }

  return sendRequest(cfg, options, cb);
};

export enum MemberTypes {
  Admins = 'Admins',
  Members = 'Members',
}

export enum PermissionTypes {
  ViewOnly = 'ViewOnly',
  AutomaticallyAdd = 'AutomaticallyAdd',
}

export enum PermissionTypesLabel {
  ViewOnly = 'View Only',
  AutomaticallyAdd = 'Automatically Add',
}

export enum AccessLevel {
  FullAccess = 'FullAccess',
  LimitedAccess = 'LimitedAccess',
}

export enum TransactionScope {
  All = 'all',
  Future = 'future',
}

export enum AccessLevelLabel {
  FullAccess = 'Full Access',
  LimitedAccess = 'Limited Access',
}

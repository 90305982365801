import { MemberTypes } from 'app-constants/enums/permissionSettings';
import { TEAM_ADMIN, TEAM_BASIC, TEAM_OWNER } from 'settings/constants/roles';

export const getMembersByType = (permissionUsers, type) => {
  const isAdmins = type === MemberTypes.Admins;

  const members = [...permissionUsers];

  return members.filter((item) =>
    isAdmins ? item.Role === TEAM_ADMIN || item.Role === TEAM_OWNER : item.Role === TEAM_BASIC,
  );
};

export const isContinueDisabled = (details) => {
  let isDisabled = false;

  for (let key in details) {
    const item = details[key];

    isDisabled = item.addToTransaction && (!item.accessLevel || !item.roleId);
    if (isDisabled) break;
  }

  return isDisabled;
};

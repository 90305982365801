import { Option, MultiSelect, Select } from 'components-antd';
import { Icons } from 'pages/Workshop/Icons';

import styles from './styles.module.scss';
import { CheckBox } from 'components/Icons';
import classNames from 'classnames';
import { Avatar } from 'components';
import { getPlaceHolder } from './helper';
import { PermissionListItem } from './PermissionListItem';
import { useState } from 'react';

interface FilePermissionsMultiSelectProps {
  placeholder: string;
  entityList: { value: any; label: any }[];
  entity: any;
  setEntity: (value: any) => void;
  showSelectedEntriesBelow?: boolean;
  filePermissionsMultiSelectDropdownClassName?: string;
  multiSelectWrapperClassName?: string;
  propClassName?: string;
  clientsInAccessPermission: { value: any; label: any }[];
}

const customFilterOption = (inputValue: string, option) => {
  const value = option?.props?.children?.[0]?.props?.children?.[1]?.props?.children;
  if (!value) return false;
  return value?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1;
};

export const FilePermissionsMultiSelect = ({
  entity,
  setEntity,
  entityList,
  placeholder,
  propClassName = '',
  clientsInAccessPermission,
  multiSelectWrapperClassName = '',
  filePermissionsMultiSelectDropdownClassName = '',
}: FilePermissionsMultiSelectProps) => {
  const [searchText, setSearchText] = useState('');
  return (
    <>
      <MultiSelect
        size="large"
        variant={'search'}
        onSearch={(value) => setSearchText(value)}
        placeholderText={placeholder}
        onHandleChange={(values) => {
          searchText && setSearchText('');
          setEntity(values);
        }}
        propClass={classNames(
          styles.multiSelect,
          {
            [styles.placeholderForSelected]: entity.length > 0,
          },
          propClassName,
        )}
        optionFilterProp="children"
        filterOption={customFilterOption}
        wrapperClass={classNames(styles.multiSelectWrapper, multiSelectWrapperClassName)}
        values={entity}
        selectIcon={<Icons variant={'search'} />}
        dropdownClassName={classNames(
          styles.multiSelectSectionDropdown,
          filePermissionsMultiSelectDropdownClassName,
        )}
        menuItemSelectedIcon={<CheckBox className={styles.checkBoxIcon} />}
        showClearIcon={true}
        showSearchPlaceholder={false}
        getPopupContainer={(node) => node}
        allowClear={false}
        tagRender={(props) =>
          searchText ? <span /> : <span className={styles.placeholderText}>{placeholder}</span>
        }
        onBlur={() => searchText && setSearchText('')}
      >
        {entityList?.map(({ value, label }, idx) => (
          <Option key={idx} value={value}>
            <span className={styles.optionContainer}>
              <Avatar
                placeholder={getPlaceHolder(label)}
                avatarClassName={styles.optionAvatarCustom}
              />

              <span className={styles.optionLabel}>{label}</span>
            </span>

            {!entity.includes(value) && (
              <CheckBox className={styles.checkBoxIcon} fill="#fff" stroke="#AAABAB" />
            )}
          </Option>
        ))}
      </MultiSelect>

      <div className={styles.clientUsersList}>
        {clientsInAccessPermission.map((item, idx) => (
          <div key={idx}>
            <PermissionListItem item={item} hideCheckbox={true} />
          </div>
        ))}

        {entity?.map((item, idx) => (
          <div key={idx}>
            <PermissionListItem
              item={item}
              entityList={entityList}
              onUncheckItem={() => setEntity(entity?.filter((i) => i !== item))}
            />
          </div>
        ))}
      </div>
    </>
  );
};

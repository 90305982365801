import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { Fragment, useState } from 'react';
import { DocumentsContentModal } from '../../DocumentsContentModal';
import { PermissionsContent } from './PermissionsContent';

export const PermissionsModal = (props) => {
  const { open, ...restProps } = props;
  return (
    <Fragment>
      <DocumentsContentModal open={open} onClose={restProps.onClose} title="Permissions">
        <PermissionsContent {...restProps} />
      </DocumentsContentModal>
    </Fragment>
  );
};

import { Fragment, useRef, useState } from 'react';

import { Icons } from '../../../components';
import { EditTemplateNameModal } from './EditTemplateNameModal';
import { InfoTooltip } from 'pages/FormProcess/DynamicForm/Containers/DynamicEditor/components/InfoTooltip';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FORM_TYPE } from 'app-constants';

export const TemplateName = (props) => {
  const { className, name } = props;

  const hasOverflownName = name && name.length > 50;
  return (
    <Fragment>
      <div className={classNames({ [styles.fileNameWrap]: hasOverflownName }, className)}>
        {hasOverflownName ? (
          <InfoTooltip text={name} className={styles.documentNameTooltip}>
            <p className={styles.documentName}>{name} </p>
          </InfoTooltip>
        ) : (
          <p className={styles.documentName}>{name}</p>
        )}
      </div>
    </Fragment>
  );
};

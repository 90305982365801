import { get } from 'lodash-es';
import { handleActions } from 'redux-actions';
import { IDLE } from 'settings/constants/apiState';
import { getAvmAdjustmentAction } from 'store/actions/radarPortfolio';

const initialState = {
  state: IDLE,
  data: null,
  meta: {},
};

export default handleActions(
  {
    [getAvmAdjustmentAction as any]: (state, { payload }) => ({
      state: get(payload, 'state'),
      data: get(payload, 'data', null),
      meta: get(payload, 'meta'),
    }),
  },
  initialState,
);

import { createAction } from 'redux-actions';

export const getAddressValuationDetailsAction = createAction('GET_ADDRESS_VALUATION_DETAILS');

export const getReminderByIdAction = createAction('GET_REMINDER_BY_ID');

export const getAddressNeighborhoodActivityAction = createAction(
  'GET_ADDRESS_NEIGHBORHOOD_ACTIVITY',
);

export const getAddValuationValueAction = createAction('POST_ADD_VALUATION_VALUE');
export const getAddAvmAdjustmentAction = createAction('POST_ADD_AVM_ADJUSTMENT_VALUE');
export const getEditAvmAdjustmentAction = createAction('POST_EDIT_AVM_ADJUSTMENT_VALUE');
export const getCancelAvmAdjustmentAction = createAction('POST_CANCEL_AVM_ADJUSTMENT_VALUE');

export const getEditNeighborhoodRadiusAction = createAction('EDIT_NEIGHBORHOOD_RADIUS_VALUE');

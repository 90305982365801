import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Drawer, Input, Wrapper as PendingWrapper } from 'components';
import { TagInput, UploadDocuments } from 'components/Transactions';
import {
  editTransactionDocumentEffect,
  getTransactionDocumentsFilterEffect,
  getTransactionDocumentEffect,
} from 'store/effects/transactions';
import { getTransactionDocumentDrawerSelector } from 'store/selectors/drawers/transactionDocument';
import { openTransactionDocumentDrawerEffect } from 'store/effects/drawers/transactionDocument';
import Icon from 'pages/Workshop/Transactions/TransactionTasks/Icons';
import Header from './Header';
import Footer from './Footer';
import ContentWrapper from './ContentWrapper';
import Field from './Field';

import styles from './styles.module.scss';
import { getTransactionSelector } from 'store/selectors/transaction';

const EditTransactionDocument = (props) => {
  const { className } = props;
  const dispatch = useDispatch();
  const { open, params } = useSelector(getTransactionDocumentDrawerSelector);
  const [formFields, setFormFields] = useState({});
  const [accessToEveryone, setAccessToEveryone] = useState(false);
  const [accessToMe, setAccessToMe] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const { transaction } = useSelector(getTransactionSelector);
  const allParticipantsIds = useMemo(
    () => (transaction?.Participants || []).map((participant) => participant?.Id),
    [transaction],
  );

  useEffect(() => {
    if (params?.documentId) {
      const config = { documentId: params?.documentId };
      const options = { silent: true };
      setIsPending(true);
      dispatch(
        getTransactionDocumentEffect(config, options, (err, response) => {
          if (!err) {
            const result = response?.data?.result || {};
            const usersWithAccess = result.AccessToEveryone
              ? allParticipantsIds
              : (result?.UsersWithAccess || []).map((u) => u?.Id);
            setAccessToEveryone(result.AccessToEveryone);
            setFormFields({
              ...result,
              UsersWithAccess: usersWithAccess,
              Category: result.Category?.Name,
            });
          }
          setIsPending(false);
        }),
      );
    }
  }, [dispatch, params?.documentId, allParticipantsIds]);

  const onChangeField = (val, fieldName, clearFieldName) => {
    setFormFields({
      ...formFields,
      [fieldName]: val,
      ...(clearFieldName ? { [clearFieldName]: undefined } : {}),
    });
  };

  const onClose = () => {
    dispatch(openTransactionDocumentDrawerEffect({ open: false }));
  };

  const onSaveHandler = () => {
    setIsPending(true);
    dispatch(
      editTransactionDocumentEffect(accessToEveryone, formFields, {}, (err) => {
        if (!err) {
          onClose();
          dispatch(getTransactionDocumentsFilterEffect());
        }
        setIsPending(false);
      }),
    );
  };

  const onChangeTag = (val) => {
    onChangeField(val, 'Category', 'customTag');
  };

  const onChangeCustomTag = (val) => {
    if (val?.trim()) {
      onChangeField(val, 'customTag', 'Category');
    }
  };

  const onDeleteDocument = () => {
    if (isPending) return null;

    setFormFields({
      ...formFields,
      Meta: undefined,
    });
  };

  const onFileChange = (values) => {
    const uploadedFile = values?.[0];
    setFormFields({
      ...formFields,
      Meta: {
        Filename: uploadedFile?.filename,
        Size: uploadedFile?.size,
        ContentType: uploadedFile?.contentType,
        Data: uploadedFile?.data,
      },
    });
  };

  const onChangeReviewers = (e, val) => {
    onChangeField(val, 'UsersWithAccess');
  };

  const getEveryoneAccess = (val) => {
    setAccessToEveryone(val);
  };

  const getJustMeAccess = (val) => {
    setAccessToMe(val);
  };

  const isCantBeUpdated = () => formFields?.Title && formFields?.Meta;

  return (
    <Drawer
      isOpen={open}
      onClose={onClose}
      className={classNames(styles.editTransactionDrawer, className)}
      header={<Header onClose={onClose} />}
      testid="edit_document_drawer"
    >
      <ContentWrapper>
        <PendingWrapper isPending={isPending} className={styles.pendingWrapper}>
          <Field label="Name">
            <Input
              className={styles.field}
              placeholder="Document Name"
              variant="light"
              value={formFields?.Title}
              onChange={(e, val) => onChangeField(val, 'Title')}
              disabled={isPending}
              testid="name_input"
              inputClassName={styles.inputClassName}
            />
          </Field>
          <Field label="Tag">
            <TagInput
              label=""
              onSelect={onChangeTag}
              onSelectCustomTag={onChangeCustomTag}
              value={{
                Category:
                  formFields?.Category?.name || formFields?.Category?.Name || formFields?.Category,
                customTag: formFields?.customTag,
              }}
              disabled={isPending}
            />
          </Field>

          {!formFields?.Meta?.FormMeta?.auditLogDocumentVaultUUID && (
            <div className={styles.documents}>
              <div className={styles.title}>Documents</div>
              {formFields?.Meta?.Filename ? (
                <div className={styles.documentItem}>
                  <div testid="file_name" className={styles.documentTitle}>
                    {formFields?.Meta?.Filename}
                  </div>
                  <div
                    testid="remove_file"
                    onClick={onDeleteDocument}
                    className={styles.iconDelete}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 16L16 8"
                        stroke="#163C5A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M16 16L8 8"
                        stroke="#163C5A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                <UploadDocuments
                  isOnlyUploading
                  multiple={false}
                  onFileChange={onFileChange}
                  uploadButton={
                    <div testid="upload_new" className={styles.fileNew}>
                      <Icon className={classNames(styles.uploadFileIcon)} variant={Icon.UPLOAD} />
                      <div className={styles.uploadFileText}>Upload New</div>
                    </div>
                  }
                  className={styles.uploadDocuments}
                />
              )}
            </div>
          )}
        </PendingWrapper>
      </ContentWrapper>
      <Footer>
        <Button
          className={styles.submitButton}
          loaderClassName={styles.submitButtonLoader}
          title="Save"
          onClick={onSaveHandler}
          testid="save_button"
          isPending={isPending}
          disabled={!isCantBeUpdated()}
        />
      </Footer>
    </Drawer>
  );
};

EditTransactionDocument.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({}),
};

EditTransactionDocument.defaultProps = {
  className: '',
  value: {},
};

export default EditTransactionDocument;

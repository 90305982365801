import classnames from 'classnames';

import { Button } from 'components';

import styles from './styles.module.scss';
import ResetIcon from 'pages/Settings/SettingsDisplay/ResetIcon';

interface PermissionButtonsFooterProps {
  className?: string;
  disabled?: boolean;
  handleContinue: () => void;
  handleReset: () => void;
  loading: boolean;
}

export const PermissionButtonsFooter = ({
  className,
  handleContinue,
  handleReset,
  disabled,
  loading,
}: PermissionButtonsFooterProps) => (
  <div className={classnames(styles.permissionButtonsFooterContainer, className)}>
    <div className={classnames(styles.buttonsContainer)}>
      <Button title={'Reset'} className={styles.reset} icon={<ResetIcon />} onClick={handleReset} />
      <Button
        disabled={disabled}
        className={classnames(styles.continueBtn, { [styles.disabledBtn]: disabled })}
        onClick={handleContinue}
        title={'Save Changes'}
        isPending={loading}
      />
    </div>
  </div>
);

import { apiServer } from 'settings/web-services/api';

export function getListingDetailById({ id, searchId, searchQuery, activeTab }) {
  return apiServer.get(
    `/listings/${id}${searchId ? `?searchId=${searchId}` : `?searchQuery=${searchQuery}`}${
      activeTab ? `&activeTab=${activeTab}` : ''
    }`,
  );
}

export function getListingDetailByIdV3({ id, ...searchQuery }) {
  return apiServer.post(`/listings/v3/${id}`, searchQuery);
}

export function getWalkscoreByGeolocationAndLocation(cfg) {
  return apiServer.get('/walkscore', { params: cfg });
}

export function getSchoolsAggregateByGeolocation(cfg) {
  return apiServer.get('/property/schools/aggregate', { params: cfg });
}

export function getSchoolDetail(cfg) {
  let _param = { dataSource: cfg?.indicator, addRaw: cfg?.isRaw };
  return apiServer.get(`/property/schools/${cfg?.id}`, { params: _param });
}

// This should live in another folder together with /property/schools
export function getSchoolsDistrictsAutocompleteResults(cfg) {
  return apiServer.get('/property/schools/autocomplete', { params: cfg });
}

export function getExploreDetailsByGeolocationAndCategories(cfg) {
  return apiServer.get('/business', { params: cfg });
}

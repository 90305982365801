import { Popover } from 'components-antd';
import styles from './style.module.scss';
import { useEffect, useState } from 'react';

import { Icons } from '../../../components/Icons';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import { useSelector } from 'react-redux';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { useHistory } from 'react-router-dom';
import { link } from 'settings/navigation/link';
import { getTeamListDataArray } from 'store/selectors/teamList';
import { EditTemplateNameModal } from '../TemplateName/EditTemplateNameModal';

export const EditMenu = ({ onDelete, record, onUpdateNameSuccess }) => {
  const [popupOpen, setPopupOpen] = useState(false);

  const agentRole = useSelector(getAgentTeamRoleSelector);
  const history = useHistory();

  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(agentRole);
  const isOwner = [TEAM_OWNER].includes(agentRole);
  const teamMembers = useSelector(getTeamListDataArray);
  const [teamOwnerId, setTeamOwnerId] = useState();

  const [openEditModal, setOpenEditModal] = useState(false);

  useEffect(() => {
    if (teamMembers) {
      const teamOwnerId = teamMembers.find((member) => member.TeamRole === TEAM_OWNER)?.Id;
      setTeamOwnerId(teamOwnerId);
    }
  }, []);

  const onEditTemplate = () => {
    const data = record;

    history.push({
      pathname: data?.templateId
        ? link.toWorkshopFormTemplateRequest({
            templateId: data?.templateId,
          })
        : link.toWorkshopFormBundleTemplateRequest,
      state: {
        editMode: true,
        templateBundle: !!data?.bundleId,
        bundleId: data?.bundleId,
        isLibraryTemplate: true,
      },
    });
  };

  const onEditClick = () => {
    toggleNameModal();
  };

  const toggleNameModal = () => {
    setOpenEditModal((prev) => !prev);
  };

  const onSuccess = () => {
    setOpenEditModal(false);
    onUpdateNameSuccess();
  };

  const getEditOptions = () => {
    return (
      <div className={styles.editMenu}>
        {[
          ...((isAdminOrOwner && record.publishedBy.Id !== teamOwnerId) || record.isOwner
            ? [
                {
                  label: 'Edit Template',
                  onClick: onEditTemplate,
                  params: [],
                  icon: 'redPen',
                },
                {
                  label: 'Edit Name',
                  onClick: onEditClick,
                  params: [],
                  icon: 'redPen',
                },
              ]
            : []),
          ...(isOwner || record.isOwner
            ? [
                {
                  label: 'Delete',
                  onClick: onDelete,
                  icon: 'redTrash',
                },
              ]
            : []),
        ].map((item, index) => (
          <div
            key={index}
            className={styles.menuLink}
            onClick={(event) => {
              event.stopPropagation();

              (item?.onClick as any)();
              setPopupOpen(false);
            }}
          >
            <Icons variant={item.icon} className={styles.menuIcon} />
            <span className={styles.text}>{item.label}</span>
          </div>
        ))}
      </div>
    );
  };

  if (!((isAdminOrOwner && record.publishedBy.Id !== teamOwnerId) || record.isOwner)) return <></>;

  console.log({ openEditModal });
  return (
    <div className={styles.editMenuWrapper}>
      <div onClick={(e) => e.stopPropagation()}>
        <EditTemplateNameModal
          open={openEditModal}
          onClose={toggleNameModal}
          onSuccess={onSuccess}
          {...record}
        />
      </div>

      <Popover
        content={getEditOptions()}
        getPopupContainer={(triggerNode) => triggerNode}
        trigger="click"
        placement="bottomRight"
        className={styles.popover}
        onOpenChange={(v) => setPopupOpen(v)}
        open={popupOpen}
      >
        <Icons
          onClick={(e) => {
            e.stopPropagation();
            setPopupOpen((prev) => !prev);
          }}
          variant={popupOpen ? 'menuDotsBg' : 'menuDots'}
          className={styles.editIcon}
        />
      </Popover>
    </div>
  );
};

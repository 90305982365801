import { Modal, Title, Checkbox } from 'components-antd';
import classNames from 'classnames';

import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import { TransactionScope } from 'app-constants/enums/permissionSettings';

export const TransactionScopeModal = (props) => {
  const { isOpen, onCancel, onConfirm, memberName } = props;

  const [selectedScope, setSelectedScope] = useState('');

  const onChangeScope = (scope) => setSelectedScope(scope);

  useEffect(() => {
    if (isOpen) {
      setSelectedScope('');
    }
  }, [isOpen]);

  return (
    <Modal
      width={430}
      className={classNames(styles.accessLevelModal, styles.scopeModal)}
      open={isOpen}
      closable={false}
      okText="Confirm"
      onCancel={onCancel}
      onOk={() => onConfirm(selectedScope)}
      okButtonProps={{ disabled: !selectedScope }}
    >
      <Title className={styles.title} level={4}>
        Which transactions do you want to add {memberName || 'Member'} to?
      </Title>

      <div className={styles.checkboxGroup}>
        <div
          className={classNames(styles.checkboxWrapper, {
            [styles.checked]: selectedScope === TransactionScope.All,
          })}
          onClick={() => onChangeScope(TransactionScope.All)}
        >
          <Checkbox checked={selectedScope === TransactionScope.All}>
            All (past, current, future)
          </Checkbox>
        </div>

        <div
          className={classNames(styles.checkboxWrapper, {
            [styles.checked]: selectedScope === TransactionScope.Future,
          })}
          onClick={() => onChangeScope(TransactionScope.Future)}
        >
          <Checkbox checked={selectedScope === TransactionScope.Future}>
            Future transactions only
          </Checkbox>
        </div>
      </div>
    </Modal>
  );
};

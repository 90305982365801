import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import fileDownload from 'js-file-download';

import { Button, Col, Row } from 'components-antd';
import { SmartFormIcons } from '../SmartFormIcons';
import { PDFViewer } from 'pages/FormBuilder/components/PDFViewer';
import {
  AddFormCommentType,
  CommentUsersType,
  EditFormCommentType,
  FormCommentType,
  FormDocumentAnswersType,
  FormDocumentQuestionsType,
  FieldInfoType,
  StrikeThrough,
  FormCommentThread,
  OtherDocumentsType,
  AnonDataType,
  AuthDataType,
} from 'types';
import { FormPill } from 'pages/FormProcess/components';
import { previewDocumentEffect } from 'store/effects/transactions';
import Download from 'components/Messages/MessagesView/components/Download';

import styles from './styles.module.scss';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';
import { getFilesName } from 'pages/FormProcess/DynamicForm/DynamicHeader/helper';
import { AllowedAnonEnum, FORM_TOKEN } from 'app-constants';
import { DownloadButton } from 'pages/FormProcess/DynamicForm/DynamicHeader/components/DownloadButton/DownloadButton';
import { Close } from 'components/Icons';

interface EditFormProps {
  questions?: FormDocumentQuestionsType;
  responses?: FormDocumentAnswersType;
  handleDone: () => void;
  link?: string;
  pdfName?: string;
  clientName?: string;
  address?: string;
  handleExit?: () => void;
  completed?: boolean;
  canceled?: boolean;
  onlyPreview?: boolean;
  handleEdit?: () => void;
  handleUnlock?: () => void;
  commentUsers?: CommentUsersType;
  formComment?: FormCommentType;
  setFieldInfo?: (fieldInfo: FieldInfoType) => void;
  handleGetFormComments?: () => void;
  handleAddFormComment?: (
    payload: AddFormCommentType,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleEditFormComment?: (
    payload: EditFormCommentType,
    setEditingMode: (editingMode: boolean) => void,
    setSending: (isSending: boolean) => void,
  ) => void;
  handleDeleteFormComment?: (formCommentId: number) => void;
  handleToggleResolveThread?: (
    threadId: number,
    setResolving: (resolving: boolean) => void,
  ) => void;
  setCommentPanelOpen?: (commentPanelOpen: boolean) => void;
  strikeThrough?: StrikeThrough;
  webView?: Boolean;
  isCreator?: Boolean;
  formCommentThreads?: FormCommentThread[];
  otherDocuments?: OtherDocumentsType[];
  anonData?: AnonDataType;
  authData?: AuthDataType;
  smartFormPreview?: boolean;
}

export const PreviewForm = ({
  questions,
  responses,
  handleDone,
  link,
  handleExit,
  pdfName,
  clientName,
  address,
  completed,
  canceled,
  onlyPreview,
  handleEdit,
  handleUnlock,
  commentUsers,
  formComment,
  setFieldInfo,
  handleGetFormComments,
  handleAddFormComment,
  handleEditFormComment,
  handleDeleteFormComment,
  handleToggleResolveThread,
  setCommentPanelOpen,
  strikeThrough = {},
  webView,
  isCreator,
  formCommentThreads,
  otherDocuments = [],
  anonData,
  authData,
  smartFormPreview,
}: EditFormProps) => {
  const [showSubmit, setShowSubmit] = useState(true);

  const isMultipleDocument = otherDocuments.length > 1;

  const submit = () => {
    if (handleDone) {
      setShowSubmit(false);
      handleDone();
    }
  };

  const EditRow = () => {
    return (
      <Row justify="center" className={styles.editRow}>
        {handleEdit && !onlyPreview ? (
          <Col>
            <div
              className={classNames(styles.editForm, styles.navigationButton)}
              onClick={handleEdit}
            >
              <SmartFormIcons variant="pencil" />
              Edit
            </div>
          </Col>
        ) : handleUnlock ? (
          <Col>
            <div
              className={classNames(styles.editForm, styles.navigationButton)}
              onClick={handleUnlock}
            >
              <SmartFormIcons variant="lock" />
              Unlock
            </div>
          </Col>
        ) : (
          <></>
        )}
        <FormPill clientAddress={address} clientName={clientName} pdfName={pdfName} />
        <Col>
          <div className={styles.navigationIcons}>
            <div
              className={classNames(styles.closeNavigation, styles.navigationButton)}
              onClick={handleExit}
            >
              <SmartFormIcons variant="cancel" />
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const EditRowPreview = () => {
    return (
      <Row className={styles.editPreviewRow}>
        {handleEdit && !onlyPreview ? (
          <Col>
            <div className={styles.editPreviewForm} onClick={handleEdit}>
              <SmartFormIcons className={styles.icon} variant="pencil" />
              <span className={styles.text}>Edit</span>
            </div>
          </Col>
        ) : handleUnlock ? (
          <Col>
            <div className={styles.editPreviewForm} onClick={handleUnlock}>
              <SmartFormIcons className={styles.icon} variant="lock" />
              <span className={styles.text}>Unlock</span>
            </div>
          </Col>
        ) : (
          <></>
        )}
        <Col>
          <div className={styles.previewHeaderWrap}>
            <span className={styles.previewHeaderTitle}>Preview</span>
            <span className={styles.previewHeaderFile}>{pdfName}</span>
          </div>
        </Col>
        <Col>
          <div className={styles.closeNavigation} onClick={handleExit}>
            <SmartFormIcons variant="cancel" />
          </div>
        </Col>
      </Row>
    );
  };

  const getDocumentUrl = () => {
    if (otherDocuments.length > 1) {
      return otherDocuments.map(({ link }) => link);
    }

    return link;
  };

  const getAnonymousUserDetails = () => {
    const details = dynamicManager.getAnonymousDetails();
    if (details) return details;

    if (anonData) {
      return {
        token: anonData.token,
        type: FORM_TOKEN[anonData.type],
      };
    }

    return {};
  };

  const getForms = () => {
    if (isMultipleDocument) return otherDocuments;

    return [{ link }];
  };

  const prepareAnonymousDetails = () => {
    return (
      dynamicManager.getAnonymousDetails() ||
      (anonData ? { ...anonData, type: FORM_TOKEN[anonData.type] } : undefined)
    );
  };

  return (
    <>
      {completed || canceled ? (
        <>
          <div className={styles.previewTitle}>
            <span className={!completed ? styles.previewTitleLeftAlign : ''}>
              {completed
                ? 'This document is complete and signed by all parties.'
                : 'This document is voided.'}
            </span>

            {completed ? (
              <span className={styles.downloadButton}>
                {smartFormPreview ? (
                  <Download
                    document={{ DocumentVaultUUID: link }}
                    anonymousUserDetails={getAnonymousUserDetails()}
                  />
                ) : (
                  <DownloadButton
                    documents={getForms()}
                    formProcessPublicId={
                      dynamicManager.getFormProcessPublicId() || authData?.formProcessPublicId || ''
                    }
                    anonymousUserDetails={prepareAnonymousDetails()}
                    isCompleted={true}
                  />
                )}

                <span className={styles.closeIcon}>
                  <Close color={'#fff'} onClick={handleExit} />
                </span>
              </span>
            ) : (
              <span className={styles.closeIcon}>
                <Close color={'#fff'} onClick={handleExit} />
              </span>
            )}
          </div>

          <div className={styles.previewFormCompletedContainer}>
            {webView && <EditRow />}

            <div className={styles.pdfContainer}>
              <PDFViewer
                Url={getDocumentUrl()}
                mode="Preview"
                commentUsers={commentUsers}
                formComment={formComment}
                setFieldInfo={setFieldInfo}
                handleGetFormComments={handleGetFormComments}
                handleAddFormComment={handleAddFormComment}
                handleEditFormComment={handleEditFormComment}
                handleDeleteFormComment={handleDeleteFormComment}
                handleToggleResolveThread={handleToggleResolveThread}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${
              isCreator ? styles.previewFormContainerWrap : styles.previewFormContainer
            } ${onlyPreview || !showSubmit ? styles.emptyFooterFormContainer : ''}`}
          >
            {isCreator ? <EditRowPreview /> : <EditRow />}
            <div className={styles.pdfContainer}>
              <PDFViewer
                Url={anonData?.type === AllowedAnonEnum.PREVIEW ? getDocumentUrl() : link}
                questions={questions}
                mode="Preview"
                responses={responses}
                commentUsers={commentUsers}
                formComment={formComment}
                setFieldInfo={setFieldInfo}
                handleGetFormComments={handleGetFormComments}
                handleAddFormComment={handleAddFormComment}
                handleEditFormComment={handleEditFormComment}
                handleDeleteFormComment={handleDeleteFormComment}
                handleToggleResolveThread={handleToggleResolveThread}
                strikeThrough={strikeThrough}
                setCommentPanelOpen={setCommentPanelOpen}
                formCommentThreads={formCommentThreads || []}
              />
            </div>
          </div>

          {!onlyPreview && showSubmit ? (
            <div className={styles.editFormFooter}>
              <Row className={styles.editFormFooterColumn}>
                <Button
                  variant="secondary"
                  className={classNames(styles.editFormFooterContinue, styles.editFormFooterButton)}
                  onClick={submit}
                >
                  I&apos;m done <SmartFormIcons variant="continue" className={styles.svgContinue} />
                </Button>
              </Row>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

import { currentYear } from './date';

/**
 * This transforms our PropertyCache.Market field to a format for presentation and
 * supports custom naming by MLS
 * @param str Market
 * @returns String with presentation name for Market
 */
export function getDisplayMlsNameFromMarket(market) {
  market = market?.toString();
  switch (market) {
    case 'bright-test':
      return 'Bright MLS';
    case 'bright':
      return 'Bright MLS';
    case 'mosdemo':
      return 'Mosaik MLS';
    default:
      return market?.toString()?.toUpperCase();
  }
}

/**
 * This returns custom Mls specific disclaimers
 * @param str Market
 * @returns Disclaimer as a string
 */
export function getMlsDisclaimer(market) {
  market = market.toString();
  market = market.replace('-test', '');
  switch (market) {
    case 'primemls':
      return `Copyright ${currentYear} PrimeMLS, Inc. All rights reserved. This information is deemed reliable, but not guaranteed. The data related to real estate for sale on this website comes in part from the VOW program of PrimeMLS.`;
    case 'mred':
      return `Copyright ${currentYear}. Midwest Real Estate Data LLC. All rights reserved. The data relating to real estate for sale on this web site comes in part from the Broker Reciprocity Program of the Midwest Real Estate Data LLC. Listing information is deemed reliable but not guaranteed. NOTICE: Many homes contain recording devices, and buyers should be aware they may be recorded during a showing.`;
    case 'ppmls':
      return `Copyright ${currentYear}. The real estate listing information and related content displayed on this site is provided exclusively for consumers’ personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. This information and related content is deemed reliable but is not guaranteed accurate by the Pikes Peak REALTOR® Services Corp.`;
    default:
      return `Copyright ${currentYear}. All rights reserved. IDX information is provided exclusively for personal, non-commercial use, and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing. Listing information is aggregated from various brokers who participate in IDX. Information is deemed reliable, but not guaranteed.`;
  }
}

/**
 * This returns custom Mls specific logos
 * @param str Market
 * @returns Disclaimer as a string
 */
export function getMlsImage(market) {
  market = market.toString();
  market = market.replace('-test', '');
  switch (market) {
    case 'primemls':
      return {
        mlsLogoWidth: '150px',
        mlsLogoURL: 'https://mosaik-assets.s3.amazonaws.com/images/mlslogo-primemls.png',
      };
    case 'ppmls':
      return {
        mlsLogoWidth: '150px',
        mlsLogoURL: 'https://mosaik-assets.s3.amazonaws.com/images/mlslogo-ppmls.png',
      };
    default:
      return {};
  }
}

/**
 * This returns custom MlsId by removing characters or not depending on market name
 * @param str Market
 * @param str ListingId
 * @returns MlsId as a string
 */
export function getMlsIdToDisplay(market, listingId) {
  market = market.toString();
  switch (market) {
    case 'mred':
    case 'actris':
    case 'neirbr':
    case 'neirbr-test':
    case 'actris-test':
      return listingId?.slice(3);
    default:
      return listingId;
  }
}

/**
 * Get the support email for a given market
 * @param {string} market - The market for which to get the support email
 * @returns {string} - A string with the support email
 */
export function getSupportEmail(market) {
  market = market.toString();
  switch (market) {
    default:
      return 'support@mosaik.io';
  }
}
/**
 * This returns custom Mls specific phoneNumber
 * @param str Market
 * @returns Disclaimer as a string
 */
export function getAgentPhoneNumber(market, phoneNumber) {
  market = market.toString();
  market = market.replace('-test', '');
  switch (market) {
    case 'cdar':
    case 'spartanburg':
      return null;
    default:
      return phoneNumber;
  }
}

/**
 * This returns HomeType Labels
 * @param str HomeType
 * @returns HomeType Label
 */
export function getHomeTypeLabel(homeType) {
  const labels = {
    Other: 'Other',
    Single: 'Single Family Home',
    Townhouse: 'Townhouse',
    Condo: 'Condominium',
    MultiFamily: 'Multi-Family',
    NewConstruction: 'New Construction',
    Land: 'Land',
    Commercial: 'Commercial',
    CommercialLease: 'Commercial Lease',
    ResidentialLease: 'Residential Lease',
    RL_Other: 'Other',
    RL_Single: 'Single Family Home',
    RL_Townhouse: 'Townhouse',
    RL_Apartment: 'Apartment',
    RL_Condo: 'Condominium',
    RL_MultiFamily: 'Multi-Family',
    CommercialLand: 'Commercial Land',
    CommercialLeaseLand: 'Commercial Lease - Land',
  };

  const label = labels[homeType];
  if (label) {
    return label;
  } else {
    return getHomeTypeLabel;
  }
}

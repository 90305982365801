import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RadarHeader } from '../components/RadarHeader';
import { AntCard, PageWrapper } from 'components-antd';
import ListingDetailMap from 'pages/Properties/ListingDetail/components/ListingDetailMap';
import { map } from 'lodash-es';
import { Icons } from './Icons';
import { HistoryCard } from './components/HistoryCard';
import { NeighborhoodActivityCard } from './components/NeighborhoodActivityCard';
import {
  requestPropertyDetailsEffect,
  requestPropertyNeighborhoodActivitiesEffect,
} from 'store/effects/radarPortfolio';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getPropertyReferenceDetailsSelector,
  getPropertyReferenceNeighborhoodActivitiesSelector,
} from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import { Wrapper } from 'components';
import { formatNumber } from 'helpers';
import moment from 'moment';
import { ValuationCard } from 'pages/Intel/components/PropertyTab/components';
import { getValuationDataSelector } from 'store/selectors/intel';
import { requestGetAddressValuationDetailsEffect } from 'store/effects/intel';
import { NeighborhoodFiltersChangeHandler, NeighborhoodFilterTypes } from '../types';
import { RadiusForm } from './components/RadiusForm';
import classNames from 'classnames';
import { routes } from 'settings/navigation/routes';
import { updatePropertyAddressMetaAction } from 'store/actions/radarPortfolio';
import { ClosingDateForm } from './components/ClosingDateForm';
import { NotesForm } from './components/NotesForm';
import { Categories } from 'pages/Intel/components/NeighborhoodTab/components/Filter/types';

export const getCategoryAndStatusFromFilter = (nextFilter: NeighborhoodFilterTypes) => {
  let category: string[] | undefined = undefined;
  const categories =
    nextFilter === NeighborhoodFilterTypes.All
      ? Object.values(Categories).reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {} as Record<string, boolean>)
      : {
          [nextFilter]: true,
        };

  if (categories[Categories.NEW_LISTING]) {
    category = [...(category ?? []), 'New'];
  }
  if (categories[Categories.PRICE_REDUCTION]) {
    category = [...(category ?? []), 'Price Reductions'];
  }
  if (categories[Categories.PRICE_INCREASE]) {
    category = [...(category ?? []), 'Price Increase'];
  }
  if (categories[Categories.BACK_ON_THE_MARKET]) {
    category = [...(category ?? []), 'Back on Market'];
  }
  if (categories[Categories.WITHDRAWN]) {
    category = [...(category ?? []), Categories.WITHDRAWN];
  }
  if (categories[Categories.UNDER_CONTRACT]) {
    category = [...(category ?? []), Categories.UNDER_CONTRACT];
  }
  if (categories[Categories.EXPIRED]) {
    category = [...(category ?? []), Categories.EXPIRED];
  }
  if (categories[Categories.SOLD]) {
    category = [...(category ?? []), Categories.SOLD];
  }

  return {
    category,
  };
};

export const RadarProperty: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const {
    address1: extractedAddress1,
    address2: extractedAddress2,
    addressId: extractedStateAddressId,
    clientId: extractedClientId,
    clientName: extractedClientName,
  } = (location.state || {}) as any;
  const [address1] = useState(extractedAddress1);
  const [address2] = useState(extractedAddress2);
  const [stateAddressId] = useState(extractedStateAddressId);
  const [clientId] = useState(extractedClientId);
  const [clientName] = useState(extractedClientName);
  const params = { address1, address2, addressId: stateAddressId, clientId };
  const { data, state } = useSelector(getPropertyReferenceDetailsSelector);
  const { data: neighborhoodActivitiesData, state: neighborhoodActivitiesState } = useSelector(
    getPropertyReferenceNeighborhoodActivitiesSelector,
  );
  const isPendingDetails = state === PENDING;
  const isPendingNeighborhood = neighborhoodActivitiesState === PENDING;
  const parsedData = data?.parsedData?.[0];
  const neighbourhoodRadius = data?.address?.neighbourhoodRadius ?? '';
  const addressId = data?.address?.Id;
  const notes = data?.address?.notes ?? '';
  const type = data?.address?.propertyType?.length ? data?.address?.propertyType?.join(', ') : '';
  const closingDate = data?.address?.closingDate
    ? moment(data?.address?.closingDate).format('M/D/YY')
    : '';
  const createdDate = data?.address?.CreatedDate
    ? moment(data?.address?.CreatedDate).format('M/D/YY')
    : '';
  const tax = data?.assessmentHistory;
  const sale = data?.saleHistory;
  const metadata = data?.address?.address?.metadata;
  params.addressId = params.addressId || addressId;
  const { data: valuationData, isPending: isPendingValuationData } =
    useSelector(getValuationDataSelector);
  const [editRadius, setEditRadius] = useState(false);
  const [editClosingDate, setEditClosingDate] = useState(false);
  const [editNotes, setEditNotes] = useState(false);

  useEffect(() => {
    if (!location.state) history.push(routes.radarPortfolio);
    else {
      dispatch(requestPropertyDetailsEffect(params));
      dispatch(requestGetAddressValuationDetailsEffect(params, { showError: false }));
    }
  }, []);

  useEffect(() => {
    if (isPendingDetails || !metadata || !location.state) {
      return;
    }

    dispatch(
      requestPropertyNeighborhoodActivitiesEffect({
        dayRange: 1,
        address: {
          lat: metadata.latitude,
          long: metadata.longitude,
          radius: neighbourhoodRadius ?? 10,
        },
        ...getCategoryAndStatusFromFilter(NeighborhoodFilterTypes.All),
      }),
    );
  }, [isPendingDetails, metadata, neighbourhoodRadius]);

  const mapOptions = {
    markers: {
      [`${metadata?.longitude} ${metadata?.latitude}`]: [
        {
          id: data?.address?._id,
          geometry: {
            coordinates: {
              lng: metadata?.longitude,
              lat: metadata?.latitude,
            },
          },
        },
      ],
    },
    zoom: 14,
    center: {
      lng: metadata?.longitude,
      lat: metadata?.latitude,
    },
  };

  const handleNeighborhoodActivitiesFiltersChange: NeighborhoodFiltersChangeHandler = ({
    category,
    activityStatus,
    dayRange,
  }) => {
    if (!metadata) {
      return;
    }

    dispatch(
      requestPropertyNeighborhoodActivitiesEffect({
        category,
        activityStatus,
        dayRange,
        address: {
          lat: metadata.latitude,
          long: metadata.longitude,
          radius: neighbourhoodRadius ?? 10,
        },
      }),
    );
  };

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.pageWrapper}>
      <RadarHeader>
        <div className={styles.titleInputContainer}>
          <div className={styles.title}>{address1}</div>
          <div
            className={styles.subHeading}
            onClick={() => {
              history.push(`/client-profile/${clientId}`);
            }}
          >
            <p>
              Client: <span>{clientName}</span>
            </p>
            {/* <TooltipIcon size={'16px'} color={'#747475'} className={styles.tooltipIcon} /> */}
          </div>
        </div>
      </RadarHeader>
      <div className={styles.pageContent}>
        <Wrapper isPending={isPendingDetails} className={styles.container}>
          <div className={styles.detailsRow}>
            <AntCard className={styles.detailsCard}>
              <p className={styles.heading}>Details</p>
              <ListingDetailMap options={mapOptions} />
              {map(
                [
                  {
                    icon: Icons.CLOSED,
                    label: 'Closed',
                    value: closingDate,
                    edit: true,
                    setter: setEditClosingDate,
                  },
                  { icon: Icons.CLOCK, label: 'Added to Mosaik', value: createdDate },
                  { icon: Icons.BEDS, label: 'Beds', value: parsedData?.beds ?? '' },
                  { icon: Icons.BATHS, label: 'Baths', value: parsedData?.baths ?? '' },
                  {
                    icon: Icons.SIZE,
                    label: 'Size',
                    value: parsedData?.size ? formatNumber(parsedData?.size) + ' sq. ft.' : '',
                  },
                  { icon: Icons.TYPE, label: 'Type', value: type ?? '' },
                  {
                    icon: Icons.LOT_SIZE,
                    label: 'Lot Size',
                    value: parsedData?.lotSize
                      ? formatNumber(parsedData?.lotSize) + ' sq. ft.'
                      : '',
                  },
                  {
                    icon: Icons.NEIGHBORHOOD,
                    label: 'Neighborhood Activity Radius',
                    value: neighbourhoodRadius,
                    edit: true,
                    setter: setEditRadius,
                  },
                  {
                    icon: Icons.NOTES,
                    label: 'Notes:',
                    value: notes,
                    directionColumn: true,
                    edit: true,
                    setter: setEditNotes,
                  },
                ],
                ({ label, value, icon, edit = false, directionColumn = false, setter = null }) => (
                  <>
                    {edit && editNotes && label === 'Notes:' ? (
                      <NotesForm
                        value={value}
                        handleClose={() => {
                          setter && setter(false);
                        }}
                        addressId={Number(addressId)}
                        onUpdate={(notes) => {
                          if (notes) {
                            dispatch(
                              updatePropertyAddressMetaAction({
                                notes,
                              }),
                            );
                          }
                        }}
                      />
                    ) : null}
                    {edit && editRadius && label === 'Neighborhood Activity Radius' ? (
                      <RadiusForm
                        value={Number(value)}
                        handleClose={() => {
                          setter && setter(false);
                        }}
                        addressId={Number(addressId)}
                        onUpdate={(radius) => {
                          if (!isNaN(Number(radius))) {
                            dispatch(
                              updatePropertyAddressMetaAction({
                                neighbourhoodRadius: Number(radius),
                              }),
                            );
                          }
                        }}
                      />
                    ) : null}
                    {edit && editClosingDate && label === 'Closed' ? (
                      <ClosingDateForm
                        value={data?.address?.closingDate}
                        handleClose={() => {
                          setter && setter(false);
                        }}
                        addressId={Number(addressId)}
                        onUpdate={(closingDate) => {
                          if (closingDate) {
                            dispatch(updatePropertyAddressMetaAction({ closingDate }));
                          }
                        }}
                      />
                    ) : null}
                    {!(edit && (editClosingDate || editRadius || editNotes)) && (
                      <div
                        className={classNames(styles.tableRow, {
                          [styles.column]: directionColumn,
                        })}
                      >
                        <div className={styles.iconRow}>
                          <Icons variant={icon} />
                          <p className={styles.label}>{label}</p>
                        </div>
                        <div
                          className={classNames(styles.valueContainer, {
                            [styles.column]: directionColumn,
                          })}
                        >
                          <p
                            className={classNames(styles.value, {
                              [styles.column]: directionColumn,
                            })}
                          >
                            {value}
                          </p>
                          {edit && (
                            <span
                              className={classNames(styles.editIcon, {
                                [styles.notes]: label === 'Notes:',
                              })}
                              onClick={() => {
                                setter && setter(true);
                              }}
                            >
                              <Icons variant={Icons.EDIT} />
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ),
              )}
            </AntCard>
          </div>
          <div className={styles.cardsRow}>
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={false}
            />
            <ValuationCard
              valuationData={valuationData ?? []}
              isPending={isPendingValuationData}
              params={params}
              rental={true}
            />
            <NeighborhoodActivityCard
              neighborhoodActivitiesData={neighborhoodActivitiesData}
              isPending={isPendingNeighborhood}
              onFiltersChange={handleNeighborhoodActivitiesFiltersChange}
              mapOptions={mapOptions}
            />
            {(tax ?? []).length || (sale ?? [])?.length ? (
              <HistoryCard tax={tax ?? []} sale={sale ?? []} />
            ) : null}
          </div>
        </Wrapper>
      </div>
    </PageWrapper>
  );
};

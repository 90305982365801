import { useState } from 'react';
import classnames from 'classnames';
import AutoComplete from 'antd/lib/auto-complete';

import classNames from 'classnames';

import { incorrectEmail } from 'app-constants';
import { AgentsType, ClientsType } from 'types';
import { caseInsensitiveFilter } from 'helpers';
import { Modal, ModalProps, Row, Col, Button, OptionType } from 'components-antd';

import styles from './styles.module.scss';
import { isValidEmail, validationToast } from 'pages/FormProcess/helper';

interface ReplaceEntityModalProps extends ModalProps {
  url?: string;
  replaceEntityData: {
    open: boolean;
    isEditor?: boolean;
    entityId?: number;
    name?: string;
    email?: string;
    role?: string;
    options?: ClientsType & AgentsType;
  };
  onCloseModal: () => void;
  handleReplaceEntity: ({
    url,
    isEditor,
    oldId,
    userId,
    email,
    setLoading,
  }: {
    url: string;
    isEditor?: boolean;
    oldId?: number;
    userId?: number;
    email?: string;
    setLoading: (loading: boolean) => void;
    onCloseModal: () => void;
  }) => void;
  dynamicForm?: boolean;
}

export const ReplaceEntityModal = ({
  url,
  replaceEntityData,
  onCloseModal,
  handleReplaceEntity,
  dynamicForm,
}: ReplaceEntityModalProps) => {
  const { open, isEditor, entityId, name, email, role, options } = replaceEntityData;

  const [entity, setEntity] = useState<{ id?: number; name?: string; email?: string }>({});
  const [loading, setLoading] = useState(false);

  const getEntityOptions = (): OptionType[] =>
    options?.map((entity) => ({
      key: entity.Id,
      label: `${entity.FirstName} ${entity.LastName}`,
      value: `${entity.FirstName} ${entity.LastName}`,
    })) || [];

  const handleOnBlur = () => {
    if (entity?.email && !isValidEmail(entity.email)) {
      setEntity({});
      validationToast(incorrectEmail, { top: 0 });
    }
  };

  return (
    <>
      <Modal
        afterClose={() => setEntity({})}
        open={open}
        width={675}
        footer={null}
        title={`Replace ${isEditor ? 'Editor' : 'Signatory'}`}
        onCancel={onCloseModal}
        className={styles.replaceEntityModal}
        maskClosable={false}
        destroyOnClose={true}
        closable={!loading}
      >
        <div>
          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24}>
              <p className={styles.heading}>{`Current ${isEditor ? 'Editor' : 'Signatory'}`}</p>
            </Col>
          </Row>
          <Row className={styles.formRow}>
            <Col
              xs={5}
              sm={5}
              md={5}
              xl={5}
              lg={5}
              className={classNames({ [styles.borderBottom]: !dynamicForm })}
            >
              {!dynamicForm && <p className={styles.content}>{role}</p>}
            </Col>
            <Col xs={1} sm={1} md={1} xl={1} lg={1}></Col>
            <Col xs={15} sm={15} md={15} xl={15} lg={15} className={styles.borderBottom}>
              <p className={styles.content}>{name ? name : email}</p>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24}>
              <p className={styles.heading}>{`New ${isEditor ? 'Editor' : 'Signatory'}`}</p>
            </Col>
          </Row>

          <Row className={styles.formRow}>
            <Col
              xs={5}
              sm={5}
              md={5}
              xl={5}
              lg={5}
              className={classNames({ [styles.borderBottom]: !dynamicForm })}
            >
              {!dynamicForm && <p className={styles.content}>{entity?.name}</p>}
            </Col>

            <Col xs={1} sm={1} md={1} xl={1} lg={1}></Col>

            <Col xs={15} sm={15} md={15} xl={15} lg={15}>
              <AutoComplete
                value={entity.id ? entity.name : entity.email}
                onChange={(email) => setEntity({ email })}
                onSelect={(value, option) => setEntity({ name: value, id: option.key })}
                onBlur={() => (entity.id ? undefined : handleOnBlur())}
                disabled={loading}
                placeholder="Enter name or email address..."
                bordered={false}
                className={classnames(
                  { [styles.disabledInput]: loading },
                  styles.autoCompleteInput,
                )}
                options={getEntityOptions()}
                filterOption={(inputValue, option) =>
                  caseInsensitiveFilter(inputValue, option?.label as string)
                }
              />
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} xl={24} lg={24} className={styles.updateBtnDiv}>
              <Button
                disabled={!(entity.id || entity.email)}
                loading={loading}
                className={classnames(
                  { [styles.disabledBtn]: !(entity.id || entity.email) },
                  styles.updateBtn,
                )}
                variant="secondary"
                onClick={() =>
                  url &&
                  handleReplaceEntity({
                    url,
                    isEditor,
                    oldId: entityId,
                    userId: entity.id,
                    email: entity.email,
                    setLoading,
                    onCloseModal,
                  })
                }
              >
                Update
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

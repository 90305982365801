import { Table } from 'pages/Radar/components';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import {
  requestPropertyReferenceDataEffect,
  updateClientPropertyEffect,
} from 'store/effects/radarPortfolio';
import { getPropertyReferenceDataSelector } from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import { Icons } from 'pages/Radar/Icons';
import NumberFormat from 'react-number-format';
import { Popover } from 'components-antd';
import ActionsIcon from 'pages/Properties/Search/components/Icons/ActionsIcon';
import moment from 'moment';
import { Avatar, Button, Wrapper } from 'components';
import { getInitials } from 'utils';
import { Archive, Plus } from 'components/Icons';
import classNames from 'classnames';
import { AddressModal } from './components/AddressModal';
import { useHistory } from 'react-router-dom';
import { requestGetCampaignsEffect } from 'store/effects/radarKits';

export const Portfolio = ({ clientId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showArchived, setShowArchived] = useState(false);
  const [openAddressModal, setOpenAddressModal] = useState(false);

  const { data, state } = useSelector(getPropertyReferenceDataSelector);
  const isLoading = state === PENDING;

  const addresses =
    data?.flatMap(({ Client }) => (Client.Id === clientId ? Client?.Address : [])) ?? [];

  const properties =
    data?.flatMap(({ FirstName, LastName, Client, AvatarUrl }) =>
      Client.Id === clientId
        ? Client?.Address?.map(
            ({ Id, closingDate, neighbourhoodRadius, parsedData, ...addressObj }) => {
              const { address1, address2, homeValue, rentalValue } = parsedData || addressObj || {};
              return {
                id: Id,
                property: { line1: address1, line2: address2 },
                client: {
                  name: `${FirstName} ${LastName}`,
                  image: AvatarUrl,
                },
                value: homeValue || '-',
                rentalValue: rentalValue || '-',
                activity: neighbourhoodRadius || '-',
                closed: closingDate ? moment(closingDate).format('MM/DD/YYYY') : '-',
              };
            },
          ) || []
        : [],
    ) ?? [];

  const archiveUpdate = useCallback(
    async (addressId, isArchive) => {
      await dispatch(
        updateClientPropertyEffect({ addressId, clientId, isArchive }, {}, (err, data) => {
          if (!err) {
            dispatch(
              requestPropertyReferenceDataEffect({
                Archive: !isArchive,
              }),
            );
            dispatch(requestGetCampaignsEffect({}, { silent: true }));
          }
        }),
      );
    },
    [clientId],
  );

  useEffect(() => {
    dispatch(
      requestPropertyReferenceDataEffect({
        Archive: showArchived,
      }),
    );
  }, [showArchived]);

  const columns: any = [
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      render: (property: any) => (
        <div className={styles.propertyContainer}>
          <div className={styles.iconContainer}>
            <Icons variant={Icons.CONDO} />
          </div>
          <div className={styles.textContainer}>
            <p className={styles.line1}>{property?.line1}</p>
            <p className={styles.line2}>{property?.line2?.split('-')?.[0]}</p>
          </div>
        </div>
      ),
    },
    [
      {
        title: 'Client',
        dataIndex: 'client',
        key: 'client',
        render: (client: any) => (
          <div className={styles.client}>
            <Avatar
              avatarClassName={styles.avatarSmall}
              src={client?.image}
              placeholder={getInitials(client?.name)}
            />
            <p className={styles.text}>{client?.name}</p>
          </div>
        ),
      },
    ],
    {
      title: 'Est. Value',
      dataIndex: 'value',
      key: 'value',
      render: (val: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={val}
          prefix="$"
          renderText={(val) => <p className={styles.value}>{val}</p>}
        />
      ),
    },
    {
      title: 'Est. Rental Value',
      dataIndex: 'rentalValue',
      key: 'rentalValue',
      render: (rentalValue: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={rentalValue}
          prefix="$"
          renderText={(val) => <p className={styles.text}>{val}</p>}
        />
      ),
    },
    {
      title: 'Neighborhood Radius',
      dataIndex: 'activity',
      key: 'activity',
      render: (activity: number) => <p className={styles.text}>{activity}</p>,
    },
    {
      title: 'Closed',
      dataIndex: 'closed',
      key: 'closed',
      render: (closed: string) => <p className={styles.text}>{closed}</p>,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id: any) => (
        <p className={styles.value}>
          <div className={styles.actionBtn}>
            <Popover
              overlayClassName={styles.popoverOverlay}
              placement="bottomRight"
              trigger="hover"
              content={
                <div className={styles.actionList}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      archiveUpdate(id, !showArchived);
                    }}
                    className={styles.actionListBtn}
                  >
                    <Archive
                      className={styles.icon}
                      color="#FF576D"
                      size={'24'}
                      strokeWidth={'2'}
                    />
                    <span>{showArchived ? 'Unarchive' : 'Archive'}</span>
                  </div>
                </div>
              }
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ActionsIcon className={styles.actionBtnIcon} color={'#252d44'} />
              </div>
            </Popover>
          </div>
        </p>
      ),
    },
  ];

  const handleRowClick = useCallback(
    ({ id, property, client }) => {
      history.push('/radar/property', {
        address1: property?.line1,
        address2: property?.line2,
        addressId: id,
        clientId: clientId,
        clientName: client?.name,
      });
    },
    [dispatch],
  );

  return (
    <Wrapper isPending={isLoading} className={styles.main}>
      <div className={styles.portfolioContent}>
        <div className={styles.header}>
          <p className={styles.heading}>Properties</p>
          <div className={styles.buttons}>
            {!showArchived && (
              <Button
                title="Add"
                icon={<Plus color="#fff" />}
                className={styles.add}
                onClick={() => setOpenAddressModal(true)}
              />
            )}
            <Button
              icon={
                <Archive color={showArchived ? '#fff' : '#252d44'} size={'24'} strokeWidth={'2'} />
              }
              className={classNames(styles.archive, { [styles.active]: showArchived })}
              onClick={() => setShowArchived((prev) => !prev)}
            />
          </div>
        </div>
        <AddressModal
          isOpen={openAddressModal}
          onClose={() => setOpenAddressModal(false)}
          addresses={addresses}
          clientId={clientId}
        />
        <div className={styles.table}>
          <Table
            data={properties}
            columns={columns}
            count={properties?.length}
            handleRowClick={handleRowClick}
            disableHeader={true}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Wrapper>
  );
};

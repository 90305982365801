import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { requestGetUserParticipantsListEffect } from 'store/effects/drawers/addParticipants';
import {
  getLoadingParticipantsList,
  getParticipantsFormattedList,
} from 'store/selectors/drawers/addParticipants';
import { Wrapper as PendingWrapper, Autocomplete } from 'components';

import Icon from './Icons';
import FlatValues from './FlatValues';
import BlockValues from './BlockValues';

import styles from './styles.module.scss';
import { getUserRolesSelector } from 'store/selectors/user';
import { AGENT, CLIENT, THIRD_PARTY } from 'settings/constants/roles';
import { useOutsideClick } from 'hooks';
import { getIsCommentsDrawerOpenSelector } from 'store/selectors/drawers/comments';

const Participants = (props) => {
  const {
    className,
    value,
    onChange,
    variant,
    valuesClassName,
    blockValueClassName,
    prefixClassName,
    wrapperClassName,
  } = props;
  const { inputHolderClassName } = props;
  const activeClients = useSelector(getParticipantsFormattedList);
  const { isIdle, isPending, isData } = useSelector(getLoadingParticipantsList);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState(value);
  const loggedinUserRoles = useSelector(getUserRolesSelector);
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const isCommentsDrawerOpen = useSelector(getIsCommentsDrawerOpenSelector);

  useEffect(() => setSelectedParticipants(value), [value]);

  useOutsideClick([inputRef], () => {
    setIsFocused(false);
  });

  useEffect(() => {
    if (isIdle || !isData) {
      dispatch(
        requestGetUserParticipantsListEffect({ includeCollaborators: !isCommentsDrawerOpen }),
      );
    } else {
      dispatch(
        requestGetUserParticipantsListEffect(
          { includeCollaborators: !isCommentsDrawerOpen },
          { silent: true },
        ),
      );
    }
  }, []); // eslint-disable-line
  useEffect(() => {
    if (
      Array.isArray(activeClients) &&
      activeClients.length == 1 &&
      loggedinUserRoles.includes(CLIENT)
    ) {
      const participant = [{ ...activeClients[0], value: activeClients[0].id }];
      setSelectedParticipants(participant);
      onChange(participant);
    }
  }, [activeClients]);
  const getOptions = () => {
    if (!activeClients) return [];
    const roleMapping = {
      [AGENT]: 'Agent',
      [CLIENT]: 'Client',
      [THIRD_PARTY]: 'Third Party',
    };

    return activeClients.map((client) => ({
      id: client.id,
      name: client.name,
      value: client.id,
      role: roleMapping[client.role] || client.role,
      avatarUrl: client.avatarUrl,
      firstName: client.firstName,
      lastName: client.lastName,
    }));
  };

  const onSelectHandler = (event, val) => {
    setInputValue([]);
    const isSelectedParticipant = selectedParticipants.find((selected) => selected.id === val.id);

    if (!isSelectedParticipant) {
      const newParticipants = [...(selectedParticipants || []), val];
      setSelectedParticipants(newParticipants);
      onChange(newParticipants);
    }
  };

  const onDeleteParticipant = (id) => {
    const newParticipants = selectedParticipants.filter((participant) => participant.id !== id);
    setSelectedParticipants(newParticipants);
    onChange(newParticipants);
  };

  const renderOption = (optionData) => (
    <span>
      <span>{optionData.name}</span>
      <span className={styles.role}>{`(${optionData.role})`}</span>
    </span>
  );

  const getValues = () => {
    if (variant === Participants.BLOCK) {
      return (
        <BlockValues
          participants={selectedParticipants}
          onDelete={onDeleteParticipant}
          className={valuesClassName}
          valueClassName={blockValueClassName}
          testid="participants"
        />
      );
    }

    return (
      <FlatValues
        participants={selectedParticipants}
        onDelete={onDeleteParticipant}
        className={valuesClassName}
        testid="participants"
      />
    );
  };

  const setFocus = () => setIsFocused(true);

  return (
    <div
      testid="participants_block"
      className={classNames(styles.inputWrapper, className)}
      ref={inputRef}
      onClick={setFocus}
    >
      <div className={classNames(styles.inputHolder, inputHolderClassName)}>
        <div className={classNames(styles.prefix, prefixClassName)}>To:</div>
        {isFocused || !selectedParticipants?.length ? (
          <Autocomplete
            testid="to_input_select"
            search
            className={{ wrapper: styles.search, value: styles.value }}
            variant={Autocomplete.LIGHT_FULL}
            options={getOptions()}
            placeholder="Search name"
            isEmptyValue
            onSelect={onSelectHandler}
            value={inputValue}
            renderOption={renderOption}
          />
        ) : (
          <div className={classNames(styles.valuesWrapper, wrapperClassName)}>{getValues()}</div>
        )}
        <div className={styles.postfix}>
          <PendingWrapper loaderClassName={styles.loader} isPending={isPending}>
            <Icon className={styles.icon} variant={Icon.ADD_PARTICIPANT} />
          </PendingWrapper>
        </div>
      </div>
    </div>
  );
};

Participants.FLAT = 'flat';
Participants.BLOCK = 'block';

Participants.propTypes = {
  className: PropTypes.string,
  blockValueClassName: PropTypes.string,
  inputHolderClassName: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
  variant: PropTypes.string,
  valuesClassName: PropTypes.string,
  prefixClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Participants.defaultProps = {
  className: '',
  blockValueClassName: '',
  inputHolderClassName: '',
  value: [],
  onChange: () => {},
  variant: Participants.FLAT,
  valuesClassName: '',
  prefixClassName: '',
  wrapperClassName: '',
};

export default Participants;

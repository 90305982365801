import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { isEqual } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GoogleMap, MarkerClusterer, InfoWindow } from '@react-google-maps/api';

import ZoomControls from './ZoomControls';
import FullScreenControl from './FullScreenControl';
import Markers from './Markers';
import {
  getClusterStyleIndex,
  getClusterText,
  getMarkersBounds,
  getZoomForBounds,
} from './helpers';
import MarkerActive from 'components/Map/images/marker-active.svg';
import { getSearchIsMapDrawingModeSelector } from 'store/selectors/search';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getFeedCriteriaSelectorV3 } from 'store/selectors/feedv3';
import { LocationType } from 'types';
import Center from './Center';
import InfoProperties from './Markers/InfoProperties';

const Map = (props) => {
  const {
    id,
    containerStyle,
    center,
    bounds,
    shouldFitBound,
    onBoundsChangedHandlerFn,
    listingCountTextForMap,
    isMapEnhancementEnabled,
    selectedLocations,
    showPlaces = false,
    groupLabel,
    noFitBounds = false,
    disableDraw,
  } = props;
  const {
    mapContainerClassName,
    zoom,
    oneDotZoom,
    markersMap,
    controlsClassName,
    popupClassName,
    controls: propControls,
  } = props;
  const { onClickMarker, getPropertyLink } = props;
  const [isUserTriggeredBoundsChange, setIsUserTriggeredBoundsChange] = useState(false);
  const [map, setMap] = useState();
  const [infoWindowData, setInfoWindowData] = useState(null);
  const [previousZoom, setPreviousZoom] = useState();
  const isDrawingMode = useSelector(getSearchIsMapDrawingModeSelector);
  const { criteria } = useSelector(getFeedCriteriaSelectorV3);
  const polygonGeoJSON = criteria?.Locations?.find(
    ({ Type }) => Type === LocationType.Polygon,
  )?.Polygon;
  const [featureLayer, setFeatureLayer] = useState();

  const boundChangeRef = useRef({
    boundChangeCount: 0,
    boundsPolygon: [],
    boundsZoomLevel: 0,
  });

  const USACountryBounds = {
    north: 49.3457868,
    south: 24.396308,
    west: -125.0,
    east: -66.93457,
  };
  const mapOptions = useMemo(
    () => ({
      ...(showPlaces ? {} : { mapId: 'e4302f028d5de967' }),
      disableDefaultUI: true,
      minZoom: 4,
    }),
    [],
  );
  const controls = useMemo(
    () => ({
      ...Map.defaultProps?.controls,
      ...(propControls || {}),
    }),
    [propControls],
  );
  const markers = useMemo(() => Object.values(markersMap), [JSON.stringify(markersMap)]);
  const fitBounds = useCallback(() => {
    if (map && shouldFitBound) {
      /**
       * setting zoom or bounds will update map
       * even though it is not a user generated change
       */
      setIsUserTriggeredBoundsChange(false);
      let extendedBounds;
      if (!disableDraw && polygonGeoJSON && markers.length < 1) {
        extendedBounds = getPolygonBounds(polygonGeoJSON);
      } else {
        extendedBounds = getMarkersBounds(bounds) || getMarkersBounds(markers) || map.getBounds();
      }
      if (extendedBounds) {
        map.fitBounds(extendedBounds);
        if (isMapEnhancementEnabled && onBoundsChangedHandlerFn) {
          onBoundsChangedHandlerFn(createPolygonFromBounds(extendedBounds), true);
        }
      }
      if (markers?.length === 1) {
        map.setZoom(oneDotZoom);
      } else if (boundChangeRef.current.boundsZoomLevel) {
        map.setZoom(boundChangeRef.current.boundsZoomLevel);
        boundChangeRef.current.boundsZoomLevel = 0;
      } else if (!getMarkersBounds(bounds) && !getMarkersBounds(markers)) {
        map.setZoom(zoom);
      } else {
        //NOTE: this is a weird bug of markers getting clustered on first load, so setting zoom to null helps in re-calculating the marker bounds
        setTimeout(() => {
          map.setZoom(map.getZoom());
        }, 1000);
      }
    }
  }, [map, markers, bounds, oneDotZoom, polygonGeoJSON]);

  const getPolygonBounds = (polygon) => {
    const bounds = new google.maps.LatLngBounds();
    polygon.coordinates[0].forEach((point) => {
      bounds.extend({ lat: point[1], lng: point[0] });
    });
    return bounds;
  };
  const styleLocalityBoundary = () => {
    const styleFill = {
      strokeColor: '#727272',
      strokeOpacity: 1.0,
      strokeWeight: 3.0,
      fillColor: '#727272',
      fillOpacity: 0,
    };

    if (featureLayer) {
      featureLayer.style = (params) => {
        if (selectedLocations?.includes(params.feature.placeId)) {
          return styleFill;
        }
      };
    }
  };

  useEffect(() => {
    if (polygonGeoJSON) {
      calculateDrawnPolygonZoom();
      if (featureLayer) {
        featureLayer.style = null;
      }
    }
  }, [polygonGeoJSON]);

  const calculateDrawnPolygonZoom = () => {
    const bounds = new window.google.maps.LatLngBounds();
    polygonGeoJSON.coordinates[0].forEach((coord) => {
      bounds.extend(new window.google.maps.LatLng(coord[1], coord[0]));
    });
    const zoom = getZoomForBounds(bounds, { height: 1100, width: 800 });
    setPreviousZoom(zoom + 0.5);
  };

  const onLoad = useCallback(
    (mapInstance) => {
      setMap(mapInstance);
      setFeatureLayer(mapInstance?.getFeatureLayer('LOCALITY'));
      !noFitBounds && fitBounds();
    },
    [fitBounds],
  );

  function createPolygonFromBounds(bounds) {
    const northLatitude = bounds.getNorthEast().lat();
    const northLongitude = bounds.getNorthEast().lng();
    const southLatitude = bounds.getSouthWest().lat();
    const southLongitude = bounds.getSouthWest().lng();

    // Build the Polygon coordinates array
    const polygonCoords = [
      [northLongitude, northLatitude], // Northeast corner
      [southLongitude, northLatitude], // Northwest corner
      [southLongitude, southLatitude], // Southwest corner
      [northLongitude, southLatitude], // Southeast corner
      [northLongitude, northLatitude], // closing the loop
    ];

    return polygonCoords;
  }

  let onBoundsChangedTimeout;
  const onBoundsChangedHandler = (event) => {
    clearTimeout(onBoundsChangedTimeout);

    onBoundsChangedTimeout = setTimeout(() => {
      const bounds = map.getBounds();
      boundChangeRef.current.boundChangeCount += 1;

      if (
        bounds &&
        onBoundsChangedHandlerFn &&
        boundChangeRef.current.boundChangeCount > 1 &&
        isMapEnhancementEnabled
      ) {
        const boundsPolygon = createPolygonFromBounds(bounds);

        if (!isEqual(boundsPolygon, boundChangeRef.current.boundsPolygon)) {
          if (boundChangeRef.current.boundsPolygon.length && isUserTriggeredBoundsChange) {
            setIsUserTriggeredBoundsChange(false);
            boundChangeRef.current.boundsZoomLevel = map.getZoom();
            onBoundsChangedHandlerFn(boundsPolygon, false);
          }

          boundChangeRef.current.boundsPolygon = boundsPolygon;
        }
      }
    }, 750);
  };

  useEffect(() => {
    if (isDrawingMode) {
      if (featureLayer) {
        featureLayer.style = null;
      }
    } else if (!polygonGeoJSON) {
      styleLocalityBoundary();
    }
  }, [isDrawingMode]);

  useEffect(() => {
    setInfoWindowData(null);
    !noFitBounds && fitBounds();
    if (!isDrawingMode && !polygonGeoJSON) {
      styleLocalityBoundary();
    }
  }, [markers, fitBounds, bounds]);

  const getStyles = () => {
    const clusterStyles = [];
    for (let i = 0; i < 6; i++) {
      clusterStyles.push({
        url: MarkerActive,
        textColor: '#ffffff',
        textSize: 12,
        fontWeight: 600,
        width: 80,
        height: 80,
        anchorText: [-20, 0],
        anchorIcon: [20, 20],
      });
    }

    return clusterStyles;
  };

  const getCenter = () => {
    if (center && center.lat && center.lng) return center;

    const coordinates = markers?.[0]?.[0]?.geometry?.coordinates;
    if (coordinates?.lat && coordinates?.lng) {
      return coordinates;
    }

    return undefined;
  };

  const calculator = useCallback(
    (clusterMarkers, stylesLength) => ({
      index: getClusterStyleIndex(clusterMarkers, stylesLength),
      text: getClusterText(clusterMarkers, markersMap, groupLabel),
    }),
    [markersMap, groupLabel],
  );
  return (
    <div
      id={id}
      onDoubleClick={() => {
        setIsUserTriggeredBoundsChange(true);
      }}
      onWheel={() => {
        setIsUserTriggeredBoundsChange(true);
      }}
      onMouseOut={() => {
        setIsUserTriggeredBoundsChange(false);
      }}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        mapContainerClassName={classNames(styles.googleMap, mapContainerClassName)}
        center={getCenter()}
        zoom={zoom}
        clickableIcons={false}
        onLoad={onLoad}
        options={mapOptions}
        onBoundsChanged={onBoundsChangedHandler}
        onDragStart={() => {
          setIsUserTriggeredBoundsChange(true);
        }}
        restriction={{
          latLngBounds: USACountryBounds,
        }}
        onClick={() => setInfoWindowData(null)}
      >
        {controls?.fullscreen ? (
          <FullScreenControl className={controlsClassName} map={map} />
        ) : null}
        {controls?.zoom ? (
          <ZoomControls
            className={controlsClassName}
            map={map}
            onZoomChanged={() => {
              boundChangeRef.current.boundsZoomLevel = map.getZoom();
              setIsUserTriggeredBoundsChange(true);
            }}
          />
        ) : null}
        {controls?.center ? (
          <Center
            className={controlsClassName}
            map={map}
            onZoomChanged={() => {
              boundChangeRef.current.boundsZoomLevel = map.getZoom();
              setIsUserTriggeredBoundsChange(true);
            }}
          />
        ) : null}
        {markers?.length && (
          <>
            {listingCountTextForMap && isMapEnhancementEnabled ? (
              <div className={styles.countBoxOnMap}>
                <span>{listingCountTextForMap}</span>
              </div>
            ) : (
              <></>
            )}
            {/* Render InfoWindow */}
            {infoWindowData && (
              <InfoWindow
                position={infoWindowData.position}
                onCloseClick={() => setInfoWindowData(null)}
                options={{
                  pixelOffset: new google.maps.Size(20, -20), // Moves the InfoWindow above its default position
                }}
              >
                <InfoProperties properties={infoWindowData.properties} />
              </InfoWindow>
            )}
            <MarkerClusterer
              styles={getStyles()}
              clusterClass={styles.markerCluster}
              calculator={calculator}
              onClick={(e) => {
                if (map.getZoom() === 22) {
                  const lat = e.getCenter().lat();
                  const lng = e.getCenter().lng();
                  const properties = markers
                    .filter((arr) => {
                      const coordinates = arr?.[0]?.geometry?.coordinates;
                      if (!coordinates) return false;

                      const centerLat = parseFloat(lat.toFixed(4));
                      const centerLng = parseFloat(lng.toFixed(4));
                      const markerLat = parseFloat(coordinates.lat.toFixed(4));
                      const markerLng = parseFloat(coordinates.lng.toFixed(4));

                      return markerLat === centerLat && markerLng === centerLng;
                    })
                    .flat()
                    .filter(({ property }) => property)
                    .map(({ property }) => property);
                  if (properties?.length) {
                    // Set the InfoWindow data
                    setInfoWindowData({
                      position: { lat, lng },
                      properties,
                    });
                  }
                }
              }}
            >
              {(clusterer) => (
                <Markers
                  markers={markers}
                  popupClassName={popupClassName}
                  onClickMarker={onClickMarker}
                  clusterer={clusterer}
                  getPropertyLink={getPropertyLink}
                  neighborhoodExploration={props.neighborhoodExploration}
                  setInfoWindowData={setInfoWindowData}
                />
              )}
            </MarkerClusterer>
          </>
        )}
        {props.children}
      </GoogleMap>
    </div>
  );
};

const MarkerType = PropTypes.shape({
  geometry: PropTypes.shape({
    coordinates: PropTypes.shape({}),
  }),
});

Map.propTypes = {
  id: PropTypes.string,
  controlsClassName: PropTypes.string,
  mapContainerClassName: PropTypes.string,
  popupClassName: PropTypes.string,
  containerStyle: PropTypes.shape({
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  bounds: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        geometry: PropTypes.shape({
          coordinates: PropTypes.shape({}),
        }),
      }),
    ),
  ),
  zoom: PropTypes.number,
  oneDotZoom: PropTypes.number,
  options: PropTypes.shape({}),
  markersMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.arrayOf(MarkerType),
  }),
  onClickMarker: PropTypes.func,
  getPropertyLink: PropTypes.func,
  controls: PropTypes.shape({
    fullscreen: PropTypes.bool,
    zoom: PropTypes.bool,
    drawMode: PropTypes.bool,
    center: PropTypes.bool,
  }),
  children: PropTypes.element | PropTypes.arrayOf(PropTypes.element),
  shouldFitBound: PropTypes.bool,
  neighborhoodExploration: PropTypes.bool,
  isMapEnhancementEnabled: PropTypes.bool,
  listingCountTextForMap: PropTypes.string,
  noFitBounds: PropTypes.bool,
};

Map.defaultProps = {
  id: '',
  controlsClassName: '',
  mapContainerClassName: '',
  popupClassName: '',
  containerStyle: {
    width: '100%',
    height: '100%',
  },
  center: undefined,
  bounds: undefined,
  zoom: 12,
  oneDotZoom: 16,
  options: {},
  markersMap: {},
  onClickMarker: () => {},
  getPropertyLink: () => {},
  controls: {
    fullscreen: false,
    zoom: true,
    drawMode: false,
    center: false,
  },
  shouldFitBound: true,
  neighborhoodExploration: false,
  isMapEnhancementEnabled: false,
  listingCountTextForMap: '',
  noFitBounds: false,
};

export default Map;

import { useState, useEffect, useRef, RefObject } from 'react';
import classNames from 'classnames';

import {
  FormDocumentAnswersType,
  FormDocumentQuestionType,
  FormDocumentQuestionsType,
} from 'types';
import { PDF_FIELD_TYPE } from 'app-constants';
import { dynamicManager } from 'pages/FormProcess/DynamicForm/DynamicManager';

import styles from './styles.module.scss';
import { hasFilledAnswer } from '../helper';
import { debounce } from 'lodash-es';

interface SignWizardProps {
  allQuestions: FormDocumentQuestionsType;
  responses?: FormDocumentAnswersType;
  containerRef?: RefObject<HTMLDivElement>;
  dynamicForm?: boolean;
  color?: {
    background: string;
    border: string;
  };
  wizardStarted?: boolean;
}

const DEFAULT_TOP = 135;

export const SignWizard = ({
  allQuestions = [],
  responses = {},
  containerRef,
  dynamicForm,
  color,
  wizardStarted,
}: SignWizardProps) => {
  const questions = allQuestions.filter(
    (ques) =>
      (dynamicForm ? true : ques.FieldType === PDF_FIELD_TYPE.PDFSignature) && !ques.disabled,
  );

  const [wizard, setWizard] = useState(false);
  const [wizardText, setWizardText] = useState('Start signing');
  const [top, setTop] = useState(DEFAULT_TOP);
  const [activeIndex, setActiveIndex] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const wizardRef = useRef({
    SIGN_WIZARD_VIEW_ENABLED: false,
    SIGN_CONTAINER_VISIBLE: false,
    USER_SCROLL: true,
  });

  const question = questions[activeIndex];
  const questionUUID = question?.UUID;

  const answer = questionUUID ? responses?.[question.UUID]?.Answer : undefined;

  const hide =
    !questions?.length ||
    !(activeIndex >= 0) ||
    !questions?.[activeIndex] ||
    questions?.every((ques) => responses?.[ques?.UUID]?.Answer);

  const getWizardText = () => {
    let text = 'Next';
    let remainingCount = 0;

    questions.map((question) => {
      if (!responses?.[question.UUID]?.Answer) {
        remainingCount++;
      }
    });

    if (remainingCount === 1) {
      text = 'Almost done';
    }

    return text;
  };

  useEffect(() => {
    if (wizard) {
      handleOnNext(true);
    }

    wizardRef.current.SIGN_WIZARD_VIEW_ENABLED = wizard;
  }, [wizard]);

  useEffect(() => {
    if (answer && wizard) {
      handleOnNext();
    }
  }, [answer]);

  useEffect(() => {
    if (!wizard) {
      if (wizardStarted) {
        startWizard();
      } else if (hasFilledAnswer(responses, questions)) {
        const text = getWizardText();
        setWizardText(text);
      }
    }
  }, [wizardStarted]);

  const getScrollContainer = () => {
    if (dynamicForm) {
      return dynamicManager.getPageWrapper();
    }
    return containerRef?.current;
  };

  const getSignWizardElement = () => {
    return ref?.current;
  };

  const getElement = (question: FormDocumentQuestionType) => {
    if (question) {
      if (dynamicForm) {
        const questionUUID = question.UUID;
        if (questionUUID) {
          return dynamicManager.getPageQuestion(questionUUID);
        }
      } else {
        const nextQuestionFieldId = question?.Fields?.[0]?.id;
        const container = getScrollContainer();
        if (container?.querySelector) {
          const element = container.querySelector(`[itemid="${nextQuestionFieldId}-pdfField"]`);
          if (element) {
            return element as HTMLDivElement;
          }
        }
      }
    }

    return null;
  };

  const getFieldElement = (index) => {
    const question = questions?.[index];

    return getElement(question);
  };

  const setDefaultTop = (container) => {
    const wizardView = wizardRef.current.SIGN_WIZARD_VIEW_ENABLED;
    const containerVisible = wizardRef.current.SIGN_CONTAINER_VISIBLE;

    if (containerVisible && wizardView) {
      // do nothing
    } else if (container && container?.scrollTop >= 0) {
      setTop(DEFAULT_TOP + container.scrollTop);
    }
  };

  const handleScroll = debounce(() => {
    if (wizardRef.current.USER_SCROLL) {
      const element = getScrollContainer();
      if (element) {
        setDefaultTop(element);
      }
    }
  }, 300);

  const findNextSignatureIndex = () => {
    return questions.findIndex((ques) => !responses?.[ques.UUID]?.Answer);
  };

  const onNext = (isWizard = false) => {
    const index = findNextSignatureIndex();
    const container = getScrollContainer();

    if (index >= 0 && container) {
      const element = getFieldElement(index);
      if (element?.scrollIntoView) {
        wizardRef.current.USER_SCROLL = false;
        wizardRef.current.SIGN_CONTAINER_VISIBLE = true;
        const childRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        element.scrollIntoView({
          behavior: isWizard && childRect.top > 40000 ? 'auto' : 'smooth',
          block: 'center',
        });

        setActiveIndex(index);

        const yPositionInsideGrandfather = childRect.top - containerRect.top + container.scrollTop;

        setTop(yPositionInsideGrandfather);
        setTimeout(() => {
          wizardRef.current.USER_SCROLL = true;
          handleScroll();
        }, 1000);
      }
    }
  };

  useEffect(() => {
    const container = getScrollContainer();
    const element = getSignWizardElement();

    if (container?.addEventListener) {
      container.addEventListener('scroll', handleScroll);
    }

    wizardRef.current.SIGN_CONTAINER_VISIBLE = true;

    const options = {
      root: container,
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      const userScroll = wizardRef.current.USER_SCROLL;

      if (!entry.isIntersecting && userScroll) {
        wizardRef.current.SIGN_CONTAINER_VISIBLE = entry.isIntersecting;
      } else if (!userScroll) {
        wizardRef.current.SIGN_CONTAINER_VISIBLE = entry.isIntersecting;
      }
    }, options);
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (container?.removeEventListener) {
        container.removeEventListener('scroll', handleScroll);
      }
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  const handleOnNext = (isWizard = false) => {
    onNext(isWizard);
  };

  const startWizard = () => {
    setWizard(true);
  };

  if (hide) {
    return <></>;
  }

  return (
    <div
      className={classNames(styles.startWizard, styles.signBox, { [styles.animate]: wizard })}
      style={{ top: `${top}px`, ...(color ? { ...color } : {}) }}
      onClick={() => (wizard ? handleOnNext() : startWizard())}
      itemID={'SignWizard'}
      ref={ref}
    >
      {wizard ? getWizardText() : wizardText}
    </div>
  );
};

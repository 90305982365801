import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getPastMemberCampaignInfoEffect,
  getUpcomingMemberCampaignInfoEffect,
} from 'store/effects/radarKits';
import { Button, Wrapper } from 'components';
import { Icons } from '../../../icons';
import { Table } from 'pages/Radar/components';
import { PastDataType, UpcomingDataType, useCampaignClientExecInfo } from 'pages/Radar';
import moment from 'moment';
import { ReplyKitsModal } from 'pages/Radar/RadarKits/ReplyKitsModal';

export const SelectedKit = ({ kit, handleBack, clientId }) => {
  const dispatch = useDispatch();
  const [upcomingData, setUpcomingData] = useState<any[]>([]);
  const [pastData, setPastData] = useState<any[]>([]);
  const [loading, setLoading] = useState<any>({ upcoming: true, past: true });
  const [isReplyModelOpen, setIsReplyModelOpen] = useState<boolean>(false);
  const [replyRecord, setReplyRecord] = useState<any>(null);

  const openReplyModal = useCallback((record) => {
    setReplyRecord(record);
    setIsReplyModelOpen(true);
  }, []);

  const closeReplyModal = useCallback(() => {
    setReplyRecord(null);
    setIsReplyModelOpen(false);
  }, []);

  const { pastColumns, upcomingColumns } = useCampaignClientExecInfo({
    onReplyClick: openReplyModal,
  });

  const id = kit;

  useEffect(() => {
    if (!clientId || !id) return;
    dispatch(
      getUpcomingMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!upcomingData },
        (err, res) => {
          if (!err) setUpcomingData(res.data);
          setLoading((prev) => {
            prev.upcoming = false;
            return prev;
          });
        },
      ),
    );
    dispatch(
      getPastMemberCampaignInfoEffect(
        {
          campaignId: id,
          memberId: clientId,
        },
        { silent: !!pastData },
        (err, res) => {
          if (!err) {
            setPastData(res.data);
            setLoading((prev) => {
              prev.past = false;
              return prev;
            });
          }
        },
      ),
    );
  }, [clientId, id]);

  const pastDataSource = useMemo<PastDataType[]>(() => {
    return pastData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item.assignee,
          },
          isOpened: item.opened
            ? {
                date: moment(item.openedDate).format('MM/DD/YYYY'),
                time: moment(item.openedDate).format('h:mm A'),
              }
            : null,
          isClicked: item.clicked
            ? {
                date: moment(item.clickedDate).format('MM/DD/YYYY'),
                time: moment(item.clickedDate).format('h:mm A'),
              }
            : null,
          isReplied: item?.repliedDate
            ? {
                date: moment(item.repliedDate).format('MM/DD/YYYY'),
                time: moment(item.repliedDate).format('h:mm A'),
              }
            : null,
          replies: item.replies || [],
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          completed: {
            on: moment(item.execDate).format('MM/DD/YYYY'),
            by: item?.campaignTask?.CompletedBy,
          },
          isOpened: item.opened
            ? {
                date: moment(item.openedDate).format('MM/DD/YYYY'),
                time: moment(item.openedDate).format('h:mm A'),
              }
            : null,
          isClicked: item.clicked
            ? {
                date: moment(item.clickedDate).format('MM/DD/YYYY'),
                time: moment(item.clickedDate).format('h:mm A'),
              }
            : null,
          isReplied: item?.repliedDate
            ? {
                date: moment(item.repliedDate).format('MM/DD/YYYY'),
                time: moment(item.repliedDate).format('h:mm A'),
              }
            : null,
          replies: item.replies || [],
        };
      }
    });
  }, [pastData]);

  const upcomingDataSource = useMemo<UpcomingDataType[]>(() => {
    return upcomingData.map((item) => {
      if (item.campaignKit) {
        return {
          key: item.campaignKit.Id,
          name: {
            title: item.campaignKit.Kit.Name,
            info: 'KIT',
          },
          assignee: item.assignee,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      } else {
        return {
          key: item.campaignTask.Id,
          name: {
            title: item.campaignTask.Title,
            info: 'Task',
          },
          assignee: item?.campaignTask?.AssigneeList?.[0]?.FirstName,
          scheduled: moment(item.execDate).format('MM/DD/YYYY'),
        };
      }
    });
  }, [upcomingData]);

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Button
          icon={<Icons variant={Icons.BACK} color="#262626" />}
          className={styles.button}
          onClick={handleBack}
        />
        <div>
          <p className={styles.heading}>{kit?.name?.title ?? ''}</p>
          <p className={styles.subHeading}>{kit?.name?.info ?? ''}</p>
        </div>
        {/* <Button icon={<Icons variant={Icons.MENU} color="#262626" />} className={styles.button} /> */}
      </div>
      <Wrapper
        className={styles.tableContainer}
        isPending={Object.values(loading).every((val) => val)}
      >
        <div>
          <Table
            title="Upcoming"
            count={upcomingDataSource.length}
            data={upcomingDataSource}
            columns={upcomingColumns}
            disableCollapse={true}
          />
        </div>
        <div>
          <Table
            title="Past"
            count={pastDataSource.length}
            data={pastDataSource}
            columns={pastColumns}
            disableCollapse={true}
          />
        </div>
        {isReplyModelOpen && (
          <ReplyKitsModal
            isOpen={isReplyModelOpen}
            closeModal={closeReplyModal}
            replyRecord={replyRecord}
          />
        )}
      </Wrapper>
    </div>
  );
};

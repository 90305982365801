import { apiServer } from 'settings/web-services/api';
import { AddressValuationDetails, GetAddressValuationPayload } from './types';

export const getAddressValuationDetails = (payload: GetAddressValuationPayload) => {
  return apiServer.get<AddressValuationDetails[]>('/propertyreferencedata/portfolio/client-data', {
    params: payload,
  });
};

export const getAddressNeighborhoodActivity = (body) => {
  return apiServer.post('/propertyActivity/searchPropertyActivities', body);
};

export const getAddValuationHomeValue = ({ body, params }) => {
  return apiServer.post('/propertyreferencedata/portfolio/custom-home-value?' + params, body);
};

export const getEditUpdateAddressValue = (body) => {
  return apiServer.put('/propertyreferencedata/portfolio/update-address-meta', body);
};

export const getAddValuationRentalValue = ({ body, params }) => {
  return apiServer.post('/propertyreferencedata/portfolio/custom-rental-value?' + params, body);
};

export const getAddAvmAdjustmentValue = ({ body }) => {
  return apiServer.post('/propertyreferencedata/portfolio/avm-adjustment', body);
};

export const getEditAvmAdjustmentValue = ({ body, id }) => {
  return apiServer.put('/propertyreferencedata/portfolio/avm-adjustment/' + id, body);
};

export const getCancelAvmAdjustmentValue = (id) => {
  return apiServer.delete('/propertyreferencedata/portfolio/avm-adjustment/' + id);
};

import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';

import { Button } from 'components-antd';
import { dynamicManager } from '../DynamicManager';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { FORM_PROCESS_SCREEN } from 'app-constants';
import { DeclineButton } from './DeclineButton';
import { SignatoryFieldInformationModal } from './SignatoryFieldInformationModal';

export const DynamicFooter = () => {
  const {
    hideFooter: hideFormFooter,
    isLoading,
    screen,
    allowRegress,
  } = useSelector(getDynamicFormSelector);

  const isSignScreen = screen === FORM_PROCESS_SCREEN.Sign;
  const isSendDocumentScreen = screen === FORM_PROCESS_SCREEN.SendDocument;

  const [disabled, setDisabled] = useState(false);

  const showDecline = !allowRegress && isSignScreen;

  const isBundleForm = dynamicManager.isBundleForm();
  const isBundleTemplate = dynamicManager.isBundleTemplate();

  const handleClick = () => {
    dynamicManager.handleContinue(setDisabled);
  };

  const isDisabled = disabled || isLoading;

  const hideFooter = hideFormFooter || isSendDocumentScreen;
  return (
    <>
      {!hideFooter && (
        <Fragment>
          {isSignScreen && allowRegress && (
            <SignatoryFieldInformationModal setDisabled={setDisabled} />
          )}

          <div
            className={classNames(styles.footerContainer, {
              [styles.declineDynamicFooterContainer]: showDecline,
            })}
          >
            {showDecline && <DeclineButton />}

            <Button
              variant="secondary"
              className={classNames(styles.button, { [styles.disabledButton]: isDisabled })}
              disabled={isDisabled}
              onClick={isLoading ? undefined : handleClick}
              loading={(isBundleForm && isSignScreen && disabled) || (isBundleTemplate && disabled)}
            >
              {dynamicManager.isTemplateForm() ? 'Save' : 'Continue'}
            </Button>
          </div>
        </Fragment>
      )}
    </>
  );
};

import { useState, FC, useCallback, useEffect } from 'react';
import { Avatar, DropdownCheck } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { RadarHeader } from '../components/RadarHeader';
import { RadarPortfolioViewTypes, TeamViewTypes } from '../types';
import { Table } from '../components';
import { map } from 'lodash-es';
import { getInitials } from 'utils';
import { Widget } from './components/Widget';
import { PageWrapper } from 'components-antd';
import ActionsIcon from 'pages/Properties/Search/components/Icons/ActionsIcon';
import { Popover } from 'components-antd';
import {
  requestPropertyReferenceDataEffect,
  requestPropertyReferenceStatsEffect,
  updateClientPropertyEffect,
} from 'store/effects/radarPortfolio';
import {
  getPropertyReferenceDataSelector,
  getPropertyReferenceStatsSelector,
} from 'store/selectors/radarPortfolio';
import { PENDING } from 'settings/constants/apiState';
import moment from 'moment';
import { Icons } from '../Icons';
import { TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { Archive } from 'components/Icons';
import { Button } from 'components';
import classNames from 'classnames';
import { requestGetTeamListEffect } from 'store/effects';
import { getFormattedTeamList } from 'store/selectors/teamList';
import { getUserId } from 'store/selectors/user';

export const RadarPortfolio: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { data: referenceData, state } = useSelector(getPropertyReferenceDataSelector);
  const { data: stats, state: statsState } = useSelector(getPropertyReferenceStatsSelector);
  const teamList = useSelector(getFormattedTeamList);
  const userId = useSelector(getUserId);
  const teamIds = teamList?.map(({ id }) => id);
  const userRole = useSelector(getAgentTeamRoleSelector);
  const [view, setView] = useState(RadarPortfolioViewTypes.ViewByClient);
  const [myView, setMyView] = useState(false);

  const [showArchived, setShowArchived] = useState(false);

  const lastCalculatedMonth =
    stats?.homeValueMonthlyStats?.[stats?.homeValueMonthlyStats?.length - 1]?.month;
  const isAdminOrOwner = [TEAM_ADMIN, TEAM_OWNER].includes(userRole);
  const isPropertyView = view === RadarPortfolioViewTypes.ViewByProperty;
  const isAgentView = view === RadarPortfolioViewTypes.ViewByAgent;
  const isLoading = state === PENDING;
  const isLoadingStats = statsState === PENDING;

  const data =
    isAdminOrOwner && myView
      ? referenceData?.filter((client) => {
          const agents = client.Client?.Agents || [];
          return agents.some((agent) => agent.Id === userId);
        })
      : referenceData;

  const getAgentViewData = () => {
    const agentMap = new Map();

    data?.forEach((client) => {
      const { Client, AvatarUrl, Id: clientId } = client;
      const { Agents } = Client;

      Agents?.forEach(({ Id: agentId, User }) => {
        if (!teamIds.includes(agentId)) return;
        if (!agentMap.has(agentId)) {
          agentMap.set(agentId, {
            name: `${User?.FirstName || ''} ${User?.LastName || ''}`.trim(),
            image: User?.AvatarUrl || null,
            address: [],
          });
        }

        Client.Address.forEach((addressObj) => {
          const { Id, address1, address2, homeValue, rentalValue } =
            addressObj?.parsedData || addressObj || {};
          const { month, year } = addressObj?.parsedData || {};
          const formattedDate = moment(`${year}-${month}`, 'YYYY-M').format('YYYY-MM');
          const isValid = lastCalculatedMonth == formattedDate;

          const addressData = {
            id: Id,
            property: { line1: address1 || '', line2: address2 || '' },
            client: {
              name: `${client.FirstName || ''} ${client.LastName || ''}`.trim(),
              image: AvatarUrl || null,
            },
            value: isValid && homeValue ? homeValue : '-',
            rentalValue: isValid && rentalValue ? rentalValue : '-',
            activity: addressObj.neighbourhoodRadius || '-',
            closed: addressObj.closingDate
              ? moment(addressObj.closingDate).format('MM/DD/YYYY')
              : '-',
            clientId,
          };

          agentMap.get(agentId).address.push(addressData);
        });
      });
    });

    // Convert map values to an array of agent objects
    return Array.from(agentMap.values());
  };

  const clientViewData = data
    ?.map(({ FirstName, LastName, Client, AvatarUrl, Id: clientId }) => {
      const name = `${FirstName} ${LastName}`;
      return {
        name,
        image: AvatarUrl,
        address: Client?.Address?.map(
          ({ Id, closingDate, neighbourhoodRadius, parsedData, ...addressObj }) => {
            const { address1, address2, homeValue, rentalValue } = parsedData || addressObj || {};
            const { month, year } = parsedData || {};
            const formattedDate = moment(`${year}-${month}`, 'YYYY-M').format('YYYY-MM');
            const isValid = lastCalculatedMonth == formattedDate;

            return {
              id: Id,
              property: { line1: address1, line2: address2 },
              client: {
                name,
                image: AvatarUrl || '',
              },
              value: isValid && homeValue ? homeValue : '-',
              rentalValue: isValid && rentalValue ? rentalValue : '-',
              activity: neighbourhoodRadius || '-',
              closed: closingDate ? moment(closingDate).format('MM/DD/YYYY') : '-',
              clientId,
            };
          },
        ),
      };
    })
    ?.filter(({ address }) => address?.length);

  const agentViewData = getAgentViewData()?.filter(({ address }) => address?.length);

  const propertiesViewData = [
    {
      name: '',
      address:
        data?.flatMap(
          ({ FirstName, LastName, Client, AvatarUrl, Id: clientId }) =>
            Client?.Address?.map(
              ({ Id, closingDate, neighbourhoodRadius, parsedData, ...addressObj }) => {
                const { address1, address2, homeValue, rentalValue } =
                  parsedData || addressObj || {};
                const { month, year } = parsedData || {};
                const formattedDate = moment(`${year}-${month}`, 'YYYY-M').format('YYYY-MM');
                const isValid = lastCalculatedMonth == formattedDate;
                return {
                  id: Id,
                  property: { line1: address1, line2: address2 },
                  client: {
                    name: `${FirstName} ${LastName}`,
                    image: AvatarUrl,
                  },
                  value: isValid && homeValue ? homeValue : '-',
                  rentalValue: isValid && rentalValue ? rentalValue : '-',
                  activity: neighbourhoodRadius || '-',
                  closed: closingDate ? moment(closingDate).format('MM/DD/YYYY') : '-',
                  clientId,
                };
              },
            ) || [],
        ) ?? [],
    },
  ];

  useEffect(() => {
    dispatch(
      requestPropertyReferenceDataEffect({
        Archive: showArchived,
      }),
    );
    dispatch(requestPropertyReferenceStatsEffect({ Archive: showArchived }));
  }, [showArchived]);

  useEffect(() => {
    dispatch(requestPropertyReferenceStatsEffect());
    dispatch(requestGetTeamListEffect());
  }, []);

  const archiveUpdate = useCallback(async (clientId, addressId, isArchive) => {
    await dispatch(
      updateClientPropertyEffect({ addressId, clientId, isArchive }, {}, (err, data) => {
        if (!err) {
          dispatch(
            requestPropertyReferenceDataEffect({
              Archive: !isArchive,
            }),
          );
          dispatch(requestPropertyReferenceStatsEffect({ Archive: !isArchive }));
        }
      }),
    );
  }, []);

  const columns: any = [
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      width: '25%',
      render: (property: any) => (
        <div className={styles.propertyContainer}>
          <div className={styles.iconContainer}>
            <Icons variant={Icons.CONDO} />
          </div>
          <div className={styles.textContainer}>
            <p className={styles.line1}>{property?.line1}</p>
            <p className={styles.line2}>{property?.line2}</p>
          </div>
        </div>
      ),
    },
    ...(isPropertyView || isAgentView
      ? [
          {
            title: 'Client',
            dataIndex: 'client',
            key: 'client',
            width: '20%',
            render: (client: any) => (
              <div className={styles.client}>
                <Avatar
                  avatarClassName={styles.avatarSmall}
                  src={client?.image}
                  placeholder={getInitials(client?.name)}
                />
                <p className={styles.text}>{client?.name}</p>
              </div>
            ),
          },
        ]
      : []),
    {
      title: 'Est. Value',
      dataIndex: 'value',
      key: 'value',
      width: '16%',
      render: (val: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={val}
          prefix="$"
          renderText={(val) => <p className={styles.value}>{val}</p>}
        />
      ),
    },
    {
      title: 'Est. Rental Value',
      dataIndex: 'rentalValue',
      key: 'rentalValue',
      width: '16%',
      render: (rentalValue: string) => (
        <NumberFormat
          thousandSeparator
          displayType="text"
          value={rentalValue}
          prefix="$"
          renderText={(val) => <p className={styles.text}>{val}</p>}
        />
      ),
    },
    {
      title: 'Neighborhood Radius',
      dataIndex: 'activity',
      key: 'activity',
      width: '16%',
      render: (activity: number) => <p className={styles.text}>{activity}</p>,
    },
    {
      title: 'Closed',
      dataIndex: 'closed',
      key: 'closed',
      width: '16%',
      render: (closed: string) => <p className={styles.text}>{closed}</p>,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (id: any, data) => (
        <p className={styles.value}>
          <div className={styles.actionBtn}>
            <Popover
              overlayClassName={styles.popoverOverlay}
              placement="bottomRight"
              trigger="hover"
              content={
                <div className={styles.actionList}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      archiveUpdate(data.clientId, data.id, !showArchived);
                    }}
                    className={styles.actionListBtn}
                  >
                    <Archive
                      className={styles.icon}
                      color="#FF576D"
                      size={'24'}
                      strokeWidth={'2'}
                    />
                    <span>{showArchived ? 'Unarchive' : 'Archive'}</span>
                  </div>
                </div>
              }
            >
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ActionsIcon className={styles.actionBtnIcon} color={'#252d44'} />
              </div>
            </Popover>
          </div>
        </p>
      ),
    },
  ];

  const TeamViewItems = [
    {
      label: 'Team Portfolio',
      value: TeamViewTypes.TeamPortfolio,
    },
    {
      label: 'My Portfolio',
      value: TeamViewTypes.MyPortfolio,
    },
  ];

  const ViewItems = [
    ...(isAdminOrOwner
      ? [
          {
            label: 'View By Agent',
            value: RadarPortfolioViewTypes.ViewByAgent,
          },
        ]
      : []),
    {
      label: 'View By Client',
      value: RadarPortfolioViewTypes.ViewByClient,
    },
    {
      label: 'View By Property',
      value: RadarPortfolioViewTypes.ViewByProperty,
    },
  ];

  const handleRowClick = useCallback(
    ({ id, property, clientId, client }) =>
      history.push('property', {
        address1: property?.line1,
        address2: property?.line2,
        addressId: id,
        clientName: client?.name,
        clientId,
      }),

    [dispatch],
  );

  const getDataByRoleAndView = () => {
    switch (view) {
      case RadarPortfolioViewTypes.ViewByProperty:
        return propertiesViewData;

      case RadarPortfolioViewTypes.ViewByClient:
        return clientViewData;

      case RadarPortfolioViewTypes.ViewByAgent:
        return agentViewData;

      default:
        break;
    }
  };

  return (
    <PageWrapper version={2} mainPageContentStyle={styles.pageWrapper}>
      <RadarHeader
        title="Portfolio"
        actions={
          <div className={styles.actions}>
            <div className={styles.row}>
              <DropdownCheck
                dropdownFields={ViewItems}
                defaultValue={RadarPortfolioViewTypes.ViewByClient}
                onChange={(val: string) => {
                  setView(val as RadarPortfolioViewTypes);
                }}
              />
              {isAdminOrOwner && (
                <DropdownCheck
                  dropdownFields={TeamViewItems}
                  defaultValue={TeamViewTypes.TeamPortfolio}
                  onChange={(val: string) => {
                    setMyView((val as TeamViewTypes) === TeamViewTypes.MyPortfolio);
                  }}
                />
              )}
            </div>
            <Button
              icon={
                <Archive color={showArchived ? '#fff' : '#252d44'} size={'24'} strokeWidth={'2'} />
              }
              className={classNames(styles.archive, { [styles.active]: showArchived })}
              onClick={() => setShowArchived((prev) => !prev)}
            />
          </div>
        }
      />
      <div className={styles.pageContent}>
        <div className={styles.tableContainer}>
          <div className={styles.widgetsContainer}>
            <Widget
              isLoading={isLoadingStats}
              title="Portfolio Value"
              subCount={stats?.homeValueGrowth ?? '0'}
              count={
                String(
                  stats?.homeValueMonthlyStats?.[stats?.homeValueMonthlyStats?.length - 1]?.total,
                ) ?? '0'
              }
              data={stats?.homeValueMonthlyStats}
              formatNumber
            />
            {isAdminOrOwner && (
              <Widget
                isLoading={isLoading}
                title="Agents"
                count={String(agentViewData?.length ?? 0)}
                color="yellow"
              />
            )}
            <Widget
              isLoading={isLoading}
              title="Clients"
              count={String(clientViewData?.length ?? 0)}
              color="lightBlue"
            />
            <Widget
              isLoading={isLoading}
              title="Properties"
              count={String(propertiesViewData[0]?.address?.length ?? 0)}
              color="purple"
            />
          </div>
          {map(getDataByRoleAndView(), ({ address, name = '' }: any) => (
            <div>
              <Table
                title={name}
                data={address}
                columns={columns}
                count={address?.length}
                handleRowClick={handleRowClick}
                titlePrefix={
                  <Avatar
                    avatarClassName={styles.avatar}
                    src={null}
                    placeholder={getInitials(name)}
                  />
                }
                disableHeader={isPropertyView}
                isLoading={isLoading}
              />
            </div>
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

import React, { useEffect, useMemo } from 'react';

import { activityLogsPageSize } from 'app-constants/activityLogs';
import { Spinner } from 'components';
import { AuditLogCard, Collapse, Option, Panel } from 'components-antd';
import { MultiSelect } from 'components-antd/MultiSelect';
import { Minus, Plus } from 'components/Icons';
import { keys } from 'lodash-es';
import { Moment } from 'moment';
import { constructPropertyAddress } from 'pages/Clarity/Components/Activity/components/ActivityFiltersDrawer';
import { useWorkshopActivityLogSection } from 'pages/Clarity/Components/Activity/components/WorkshopActivityLogSection/useWorkshopActivityLogSection';
import { DateRangePicker } from 'components/Form/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { Role } from 'settings/constants/roles';
import {
  requestGetActivityLogsEffect,
  resetActivityLogsItemsListEffect,
} from 'store/effects/activityLogsWorkshop';
import {
  resetActivityFiltersDrawerFieldsEffect,
  setActivityFiltersDrawerEndDateFieldEffect,
  setActivityFiltersDrawerPropertiesFieldEffect,
  setActivityFiltersDrawerStartDateFieldEffect,
  setActivityFiltersDrawerUsersFieldEffect,
} from 'store/effects/drawers/activityFilters';
import { relatedEntitiesCompleteListGetDataEffect } from 'store/effects/relatedEntities';
import { getRelatedEntitiesCompleteListState } from 'store/selectors/relatedEntities';
import { formatLogsData, getFormattedFilterObject } from 'utils/activityLogsHelper';
import { RadarHeader } from '../components/RadarHeader';
import styles from './styles.module.scss';
import { resetActivityFiltersDrawerFieldsAction } from 'store/actions/drawers/activityFilters';

export const RadarLogs: React.FC = () => {
  const dispatch = useDispatch();
  const { activities, getDayAndDateTitleStr, categories, isPending, loadingRef, fieldsPayload } =
    useWorkshopActivityLogSection();

  const relatedEntities = useSelector(getRelatedEntitiesCompleteListState);
  useEffect(() => {
    dispatch(relatedEntitiesCompleteListGetDataEffect());

    return () => {
      dispatch(resetActivityFiltersDrawerFieldsAction());
    };
  }, []);

  const renderActivities = () => {
    const formatted: any = [];
    activities?.items?.length > 0 &&
      activities?.items?.forEach((document) => {
        if (document.ActionTimestamp) {
          const date = getDayAndDateTitleStr(document.ActionTimestamp);
          const index = formatted.findIndex((f) => f[date] !== undefined);
          index === -1
            ? formatted.push({ [date]: [document] })
            : formatted[index][date].push(document);
        }
      });
    const taskedGroupedData = formatLogsData(activities?.items, {
      taskGroup: true,
      categories: categories,
    });

    if (taskedGroupedData.length) {
      return (
        <>
          {taskedGroupedData.map((item, idx) => (
            <AuditLogCard
              key={idx}
              activityLogs={item[`${keys(item)}`]}
              DayAndDate={keys(item)[0]}
              activityLogCardClassName={styles.clarityActivityLogCard}
            />
          ))}
        </>
      );
    }

    if (!isPending && !formatted.length) return <p className={styles.noDataFound}>No data found</p>;
  };

  const onDateChangeHandler = (_from, _to) => {
    console.log('HERE');
    const startDate = _to && !_from ? _to : _from;
    const endDate = _to;

    dispatch(
      setActivityFiltersDrawerStartDateFieldEffect({
        data: {
          ...(startDate ? { startDate } : { startDate: undefined }),
          ...(endDate ? { endDate } : { endDate: undefined }),
        },
      }),
    );
    onDone({
      ...fieldsPayload,
      ...(startDate ? { startDate } : { startDate: undefined }),
      ...(endDate ? { endDate } : { endDate: undefined }),
    });
  };

  const onUserSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerUsersFieldEffect({
        data: { users: value },
      }),
    );
    onDone({
      ...fieldsPayload,
      users: value,
    });
  };

  const clientsList = useMemo(
    () =>
      relatedEntities?.length
        ? relatedEntities?.filter((entity) => entity.Type === 'Contact' && entity.Role === 'Client')
        : [],
    [relatedEntities],
  );

  const renderClientsList = useMemo(() => {
    const list: any = [];
    clientsList?.forEach((p) => {
      const name = `${p.FirstName} ${p.LastName}`;
      list.push(<Option value={p.ContactUserId}>{name}</Option>);
    });

    return list;
  }, [relatedEntities]);

  const teamMembersList = useMemo(
    () =>
      relatedEntities?.length
        ? relatedEntities?.filter(
            (entity) => entity.Type === 'Contact' && entity.Role === Role.Agent,
          )
        : [],
    [relatedEntities],
  );

  const renderTeamMemberList = useMemo(() => {
    const list: any = [];
    teamMembersList?.forEach((p) => {
      const name = `${p.FirstName} ${p.LastName}`;
      list.push(<Option value={p.ContactUserId}>{name}</Option>);
    });

    return list;
  }, [relatedEntities]);

  const renderPropertyList = useMemo(() => {
    const list: any = [];
    relatedEntities?.length &&
      relatedEntities
        ?.filter((entity) => entity.Type === 'Property')
        ?.forEach((p) => {
          p.ExternalId &&
            list.push(
              <Option key={p.Id} value={p.ExternalId}>
                {constructPropertyAddress(p.Address)}
              </Option>,
            );
        });

    return list;
  }, [relatedEntities]);

  const onPropertiesSelectHandleChange = (value) => {
    dispatch(
      setActivityFiltersDrawerPropertiesFieldEffect({
        data: { properties: value },
      }),
    );
    onDone({
      ...fieldsPayload,
      properties: value,
    });
  };

  const onDone = (payload?: any) => {
    dispatch(resetActivityLogsItemsListEffect());
    dispatch(
      requestGetActivityLogsEffect(
        getFormattedFilterObject(payload || fieldsPayload, 1, activityLogsPageSize),
      ),
    );
  };

  const onResetAll = () => {
    dispatch(resetActivityFiltersDrawerFieldsEffect({}));
    onDone({});
  };
  return (
    <div className={styles.pageWrapper}>
      <RadarHeader title="Logs" />
      <div className={styles.pageContent}>
        <div className={styles.activityWrapper}>
          {renderActivities()}
          <div ref={loadingRef} style={{ height: '150px', margin: '25px' }}>
            <span style={{ display: isPending ? 'block' : 'none' }}>
              <Spinner />
            </span>
          </div>
        </div>
        <div className={styles.filterWrapper}>
          <FilterSection title="Date">
            <div>
              <DateRangePicker
                onDatesChange={onDateChangeHandler}
                dateRangePickerWrapperClass={styles.dataRangeWrapper}
              />
            </div>
          </FilterSection>
          <FilterSection title="Clients">
            <div>
              <MultiSelect
                size="large"
                title="Clients"
                placeholderText="All Clients"
                variant="user"
                onHandleChange={onUserSelectHandleChange}
                propClass={styles.activityDrawerMultiselect}
                optionFilterProp="children"
                values={fieldsPayload?.users.filter((id) =>
                  clientsList?.find((p) => p.ContactUserId === id),
                )}
                wrapperClass={styles.multiSelectWrapper}
              >
                {renderClientsList}
              </MultiSelect>
            </div>
          </FilterSection>
          <FilterSection title="Team Members">
            <div>
              <MultiSelect
                size="large"
                title="Team Members"
                placeholderText="All Team Members"
                variant="user"
                onHandleChange={onUserSelectHandleChange}
                propClass={styles.activityDrawerMultiselect}
                optionFilterProp="children"
                values={fieldsPayload?.users.filter((id) =>
                  teamMembersList?.find((p) => p.ContactUserId === id),
                )}
                wrapperClass={styles.multiSelectWrapper}
              >
                {renderTeamMemberList}
              </MultiSelect>
            </div>
          </FilterSection>
          <FilterSection title="Transactions">
            <div>
              <MultiSelect
                size="large"
                title="Transactions"
                placeholderText="All Transactions"
                onHandleChange={onPropertiesSelectHandleChange}
                propClass={styles.activityDrawerMultiselect}
                optionFilterProp="children"
                values={fieldsPayload?.properties}
                wrapperClass={styles.multiSelectWrapper}
              >
                {renderPropertyList}
              </MultiSelect>
            </div>
          </FilterSection>
        </div>
      </div>
    </div>
  );
};

type FilterSectionProps = {
  title: string;
  children: React.ReactNode;
};

const FilterSection: React.FC<FilterSectionProps> = ({ title, children }) => {
  const getCollapseIcon = (panelProps) => {
    const { isActive } = panelProps;
    return isActive ? (
      <Minus className={styles.btnWrapper} />
    ) : (
      <Plus className={styles.btnWrapper} />
    );
  };

  return (
    <Collapse
      ghost
      expandIcon={getCollapseIcon}
      expandIconPosition="end"
      className={styles.filterSection}
    >
      <Panel key="radarLogsFilter" header={<div className={styles.title}>{title}</div>}>
        {children}
      </Panel>
    </Collapse>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowDown } from 'components/Icons';
import { TagSelector } from 'components-antd';
import InputLabel from 'components/Form/InputLabel';
import {
  getTaskCategoriesEffect,
  createTransactionTaskCategoryEffect,
} from 'store/effects/transactions';
import { getTransactionTaskCategoriesSelector } from 'store/selectors/transactionTaskCategories';

import styles from './styles.module.scss';
import classNames from 'classnames';

type CategoryObject = {
  Name?: string;
  name?: string;
};

type ValueObject = {
  name?: string;
  value?: string | number;
  customTag?: string;
  Category?: CategoryObject | string;
  CategoryId?: string | number;
  role?: string;
  email?: string;
};

interface TaskTagProps {
  onSelect: (val) => void;
  onSelectCustomTag: (val) => void;
  value?: ValueObject;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showAsterisk?: boolean;
  addTag?: boolean;
  showOptional?: boolean;
  selectClassName?: string;
  className?: string;
}

type TagOptionsType = { TagId: number | string; Name: string; isCustomTag: boolean };

const TaskTag = ({
  onSelect,
  onSelectCustomTag,
  value = {},
  label = 'Tag',
  placeholder = 'Enter or select tag',
  disabled = false,
  showAsterisk = false,
  addTag = true,
  showOptional = false,
  selectClassName,
  className,
}: TaskTagProps) => {
  const dispatch = useDispatch();
  const { categories } = useSelector(getTransactionTaskCategoriesSelector);
  const [tagOptions, setTagOptions] = useState<TagOptionsType[]>([]);
  const [selectedTag, setSelectedTag] = useState<number | string | undefined>(undefined);

  useEffect(() => {
    dispatch(getTaskCategoriesEffect());
  }, []);

  useEffect(() => {
    if (categories?.length && !tagOptions.length) {
      const taskCategories = (categories || []).map(({ Name, Id }) => ({
        TagId: Id,
        Name,
        categoryId: Id,
      }));
      setTagOptions(taskCategories);
    }
  }, [categories]);

  const addCustomTag = ({ tagName }: { tagName: string }, cb: () => void) => {
    dispatch(
      createTransactionTaskCategoryEffect({ Name: tagName }, (err) => {
        if (!err) {
          setTagOptions((tagOptions) => [
            ...tagOptions,
            { TagId: tagName, Name: tagName, isCustomTag: true },
          ]);
        }
      }),
    );
    cb();
  };

  const handleChange = (Id?: number | string) => {
    const selectedOption = tagOptions.find((tagOption) => tagOption.TagId === Id);
    if (selectedOption?.isCustomTag) {
      onSelectCustomTag(selectedOption.Name);
    } else {
      onSelect(selectedOption?.Name);
    }
    setSelectedTag(Id);
  };

  useEffect(() => {
    if (tagOptions.length) {
      if (value?.customTag) {
        const option = tagOptions.find((tagOption) => tagOption?.TagId === value?.customTag);
        setSelectedTag(option?.TagId);
      } else if (value?.Category || value?.CategoryId) {
        const option = tagOptions.find(
          (tagOption) =>
            tagOption?.TagId === value?.Category ||
            tagOption?.TagId === value?.CategoryId ||
            tagOption?.Name === value?.Category ||
            (typeof value?.Category !== 'string' &&
              (tagOption?.Name === value?.Category?.Name ||
                tagOption?.Name === value?.Category?.name)),
        );
        setSelectedTag(option?.TagId);
      }
    }
  }, [tagOptions]);

  return (
    <div className={classNames(styles.tagSelectorContainer, className)}>
      {addTag ? (
        <>
          <InputLabel
            label={label}
            className={styles.label}
            showAsterisk={showAsterisk}
            showOptional={showOptional}
          />
          <TagSelector
            suffixIcon={<ArrowDown color="#ADADAD" />}
            className={classNames(styles.customTag, selectClassName)}
            selectedTags={selectedTag}
            allTags={tagOptions}
            handleChangeTag={handleChange}
            multiple={false}
            showSearch
            placeholder={placeholder}
            addTag={addCustomTag}
            disabled={disabled}
          />
        </>
      ) : null}
    </div>
  );
};

export default TaskTag;

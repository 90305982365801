import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, ModalProps, Button } from 'components-antd';
import { ConfirmationDialog } from 'components';
import { appSetShowAssignConnectionAction } from 'store/actions/app';
import {
  isAssignConnectionModalOpened,
  getConnectionTypeSelector,
  getClientIdFromDrawerSelector,
} from 'store/selectors/app';
import { ConnectionsList } from './ConnectionsList';
import { LocalHeader } from './LocalHeader';
import { Search } from './Search';
import { AGENT, CLIENT, TEAM_OWNER } from 'settings/constants/roles';
import {
  postConfigureAdminAccessEffect,
  postAssignClientsToMemberEffect,
  requestGetTeamPermittedListEffect,
  userGetDataOnBackgroundEffect,
} from 'store/effects';
import { getAgentDetailsSelector } from 'store/selectors/agentDetail';
import { showSuccessMessage } from 'helpers/success';
import { getTeamClients } from 'api/teamList';
import { AGENT_CONNECTIONS } from 'settings/constants/drawers';
import { reassignShareClientsEffect } from 'store/effects';
import { getActiveSubscriptionData, getUserId } from 'store/selectors/user';

import styles from './styles.module.scss';
import {
  requestActiveSubscriptionTeamDataEffect,
  requestGetInvoiceDataOverviewEffect,
} from 'store/effects/subscription';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

interface AssignConnectionModalProps extends ModalProps {
  disableOnEmpty?: boolean;
  closeOnClientEmpty?: boolean;
}

export const AssignConnectionModal = ({
  disableOnEmpty = true,
  closeOnClientEmpty = false,
  ...props
}: AssignConnectionModalProps) => {
  const [users, setUsers]: any = useState([]);
  const [query, setQuery] = useState('');
  const [connections, setConnections] = useState<number[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showAssignConnection, connectionAssignType, isNewlyInvited } = useSelector(
    isAssignConnectionModalOpened,
  );
  const connectionType = useSelector(getConnectionTypeSelector);
  const assignToUser: any = useSelector(getAgentDetailsSelector);
  const clientId = useSelector(getClientIdFromDrawerSelector);
  const userId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);
  const activeSubscriptionData = useSelector(getActiveSubscriptionData);

  const dispatch = useDispatch();

  const onClose = (body = { open: false }) => dispatch(appSetShowAssignConnectionAction(body));

  useEffect(() => {
    // By default, add the logged in user in connections.
    if (connectionType === 'Clients') setConnections([userId]);
  }, []);

  const fetchTeamClients = async () => {
    const list = await getTeamClients();
    if (!list?.data?.length && closeOnClientEmpty) {
      onClose();
    } else {
      setUsers(list?.data || []);
    }
  };

  const fetchTeamMembers = async () => {
    setLoading(true);
    dispatch(
      requestGetTeamPermittedListEffect({}, {}, (err, resp) => {
        if (!err) {
          setUsers(
            connectionType === AGENT_CONNECTIONS.Team
              ? agentRole === TEAM_OWNER
                ? resp.data
                : resp.data.filter((u) => u.Id !== userId)
              : resp.data,
          );
        }
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    if (connectionAssignType === CLIENT) fetchTeamClients();
    if (connectionAssignType === AGENT) fetchTeamMembers();
  }, [connectionAssignType]);

  const sortByName = (a, b) => {
    const nameA = a.FirstName.toUpperCase();
    const nameB = b.FirstName.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  };

  const getUsers = () => {
    if (!query) return users.sort(sortByName);

    return users
      .filter((user) =>
        `${user.FirstName} ${user.LastName}`.toLowerCase().includes(query.toLowerCase()),
      )
      .sort(sortByName);
  };

  const onChangeConnections = (e, val) => setConnections(val);

  const onModalClose = () => {
    const body = { open: false };
    onClose(body);
    if (!isNewlyInvited) setOpenConfirmModal(true);
  };

  const refreshBillingScreenData = () => {
    if (agentRole === TEAM_OWNER && activeSubscriptionData?.isSubscriptionActive) {
      dispatch(requestGetInvoiceDataOverviewEffect(() => {}, { silent: true }));
      dispatch(userGetDataOnBackgroundEffect({}, { showError: false }));
      dispatch(requestActiveSubscriptionTeamDataEffect({}, { showError: false, silent: true }));
    }
  };

  const configureAdminAccess = () => {
    if (connections?.length) {
      setLoading(true);
      dispatch(
        postConfigureAdminAccessEffect(
          { memberIds: connections },
          { agentId: assignToUser?.data?.Id },
          (err) => {
            if (!err) {
              showSuccessMessage('Admin access configured.');
              setConnections([]);
              refreshBillingScreenData();
              onModalClose();
            }
            setLoading(false);
          },
        ),
      );
    } else {
      setConnections([]);
      refreshBillingScreenData();
      onModalClose();
    }
  };

  const assignClientsToMember = () => {
    if (connections?.length) {
      setLoading(true);
      dispatch(
        postAssignClientsToMemberEffect(
          { clientIds: connections },
          { agentId: assignToUser?.data?.Id },
          (err) => {
            if (!err) {
              showSuccessMessage('Clients assigned successfully.');
              onClose();
            }
            setLoading(false);
          },
        ),
      );
    } else {
      onClose();
    }
  };

  const reassignShareClientWithMembers = () => {
    if (connections?.length) {
      setLoading(true);
      dispatch(
        reassignShareClientsEffect(
          { clientIds: [clientId], agentIds: connections },
          { type: connections.findIndex((Id) => Id === userId) === -1 ? 'reassign' : 'share' },
          (err) => {
            if (!err) {
              showSuccessMessage('Client assigned successfully');
              onClose();
            }
            setLoading(false);
          },
        ),
      );
    } else {
      onClose();
    }
  };

  const onSubmit = () => {
    if (connectionAssignType === AGENT) {
      if (connectionType === AGENT_CONNECTIONS.Clients) {
        reassignShareClientWithMembers();
      } else {
        configureAdminAccess();
      }
    } else {
      assignClientsToMember();
    }
  };

  if (connectionAssignType === CLIENT && closeOnClientEmpty && !users?.length) return <></>;

  return (
    <>
      <Modal
        width={675}
        data-testid="assign-connection-modal"
        className={styles.assignConnectionModal}
        open={showAssignConnection}
        footer={null}
        onCancel={onModalClose}
        {...props}
      >
        <>
          <LocalHeader />
          <Search query={query} setQuery={setQuery} />
          <ConnectionsList
            users={getUsers()}
            connections={connections}
            onChange={onChangeConnections}
            connectionAssignType={connectionAssignType}
          />
          <Button
            variant="secondary"
            className={styles.submitBtn}
            onClick={onSubmit}
            disabled={(disableOnEmpty && !connections?.length) || loading ? true : false}
          >
            Done
          </Button>
        </>
      </Modal>
      <ConfirmationDialog
        onReject={onClose}
        onConfirm={onClose}
        isOpen={openConfirmModal}
        confirmText="Done"
        hideRejectBtn={true}
      >
        <div className={styles.agentRoleConfirmText}>
          <h4>{assignToUser?.data?.FirstName} is now an admin.</h4>
          <p>You can use Symphony to adjust role and/or access level, if needed.</p>
        </div>
      </ConfirmationDialog>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, SimpleModal } from 'components';

import styles from './styles.module.scss';

const ConfirmationDialog = (props) => {
  const { children, confirmText, rejectText, isPending } = props;
  const { isOpen, onConfirm, onReject, hideRejectBtn } = props;
  const { className, buttonClassName, unsetOverflow, innerHolderClassName } = props;

  return (
    <SimpleModal
      isOpen={isOpen}
      className={className}
      contentClassName={classNames(styles.modalContent, { [styles.unsetOverflow]: unsetOverflow })}
      innerHolderClassName={classNames(
        styles.modalInnerHolder,
        {
          [styles.unsetOverflow]: unsetOverflow,
        },
        innerHolderClassName,
      )}
      testid="confirmation_modal"
    >
      {children}
      <div className={classNames(styles.actions, { [styles.centered]: hideRejectBtn })}>
        {!hideRejectBtn ? (
          <Button
            title={rejectText}
            className={classNames(styles.button, styles.rejectButton, buttonClassName.reject)}
            onClick={onReject}
            testid="reject"
            disabled={isPending}
            titleClassName={styles.title}
          />
        ) : null}
        <Button
          title={confirmText}
          className={classNames(styles.button, styles.confirmButton, buttonClassName.confirm)}
          onClick={onConfirm}
          testid="confirm"
          isPending={isPending}
          titleClassName={styles.title}
        />
      </div>
    </SimpleModal>
  );
};

ConfirmationDialog.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  rejectText: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.shape({
    confirm: PropTypes.string,
    reject: PropTypes.string,
  }),
  hideRejectBtn: PropTypes.bool,
  unsetOverflow: PropTypes.bool,
  innerHolderClassName: PropTypes.string,
};
ConfirmationDialog.defaultProps = {
  confirmText: 'Yes',
  rejectText: 'Cancel',
  className: '',
  children: null,
  isPending: false,
  buttonClassName: {
    confirm: '',
    reject: '',
  },
  hideRejectBtn: false,
  unsetOverflow: false,
  innerHolderClassName: '',
};

export default ConfirmationDialog;

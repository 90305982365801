import { DYNAMIC_QUESTION_TYPE } from 'app-constants';
import { v4 as uuidv4 } from 'uuid';

export const prepareQuestions = (questions) => {
  let formQuestions = Object.keys(questions)
    .map((page) => {
      return Object.keys(questions?.[page] || {}).map((role) => {
        const userQuestions = questions[page][role];

        if (userQuestions?.length) {
          return userQuestions.map((question) => ({
            ...question,
            pageNumber: parseInt(page),
            roleName: role,
          }));
        }
      });
    })
    .flat()
    .flat() // second flat need to make badly structured data into 1d
    .filter((el) => el)
    .map((question) => {
      if (
        (question.isUpdated && question.id) ||
        !question.id ||
        question.type === DYNAMIC_QUESTION_TYPE.StrikeThrough
      ) {
        return question;
      }
    })
    .filter((el) => el);

  const strikeThroughQuestions = formQuestions.filter(
    ({ type }) => type === DYNAMIC_QUESTION_TYPE.StrikeThrough,
  );

  const otherQuestions = formQuestions.filter(
    ({ type }) => type !== DYNAMIC_QUESTION_TYPE.StrikeThrough,
  );

  return { questions: otherQuestions, strikeThroughQuestions };
};

export const modifyQuestionsForTemplate = (questions, formRoles) => {
  return questions.map(({ UUID, roleName, ...restItem }) => ({
    ...restItem,
    UUID: uuidv4(),
    roleName: formRoles.find(({ roleId }) => roleId === roleName)?.formRole?.toString(),
  }));
};

export const flatQuestions = (questions) => {
  let formQuestions = Object.keys(questions)
    .map((page) => {
      return Object.keys(questions?.[page] || {}).map((role) => {
        const userQuestions = questions[page][role];

        if (userQuestions?.length) {
          return userQuestions.map((question) => ({
            ...question,
            pageNumber: parseInt(page),
            roleName: role,
          }));
        }
      });
    })
    .flat()
    .flat() // second flat need to make badly structured data into 1d
    .filter((el) => el)
    .map((question) => {
      if (question.type !== DYNAMIC_QUESTION_TYPE.StrikeThrough) {
        return question;
      }
    })
    .filter((el) => el);

  return { questions: formQuestions };
};

export const getAllMergedQuestions = (documents) => {
  let allQuestions: any[] = [];
  documents.forEach(({ activeQuestions, otherSignatoriesQuestions }) => {
    allQuestions = [...allQuestions, ...activeQuestions, ...otherSignatoriesQuestions];
  });

  return allQuestions;
};

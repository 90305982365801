import { FC, ReactElement, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { Icons, IconVariant } from '../../../icons';
import PhotosView from 'pages/Properties/ListingDetail/components/PhotosView';
import { Modal } from 'components-antd';

const useImageGrid = (images: string[]) => {
  const displayedImages = useMemo(() => images.slice(0, 5), [images]);

  const [rows, columns] = useMemo(
    () =>
      ({
        [1]: ['100%', '100%'],
        [2]: ['100%', '50% 50%'],
        [3]: ['calc(50% - 2px) calc(50% - 2px)', 'calc(50% - 2px) calc(50% - 2px)'],
        [4]: ['50% 50%', '50% 50%'],
        [5]: ['50% 50%', '50% 25% 25%'],
      }[displayedImages.length] ?? ['100%', '100%']),
    [displayedImages],
  );

  const getGridRow = useCallback(
    (index: number, imageCount: number) =>
      ({
        [1]: '1 / 2',
        [2]: '1 / 2',
        [3]: index === 0 ? '1 / 3' : index === 1 ? '1 / 2' : '2 / 3',
        [4]: index < 2 ? '1 / 2' : '2 / 3',
        [5]: index === 0 ? '1 / span 2' : index < 3 ? '1 / 2' : '2 / 3',
      }[imageCount]),
    [],
  );

  const getGridColumn = useCallback(
    (index: number, imageCount: number) =>
      ({
        [1]: '1 / 2',
        [2]: index === 0 ? '1 / 2' : '2 / 3',
        [3]: index === 0 ? '1 / 2' : '2 / 3',
        [4]: index % 2 === 0 ? '1 / 2' : '2 / 3',
        [5]: index === 0 ? '1 / 2' : index === 1 || index === 3 ? '2 / 3' : '3 / 4',
      }[imageCount]),
    [],
  );

  const getRoundedClass = useCallback(
    (index, imageCount) =>
      classnames({
        [styles.rounded]: imageCount === 1,
        [styles.roundedLeft]:
          (imageCount === 2 && index === 0) ||
          (imageCount === 3 && index === 0) ||
          (imageCount === 5 && index === 0),
        [styles.roundedRight]: imageCount === 2 && index === 1,
        [styles.roundedTopLeft]: imageCount === 4 && index === 0,
        [styles.roundedTopRight]:
          (imageCount === 3 && index === 1) ||
          (imageCount === 4 && index === 1) ||
          (imageCount === 5 && index === 2),
        [styles.roundedBottomLeft]: imageCount === 4 && index === 2,
        [styles.roundedBottomRight]:
          (imageCount === 3 && index === 2) ||
          (imageCount === 4 && index === 3) ||
          (imageCount === 5 && index === 4),
      }),
    [],
  );

  return {
    displayedImages,
    rows,
    columns,
    getGridRow,
    getGridColumn,
    getRoundedClass,
  };
};

export interface ImageGridProps {
  images: string[];
  address: string;
  price: string;
}

export const ImageGrid: FC<ImageGridProps> = (props: ImageGridProps): ReactElement => {
  const { images, address, price } = props;

  const { displayedImages, rows, columns, getGridRow, getGridColumn, getRoundedClass } =
    useImageGrid(images);

  const [showMore, setShowMore] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState(0);

  return (
    <div
      className={styles.imageGrid}
      style={{
        gridTemplateRows: rows,
        gridTemplateColumns: columns,
      }}
    >
      {displayedImages.map((imgSrc, index) => (
        <div
          key={index}
          className={classnames(styles.gridItem, getRoundedClass(index, displayedImages.length))}
          style={{
            gridRow: getGridRow(index, displayedImages.length),
            gridColumn: getGridColumn(index, displayedImages.length),
          }}
          onClick={(e) => {
            e.stopPropagation();
            setShowMore(true);
            setSelectedImage(index);
          }}
        >
          <img className={styles.image} src={imgSrc} alt={`image-${index}`} />
        </div>
      ))}

      <div
        className={styles.showMore}
        onClick={(e) => {
          e.stopPropagation();
          setShowMore(true);
          setSelectedImage(0);
        }}
      >
        <Icons variant={IconVariant.GALLERY} />
        <span className={styles.length}>{images.length}</span>
      </div>
      <Modal
        open={showMore}
        width={'100vw'}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          setShowMore(false);
        }}
        maskClosable={false}
        destroyOnClose={true}
        title={
          <p>
            {address}
            <span className={styles.separator}>•</span>
            <span className={styles.price}>{price}</span>
          </p>
        }
        className={styles.modal}
      >
        <PhotosView
          disableScheduleTour={true}
          imageId={selectedImage}
          photos={images.map((image) => image)}
        />
      </Modal>
    </div>
  );
};

import { Avatar } from 'components';
import styles from './styles.module.scss';
import { getInitials } from 'utils';
import { TransactionCreationAccess } from './TransactionCreationAccess';
import { InfoTooltip } from '../InfoTooltip';
import { Icons } from 'pages/Settings/SettingPermissions/Icons';
import { PermissionTypeDropdown } from './PermissionTypeDropdown';
import { MemberTypes } from 'app-constants/enums/permissionSettings';

const renderTooltipTitle = (title, text) => {
  return (
    <div className={styles.columnInfoTooltipWrapper}>
      <div className={styles.columnTitle}>{title}</div>
      <InfoTooltip text={text} className={styles.columnTooltip}>
        <Icons variant={'infoIcon'} />
      </InfoTooltip>
    </div>
  );
};

export const teamTableColumns = (tableColumnProps) => {
  const {
    memberType,
    transactionRoles,
    setExpandedRowKeys,
    setPermissionDetails,
    permissionUpdatedDeails,
  } = tableColumnProps;

  const isAdmin = memberType === MemberTypes.Admins;

  return [
    {
      key: 'Name',
      title: isAdmin ? 'Admin' : 'Member',
      dataIndex: '',
      width: isAdmin ? '45%' : '75%',
      render: (name: string, record) => (
        <div className={styles.memberInformation}>
          <Avatar
            avatarClassName={styles.memberAvatar}
            src={record.AvatarUrl || null}
            placeholder={!record.AvatarUrl ? getInitials(record.Name) : ''}
          />

          <div className={styles.memberNameWrapper}>
            <div>
              <div className={styles.memberName}>{record.Name}</div>
              {record.TransactionRole && (
                <div className={styles.memberTitle}>
                  {
                    transactionRoles.find(({ Id }) => Id === record.TransactionRole.Id)
                      ?.DisplayTitle
                  }
                </div>
              )}
            </div>

            {record.isOwner && <div className={styles.ownerMember}>Owner</div>}
          </div>
        </div>
      ),
    },
    {
      key: 'TransactionCreation',
      title: renderTooltipTitle(
        'Transaction Creation',
        'Enabling transaction creation allows users to add and manage new transactions.',
      ),
      dataIndex: '',
      width: '25%',
      render: (uploadedBy, record) => <TransactionCreationAccess {...record} />,
    },
    ...(isAdmin
      ? [
          {
            key: 'AccessPermissions',
            title: renderTooltipTitle(
              'Access Permissions',
              'Configure access permissions to restrict users to only the data and resources they are authorized to access. This ensures data security, maintains privacy, and aligns with organizational compliance standards.',
            ),
            dataIndex: '',
            width: '35%',
            render: (createdDate: string, record) => (
              <PermissionTypeDropdown
                {...record}
                permissionType="ViewOnly"
                setExpandedRowKeys={setExpandedRowKeys}
                permissionDetails={permissionUpdatedDeails}
                setPermissionDetails={setPermissionDetails}
              />
            ),
          },
        ]
      : []),
  ];
};

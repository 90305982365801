import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Col, Row, Badge } from 'components-antd';
import { useDispatch, useSelector } from 'react-redux';

import { AuditLogItemConfig } from '../helper';
import { Role as UserRole } from 'app-constants';
import { getUserSelector } from 'store/selectors/user';
import { getClientDetailsEffect } from 'store/effects/clientDetail';
import { setDrawerClientProfileId, setProfileExpandedSection } from 'store/effects';

import styles from './styles.module.scss';

interface IAuditLogItemContent {
  itemContent?: any;
  IsQuoteDrawer?: boolean;
  className?: string;
  expanded?: boolean;
  setExpanded?: (value: boolean) => void;
  expandableWrapperClass?: string;
  showActivityTag?: boolean;
  taskCategories?: any[];
  // setExpanded: (value: boolean) => void;
}
export const AuditLogItemContent: React.FC<IAuditLogItemContent> = ({
  itemContent,
  IsQuoteDrawer,
  className,
  expanded,
  showActivityTag,
  taskCategories,
  setExpanded,
  expandableWrapperClass,
}) => {
  const dispatch = useDispatch();
  const [defaultExpanded, setDefaultExpanded] = useState(false);

  const { ActionType, TagLine, UserId, Role, transactionCount, showAddress } = itemContent;
  if (TagLine) {
    TagLine.transactionCount = transactionCount;
  }

  const {
    data: { Id: currentUserId },
  } = useSelector(getUserSelector);

  const handleProfileView = (userId: number, role: UserRole) => {
    if (role === UserRole.Client) {
      dispatch(getClientDetailsEffect({ id: userId }));
      dispatch(setDrawerClientProfileId({ id: userId }));
      dispatch(setProfileExpandedSection(true));
    }
  };

  const isExpandable = useMemo(() => {
    const config = AuditLogItemConfig?.[ActionType];
    return typeof config?.isExpandable === 'function'
      ? config.isExpandable(TagLine)
      : !!config?.isExpandable;
  }, [AuditLogItemConfig?.[ActionType]?.isExpandable, TagLine]);

  const expandedItem = setExpanded ? expanded : defaultExpanded;

  return (
    <Row>
      <Col
        xs={24}
        className={classNames([
          !expandedItem && styles.close,
          expandedItem && styles.open,
          styles.box,
          className,
        ])}
      >
        <div className={styles.timelineContent}>
          <div className={classNames([styles.text])}>
            {AuditLogItemConfig[ActionType] &&
              AuditLogItemConfig[ActionType]?.TaglineMarkup({
                props: {
                  ...TagLine,
                  UserId,
                  Role,
                  transactionCount: transactionCount,
                  showAddress,
                },
                IsQuoteDrawer,
                currentUserId,
                handleProfileView,
              })}
            {AuditLogItemConfig[ActionType]?.isExpandable && expandedItem && (
              <div className={classNames([styles.expandableWrapper], expandableWrapperClass)}>
                {AuditLogItemConfig[ActionType]?.ExpandedMarkup(TagLine)}
              </div>
            )}
          </div>

          {showActivityTag &&
            itemContent?.Payload?.CategoryId &&
            !itemContent?.Payload.isGroupedSummary && (
              <div className={styles.tagColumn}>
                {/* <Badge className={styles.tag}></Badge> */}
                <Badge className={styles.tag_without_round}>
                  {
                    taskCategories?.filter((x) => x.Id === itemContent?.Payload?.CategoryId)?.[0]
                      ?.Name
                  }
                </Badge>
              </div>
            )}
        </div>
      </Col>

      {isExpandable ? (
        !expanded && !defaultExpanded ? (
          <Col
            className={classNames([styles.showHideBtn])}
            onClick={() => {
              setExpanded ? setExpanded(!expanded) : setDefaultExpanded(!defaultExpanded);
            }}
          >
            <Col> + Show Details</Col>
          </Col>
        ) : (
          <Col
            className={classNames([styles.showHideBtn])}
            onClick={() => {
              setExpanded ? setExpanded(!expanded) : setDefaultExpanded(!defaultExpanded);
            }}
          >
            <Col> - Hide Details</Col>
          </Col>
        )
      ) : (
        <></>
      )}
    </Row>
  );
};

import { SVGProps } from 'react';

export const CheckBox = ({ stroke = '#ff576d', fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.75"
      y="0.75"
      width="22.5"
      height="22.5"
      rx="3.25"
      fill={fill || stroke}
      stroke={stroke}
      strokeWidth="1.5"
    />
    <path
      d="M7.33252 11.9999L10.6659 15.3332L17.3325 8.66656"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

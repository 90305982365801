import { Button, Input } from 'components';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { showSuccessMessage } from 'helpers';
import { requestEditUpdateAddressEffect } from 'store/effects/intel';

export const RadiusForm = ({ value, handleClose, addressId, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    radius: Yup.number().required(),
  });

  const { handleSubmit, handleChange, values, handleBlur } = useFormik({
    initialValues: { radius: value },
    validationSchema,
    onSubmit: async ({ radius }) => {
      setLoading(true);
      dispatch(
        requestEditUpdateAddressEffect(
          { Id: addressId, neighbourhoodRadius: Number(radius) },
          {},
          (err) => {
            if (!err) {
              onUpdate?.(values?.radius);
              showSuccessMessage('Radius updated successfully');
              setLoading(false);
              handleClose();
            }
          },
        ),
      );
    },
  });

  return (
    <form className={styles.main} onSubmit={handleSubmit}>
      <span className={styles.input}>
        <Input
          name={'radius'}
          isNumberFormat={true}
          minNumber={0.1}
          maxNumber={10}
          variant={Input.LIGHT_ROUND}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.radius}
          label="Neighborhood Activity Radius"
          placeholder="0"
        />
        <p className={styles.iconRight}>Miles</p>
      </span>
      <div className={styles.actionButtons}>
        <Button type="submit" title={'Update'} className={styles.update} isPending={loading} />
        <Button title={'Cancel'} className={styles.cancel} onClick={handleClose} />
      </div>
    </form>
  );
};

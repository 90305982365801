import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Checkbox, Avatar, Wrapper } from 'components';
import { convertNameToAvatarPlaceholder } from 'helpers';
import { getUserId } from 'store/selectors/user';
import { AGENT, TEAM_ADMIN, TEAM_OWNER } from 'settings/constants/roles';
import { getAgentTeamRoleSelector } from 'store/selectors/agentTeamDetail';

import styles from './styles.module.scss';

export const Connection = (props) => {
  const userId = useSelector(getUserId);
  const agentRole = useSelector(getAgentTeamRoleSelector);

  const {
    className,
    value,
    connections,
    onChange,
    avatarPlaceholder,
    label,
    isChecked,
    connectionAssignType,
  } = props;

  const name = `
    ${
      value?.Id === userId
        ? agentRole === TEAM_OWNER
          ? 'Me (Owner)'
          : 'Me'
        : `${value?.FirstName || ''} ${value?.LastName || ''}`
    }
  `;

  const isAgentAdmin = connectionAssignType === AGENT && value?.TeamRole === TEAM_ADMIN;
  return (
    <div testid="connection_item" className={classNames(styles.connection, className)}>
      <div className={styles.name}>
        {value?.FirstName && (
          <Avatar
            src={value?.AvatarUrl}
            placeholder={
              avatarPlaceholder || (
                <div className={styles.avatarPlaceholder}>
                  {convertNameToAvatarPlaceholder(`${value?.FirstName} ${value?.LastName}`)}
                </div>
              )
            }
            avatarClassName={styles.avatar}
          />
        )}
        <div testid="name" className={styles.name}>
          {label || name}
        </div>
        {isAgentAdmin && <div className={styles.tag}>Admin</div>}
      </div>
      <Checkbox
        onChange={onChange}
        checkmarkClassName={styles.checkbox}
        checked={isChecked || !connections || connections.includes(value?.Id)}
        testid="connection_checkbox"
      />
    </div>
  );
};

Connection.propTypes = {
  className: PropTypes.string,
  value: PropTypes.shape({
    Id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    AvatarUrl: PropTypes.string,
    Role: PropTypes.shape({
      Title: PropTypes.string,
    }),
  }),
  connections: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  avatarPlaceholder: PropTypes.node,
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  connectionAssignType: PropTypes.string,
};

Connection.defaultProps = {
  className: '',
  value: {},
  connections: undefined,
  onChange: () => {},
  avatarPlaceholder: undefined,
  label: undefined,
  isChecked: false,
  connectionAssignType: '',
};

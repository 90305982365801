import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getTransactionSelector } from 'store/selectors/transaction';
import { InputLabel } from 'components';
import { cloneDeep } from 'lodash-es';
import Reviewer from './Reviewer';
import { Lock, Users, World } from 'components/Icons';

import styles from './styles.module.scss';
import { getUserId } from 'store/selectors/user';
import WorldGlobe from 'components/Icons/WorldGlobe';
import { getFormattedTeamList } from 'store/selectors/teamList';

const DocumentReviewers = (props) => {
  const {
    label,
    value,
    ownerId,
    onChange,
    className,
    fullAccess,
    internalAccess,
    accessToEveryone,
    setEveryoneAccess,
    setInternalAccess,
    canManageInternalAccess,
  } = props;

  const { transaction } = useSelector(getTransactionSelector);
  const userId = useSelector(getUserId);
  const teamList = useSelector(getFormattedTeamList);
  const allParticipantsIds = (transaction?.Participants || []).map(
    (participant) => participant?.Id,
  );

  const onChangeHandler = (e, id, checked) => {
    e.stopPropagation();

    internalAccess && setInternalAccess(false);

    if (checked) {
      const newValue = [...(value || []), id];
      onChange(newValue);
    } else {
      if (!value) {
        const clonedAllParticipantsIds = cloneDeep(allParticipantsIds);
        const uncheckedParticipantIndex = clonedAllParticipantsIds.findIndex(
          (participantId) => participantId === id,
        );
        clonedAllParticipantsIds.splice(uncheckedParticipantIndex, 1);
        return onChange(clonedAllParticipantsIds);
      }

      const clonedValue = cloneDeep(value);
      const removedIndex = (value || []).findIndex((val) => val === id);

      if (removedIndex !== -1 && clonedValue) {
        clonedValue.splice(removedIndex, 1);
        onChange(clonedValue);
      }
    }
  };

  const saveDefaultUsers = (e) => {
    const defaultUsers = [userId, ...(ownerId !== userId ? [ownerId] : [])];
    onChange(defaultUsers);
  };

  const onEverybodyChange = (e, val, checked) => {
    setEveryoneAccess(checked);
    if (checked) {
      onChange(allParticipantsIds);
      canManageInternalAccess && setInternalAccess(true);
    } else {
      saveDefaultUsers(e);
      setInternalAccess(false);
    }
  };

  const onInternalChange = (e, val, checked) => {
    setInternalAccess(checked);

    if (checked) {
      const teamListIds = teamList.map(({ id }) => id);
      let teamMemberParticipants = teamListIds.filter((id) => allParticipantsIds.includes(id));

      teamMemberParticipants = [...new Set([...teamMemberParticipants, ownerId])];
      onChange(teamMemberParticipants);
    } else {
      saveDefaultUsers();
    }
  };

  return (
    <div className={classNames(styles.documentReviewers, className)}>
      <InputLabel className={styles.label} label={label} />

      <div className={styles.reviewersList}>
        <Reviewer
          reviewers={value}
          isChecked={accessToEveryone}
          onChange={onEverybodyChange}
          avatarPlaceholder={<WorldGlobe className={styles.userIcon} color="#fff" />}
          isRole={false}
          label="Everyone"
          nameClass={styles.everyoneReviewerName}
          subText={'All current and future participants in the transaction.'}
        />

        {canManageInternalAccess && (
          <Reviewer
            reviewers={value}
            isChecked={internalAccess}
            onChange={onInternalChange}
            avatarPlaceholder={
              <Users className={`${styles.userIcon} ${styles.internalUserIcon}`} color="#fff" />
            }
            label={<div>Internal</div>}
            isRole={false}
            disabled={accessToEveryone}
            subText={'All current and future team members in the transaction.'}
          />
        )}

        <Reviewer
          reviewers={value}
          isChecked={true}
          avatarPlaceholder={
            <Lock className={`${styles.userIcon} ${styles.lockIcon}`} stroke="#fff" />
          }
          label="Me"
          isRole={false}
          disabled={true}
        />

        {(transaction?.Participants || [])
          .filter(({ Id }) => Id !== userId)
          .map((participant) => (
            <Reviewer
              key={participant?.Id}
              reviewers={value}
              value={participant}
              disabled={accessToEveryone || (fullAccess && participant?.Id === ownerId)}
              isFileOwner={fullAccess && participant?.Id === ownerId}
              onChange={(e, val, checked) => onChangeHandler(e, participant?.Id, checked)}
            />
          ))}
      </div>
    </div>
  );
};

DocumentReviewers.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.number),
  accessToEveryone: PropTypes.bool,
  onChange: PropTypes.func,
  setEveryoneAccess: PropTypes.func,
  setInternalAccess: PropTypes.func,
  setJustMeAccess: PropTypes.func,
  fullAccess: PropTypes.bool,
  internalAccess: PropTypes.bool,
  canManageInternalAccess: PropTypes.bool,
  ownerId: PropTypes.number,
};

DocumentReviewers.defaultProps = {
  className: '',
  label: 'Who can access this document?',
  value: undefined,
  accessToEveryone: false,
  onChange: () => {},
  setEveryoneAccess: () => {},
  setJustMeAccess: () => {},
  setInternalAccess: () => {},
  fullAccess: false,
  internalAccess: false,
  canManageInternalAccess: false,
  ownerId: undefined,
};

export default DocumentReviewers;

export enum RadarPortfolioViewTypes {
  ViewByClient = 'ViewByClient',
  ViewByProperty = 'ViewByProperty',
  ViewByAgent = 'ViewByAgent',
}

export enum TeamViewTypes {
  TeamPortfolio = 'TeamPortfolio',
  MyPortfolio = 'MyPortfolio',
}

export enum NeighborhoodCategoryTypes {
  New = 'New',
  StatusChanges = 'Status Changes',
  PriceReductions = 'Price Reductions',
  PriceIncrease = 'Price Increase',
  BackOnMarket = 'Back on Market',
  WITHDRAWN = 'Withdrawn',
  UNDER_CONTRACT = 'Under Contract',
  EXPIRED = 'Expired',
  OpenHouses = 'Open Houses',
}

export enum NeighborhoodStatusTypes {
  Sold = 'Sold',
  ActiveUnderContract = 'ActiveUnderContract',
  ComingSoon = 'ComingSoon',
  AcceptingBackups = 'AcceptingBackups',
}

export enum NeighborhoodFilterTypes {
  All = 'All',
  NEW_LISTING = 'New Listing',
  PRICE_REDUCTION = 'Price Reduction',
  PRICE_INCREASE = 'Price Increase',
  BACK_ON_THE_MARKET = 'Back on the Market',
  WITHDRAWN = 'Withdrawn',
  UNDER_CONTRACT = 'Under Contract',
  EXPIRED = 'Expired',
  SOLD = 'Sold',
}

export enum NeighborhoodDayRangeTypes {
  Today = 'Today',
  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
}

export enum NeighborhoodViewTypes {
  List = 'List',
  Map = 'Map',
}

export type NeighborhoodFiltersChangeHandler = (params: {
  category?: NeighborhoodCategoryTypes[];
  activityStatus?: NeighborhoodStatusTypes[];
  dayRange?: number;
}) => void;

import { ConfirmationDialog } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDynamicFormSelector } from 'store/selectors/requestFormProcess';
import { updateDynamicFormEffect } from 'store/effects/formProcess';

import styles from './styles.module.scss';
import { dynamicManager } from '../../DynamicManager';

export const SignatoryFieldInformationModal = (props) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const { showSignConfirmation, missingFormRoles } = useSelector(getDynamicFormSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!showConfirmationDialog) {
      setShowConfirmationDialog(!!showSignConfirmation);
    }
  }, [showSignConfirmation]);

  const resetConfirmationRoles = () => {
    setTimeout(
      () =>
        dispatch(updateDynamicFormEffect({ missingFormRoles: [], showSignConfirmation: false })),
      200,
    );
  };

  const onClose = () => {
    setShowConfirmationDialog(false);
    resetConfirmationRoles();
  };

  const onConfirm = () => {
    resetConfirmationRoles();
    dynamicManager.handleVerifiedSignatoryContinue(props.setDisabled);
  };

  return (
    <ConfirmationDialog
      onReject={onClose}
      onConfirm={onConfirm}
      isOpen={showConfirmationDialog}
      confirmText="Continue"
      rejectText={'Go Back'}
      className={styles.signConfirmationDialog}
      innerHolderClassName={styles.signConfirmationInnerHolder}
    >
      <div className={styles.missingFormRolesModal}>
        <div className={styles.heading}> No fields are assigned to the following signer(s):</div>
        <div className={styles.list}>
          {missingFormRoles?.map((roleItem) => (
            <div key={roleItem.roleId} className={styles.listItem}>
              {roleItem.roleName}
            </div>
          ))}
        </div>

        <div className={styles.bottomText}>Do you still want to continue?</div>
      </div>
    </ConfirmationDialog>
  );
};

export enum ActivityLogsTypesValue {
  InviteAccepted = 'InviteAccepted',
  InviteRejected = 'InviteRejected',
  CriteriaWizardCompleted = 'CriteriaWizardCompleted',
  CriteriaWizardSkipped = 'CriteriaWizardSkipped',
  PreApprovalRequested = 'PreApprovalRequested',
  PreApprovalUploaded = 'PreApprovalUploaded',
  NewSearchInstanceCreated = 'NewSearchInstanceCreated',
  SearchInstanceRenamed = 'SearchInstanceRenamed',
  SearchInstanceDeactivated = 'SearchInstanceDeactivated',
  SearchInstanceActivated = 'SearchInstanceActivated',
  FreeSearchTriggered = 'FreeSearchTriggered',
  SearchInstanceCriteriaEdited = 'SearchInstanceCriteriaEdited',
  PropertyAddedToFavorites = 'PropertyAddedToFavorites',
  PropertyRemovedFromFavorites = 'PropertyRemovedFromFavorites',
  PropertyViewed = 'PropertyViewed',
  TourRequested = 'TourRequested',
  TransactionStatusUpdated = 'TransactionStatusUpdated',
  TransactionParticipantInvited = 'TransactionParticipantInvited',
  TransactionParticipantRemoved = 'TransactionParticipantRemoved',
  NewTask = 'NewTask',
  NewTaskSummary = 'NewTaskSummary',
  TaskAssigned = 'TaskAssigned',
  TaskAssignedSummary = 'TaskAssignedSummary',
  TaskCompleted = 'TaskCompleted',
  TaskCompletedSummary = 'TaskCompletedSummary',
  TaskUpcoming = 'TaskUpcoming',
  TaskOverdue = 'TaskOverdue',
  TaskOverdueSummary = 'TaskOverdueSummary',
  TaskTagged = 'TaskTagged',
  TaskStuck = 'TaskStuck',
  TaskStuckSummary = 'TaskStuckSummary',
  UserTaggedInTask = 'UserTaggedInTask',
  MilestoneAdded = 'MilestoneAdded',
  MilestoneAddedSummary = 'MilestoneAddedSummary',
  MilestoneEdited = 'MilestoneEdited',
  MilestoneCompleted = 'MilestoneCompleted',
  MilestoneMissed = 'MilestoneMissed',
  MilestoneMissedSummary = 'MilestoneMissedSummary',
  MilestoneOverdue = 'MilestoneOverdue',
  MilestoneOverdueSummary = 'MilestoneOverdueSummary',
  MilestoneDeadlineUpcoming = 'MilestoneDeadlineUpcoming',
  MilestoneDeadlineUpcomingSummary = 'MilestoneDeadlineUpcomingSummary',
  FormAssigned = 'FormAssigned',
  FormCompleted = 'FormCompleted',
  MessageReceived = 'MessageReceived',
  DocumentUploadedInTransaction = 'DocumentUploadedInTransaction',
  UserReceivedNotification = 'UserReceivedNotification',
  ShowingAppointmentRequested = 'ShowingAppointmentRequested',
  ShowingAppointmentCancelled = 'ShowingAppointmentCancelled',
  ShowingAppointmentRescheduled = 'ShowingAppointmentRescheduled',
  ShowingAppointmentApproved = 'ShowingAppointmentApproved',
  ShowingAppointmentFeedback = 'ShowingAppointmentFeedback',
  ShowingAppointmentLapsed = 'ShowingAppointmentLapsed',
  ShowingAppointmentApprovalApproved = 'ShowingAppointmentApprovalApproved',
  ShowingAppointmentApprovalDeclined = 'ShowingAppointmentApprovalDeclined',
  ShowingAppointmentApprovalCancelled = 'ShowingAppointmentApprovalCancelled',
  ShowingLinkCreated = 'ShowingLinkCreated',
  ShowingLinkEnabled = 'ShowingLinkEnabled',
  ShowingLinkDisabled = 'ShowingLinkDisabled',
  ShowingLinkUpdated = 'ShowingLinkUpdated',
  ShowingAnnouncement = 'ShowingAnnouncement',
  NewAdHocSearch = 'NewAdHocSearch',
  QuoteRequested = 'QuoteRequested',
  QuoteResponseReceived = 'QuoteResponseReceived',
  QuoteResponseNewAttachment = 'QuoteResponseNewAttachment',
  PropertyDetailsViewTimeSpent = 'PropertyDetailsViewTimeSpent',
  PropertyComment = 'PropertyComment',
  OfferLinkCreated = 'OfferLinkCreated',
  OfferLinkEnabled = 'OfferLinkEnabled',
  OfferLinkDisabled = 'OfferLinkDisabled',
  OfferLinkUpdated = 'OfferLinkUpdated',
  OfferSubmitted = 'OfferSubmitted',
  OfferCounterSent = 'OfferCounterSent',
  OfferAccepted = 'OfferAccepted',
  OfferRejected = 'OfferRejected',
  OfferWithdrawn = 'OfferWithdrawn',
  OfferUpdated = 'OfferUpdated',
  OfferCounterUpdated = 'OfferCounterUpdated',
  OfferExpired = 'OfferExpired',
  CounterWithdrawn = 'CounterWithdrawn',
  OfferAnnouncement = 'OfferAnnouncement',
  CounterExpired = 'CounterExpired',
  CounterRejected = 'CounterRejected',
  CounterAccepted = 'CounterAccepted',
  CollaborateDocument = 'CollaborateDocument',
  DocumentReadyForSignature = 'DocumentReadyForSignature',
  FormDocumentSignedByEveryone = 'FormDocumentSignedByEveryone',
  FormSigned = 'FormSigned',
  DetailAdded = 'DetailAdded',
  DetailChanged = 'DetailChanged',
  ContactAdded = 'ContactAdded',
  ContactChanged = 'ContactChanged',
  ContactDeleted = 'ContactDeleted',
  TaskUpcomingSummary = 'TaskUpcomingSummary',
  TransactionTemplateApplied = 'TransactionTemplateApplied',
  TaskDeleted = 'TaskDeleted',
  TaskDeletedSummary = 'TaskDeletedSummary',
  DocumentUpdated = 'DocumentUpdated',
  TransactionCanceled = 'TransactionCanceled',
  TransactionActiveListing = 'TransactionActiveListing',
  TransactionPriceHistory = 'TransactionPriceHistory',
  TransactionNote = 'TransactionNote',
  TaskComment = 'TaskComment',
  TaskStatusUpdated = 'TaskStatusUpdated',
  TourCancelled = 'TourCancelled',
  KitClicked = 'KitClicked',
  KitOpened = 'KitOpened',
  KitReplied = 'KitReplied',
}
export enum ActivityGroupingTypes {
  TaskUpcoming = 'TaskUpcoming',
  NewTask = 'NewTask',
  TaskAssigned = 'TaskAssigned',
  TaskOverdue = 'TaskOverdue',
  TaskCompleted = 'TaskCompleted',
  MilestoneAdded = 'MilestoneAdded',
  TaskStuck = 'TaskStuck',
  MilestoneMissed = 'MilestoneMissed',
  MilestoneOverdue = 'MilestoneOverdue',
  MilestoneDeadlineUpcoming = 'MilestoneDeadlineUpcoming',
  TaskDeleted = 'TaskDeleted',
  TaskStatusUpdated = 'TaskStatusUpdated',
}

export enum ActivityLogsTypesValueLabel {
  InviteAccepted = 'Invite Accepted',
  InviteRejected = 'Invite Rejected',
  CriteriaWizardCompleted = 'Criteria Wizard Completed',
  CriteriaWizardSkipped = 'Criteria Wizard Skipped',
  PreApprovalRequested = 'Pre Approval Requested',
  PreApprovalUploaded = 'Pre Approval Uploaded',
  NewSearchInstanceCreated = 'New Search Instance Created',
  SearchInstanceRenamed = 'Search Instance Renamed',
  SearchInstanceDeactivated = 'Search Instance Deactivated',
  SearchInstanceActivated = 'Search Instance Activated',
  FreeSearchTriggered = 'Free Search Triggered',
  SearchInstanceCriteriaEdited = 'Search Instance Criteria Edited',
  PropertyAddedToFavorites = 'Property Added To Favorites',
  PropertyRemovedFromFavorites = 'Property Removed From Favorites',
  PropertyViewed = 'Property Viewed',
  TourRequested = 'Tour Requested',
  TransactionStatusUpdated = 'Transaction Status Updated',
  TransactionParticipantInvited = 'Transaction Participant Invited',
  TransactionParticipantRemoved = 'Transaction Participant Removed',
  NewTask = 'New Task',
  NewTaskSummary = 'New Task Summary',
  TaskAssigned = 'Task Assigned',
  TaskAssignedSummary = 'Task Assigned Summary',
  TaskCompleted = 'Task Completed',
  TaskCompletedSummary = 'Task Completed Summary',
  TaskUpcoming = 'Task Upcoming',
  TaskOverdue = 'Task Overdue',
  TaskOverdueSummary = 'Task Overdue Summary',
  TaskTagged = 'Task Tagged',
  TaskStuck = 'Task Stuck',
  TaskStuckSummary = 'Task Stuck Summary',
  UserTaggedInTask = 'User Tagged In Task',
  MilestoneAdded = 'Milestone Added',
  MilestoneAddedSummary = 'Milestone Added Summary',
  MilestoneEdited = 'Milestone Edited',
  MilestoneCompleted = 'Milestone Completed',
  MilestoneMissed = 'Milestone Missed',
  MilestoneMissedSummary = 'Milestone Missed Summary',
  MilestoneOverdue = 'Milestone Overdue',
  MilestoneOverdueSummary = 'Milestone Overdue Summary',
  MilestoneDeadlineUpcoming = 'Milestone Deadline Upcoming',
  MilestoneDeadlineUpcomingSummary = 'Milestone Deadline Upcoming Summary',
  FormAssigned = 'Form Assigned',
  FormCompleted = 'Form Completed',
  MessageReceived = 'Message Received',
  DocumentUploadedInTransaction = 'Document Uploaded In Transaction',
  UserReceivedNotification = 'User Received Notification',
  ShowingAppointmentRequested = 'Showing Appointment Requested',
  ShowingAppointmentCancelled = 'Showing Appointment Cancelled',
  ShowingAppointmentRescheduled = 'Showing Appointment Rescheduled',
  ShowingAppointmentApproved = 'Showing Appointment Approved',
  ShowingAppointmentFeedback = 'Showing Appointment Feedback',
  ShowingAppointmentLapsed = 'Showing Appointment Lapsed',
  ShowingAppointmentApprovalApproved = 'Showing Appointment Approval Approved',
  ShowingAppointmentApprovalDeclined = 'Showing Appointment Approval Declined',
  ShowingAppointmentApprovalCancelled = 'Showing Appointment Approval Cancelled',
  ShowingLinkCreated = 'Showing Link Created',
  ShowingLinkEnabled = 'Showing Link Enabled',
  ShowingLinkDisabled = 'Showing Link Disabled',
  ShowingLinkUpdated = 'Showing Link Updated',
  ShowingAnnouncement = 'Showing Announcement',
  NewAdHocSearch = 'New AdHoc Search',
  QuoteRequested = 'Quote Requested',
  QuoteResponseReceived = 'Quote Response Received',
  QuoteResponseNewAttachment = 'Quote Response New Attachment',
  PropertyDetailsViewTimeSpent = 'Property Details View TimeSpent',
  PropertyComment = 'Property Comment',
  OfferLinkCreated = 'Offer Link Created',
  OfferLinkEnabled = 'Offer Link Enabled',
  OfferLinkDisabled = 'Offer Link Disabled',
  OfferLinkUpdated = 'Offer Link Updated',
  OfferSubmitted = 'Offer Submitted',
  OfferCounterSent = 'Offer Counter Sent',
  OfferAccepted = 'Offer Accepted',
  OfferRejected = 'Offer Rejected',
  OfferWithdrawn = 'Offer Withdrawn',
  OfferUpdated = 'Offer Updated',
  OfferCounterUpdated = 'Offer Counter Updated',
  OfferExpired = 'Offer Expired',
  CounterWithdrawn = 'Counter Withdrawn',
  OfferAnnouncement = 'Offer Announcement',
  CounterExpired = 'Counter Expired',
  CounterRejected = 'Counter Rejected',
  CounterAccepted = 'Counter Accepted',
  CollaborateDocument = 'Collaborate Document',
  DocumentReadyForSignature = 'Document Ready For Signature',
  FormDocumentSignedByEveryone = 'Form Document Signed By Everyone',
  FormSigned = 'Form Signed',
  DetailAdded = 'Detail Added',
  DetailChanged = 'Detail Changed',
  ContactAdded = 'Contact Added',
  ContactChanged = 'Contact Changed',
  ContactDeleted = 'Contact Deleted',
  TaskUpcomingSummary = 'Task Upcoming Summary',
  TransactionTemplateApplied = 'Transaction Template Applied',
  TaskDeleted = 'Task Deleted',
  TaskDeletedSummary = 'Task Deleted Summary',
  DocumentUpdated = 'Document Updated',
  TransactionCanceled = 'Transaction Canceled',
  TransactionActiveListing = 'Transaction Active Listing',
  TransactionPriceHistory = 'Transaction Price History',
  TransactionNote = 'Transaction Notes',
  TaskComment = 'Task Comment',
  TaskStatusUpdated = 'Task Status Updated',
}

import Slider from 'react-slick';
import classNames from 'classnames';
import { PropertyCard } from '../PropertyCard';
import styles from './styles.module.scss';
import { ArrowLeft } from 'components/Icons';
import { useHistory } from 'react-router-dom';
import { PropertySliderProps } from './types';
import { PropertySliderSkeleton } from './PropertySliderSkeleton';
import { useMemo } from 'react';

const CarouselNext = (props) => {
  const { onClick, className } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeft className={styles.icon} />
    </div>
  );
};

const CarouselPrev = (props) => {
  const { onClick, className } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowLeft className={styles.icon} />
    </div>
  );
};

const settings = {
  draggable: true,
  accessibility: false,
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2.7,
  slidesToScroll: 2,
  nextArrow: <CarouselNext className={styles.arrows} />,
  prevArrow: <CarouselPrev className={styles.arrows} />,
  responsive: [
    {
      breakpoint: 1880,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 1525,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 1165,
      settings: {
        slidesToShow: 2.8,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 790,
      settings: {
        slidesToShow: 2.5,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 380,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const PropertySlider = ({
  properties,
  title,
  viewAllRoute,
  viewAllRouteHandler,
  loading,
  count,
}: PropertySliderProps) => {
  const history = useHistory();
  const isVisible = useMemo(
    () => (loading ? true : !!properties?.length),
    [loading, properties?.length],
  );
  return isVisible ? (
    <div className={classNames(styles.mainWrapper)}>
      <div className={classNames(styles.headerWrapper)}>
        <div className={classNames(styles.cardRightGradient)}></div>
        <div className={classNames(styles.titleWrapper)}>
          <div className={classNames(styles.title)}>{title}</div>
          <div className={classNames(styles.count)}>({count || properties?.length})</div>
        </div>
        <div className={classNames(styles.link)}>
          <a
            onClick={() => {
              if (viewAllRouteHandler) {
                viewAllRouteHandler();
              }
              if (viewAllRoute) {
                history.push(viewAllRoute);
              }
            }}
          >
            View More
          </a>
        </div>
      </div>
      <div className={classNames(styles.sliderContainer, styles.scrollable)}>
        {loading ? (
          <Slider {...settings} className={classNames(styles.subfeedSlider)}>
            {[...Array(6)].map((_, index) => (
              <PropertySliderSkeleton key={index} />
            ))}
          </Slider>
        ) : properties?.length ? (
          <Slider {...settings} className={classNames(styles.subfeedSlider)}>
            {properties.map((property) => (
              <PropertyCard
                key={property.id}
                id={property.id}
                image={property.image}
                address={property.address}
                subAddress={property.subAddress}
                matchPercentage={property.matchPercentage}
                agentHighlights={property.agentHighlights}
                openHouses={property.openHouses}
                priceReduction={property.priceReduction}
                commentsCount={property.commentsCount}
                matchedCriteria={property.MatchedCriterias}
                backOnMarket={property.backOnMarket}
                instanceId={property.searchInstanceId}
              />
            ))}
          </Slider>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PropertySlider;

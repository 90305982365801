import React from 'react';
import { Select, Typography, Space } from 'antd';
import classNames from 'classnames';

import { Row } from 'components-antd/Grid';
import { Icons } from './Icons';

import styles from './styles.module.scss';

const { Option } = Select;

const children: React.ReactNode[] = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const { Title } = Typography;
type variantTypes = 'user' | 'search' | 'none';
type selectorSizes = 'large' | 'middle' | 'small';

interface IMultiSelect {
  placeholderText?: string;
  variant?: variantTypes;
  showClearIcon?: boolean;
  size?: selectorSizes;
  title?: string;
  onHandleChange(value: string[]);
  children?: React.ReactNode[];
  propClass?: string;
  optionFilterProp?: string;
  wrapperClass?: string;
  values?: string[];
  selectIcon?: React.ReactNode;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  dropdownClassName?: string;
  menuItemSelectedIcon?: React.ReactNode;
  filterOption?: (inputValue: string, option: React.ReactNode) => boolean;
  showSearchPlaceholder?: boolean;
  tagRender?:
    | ((props: any) => React.ReactElement<any, string | React.JSXElementConstructor<any>>)
    | undefined;
  allowClear?: boolean;
  onSearch?: (value: string) => void;
  onBlur?: React.FocusEventHandler<HTMLElement> | undefined;
}

const defaultProps: IMultiSelect = {
  placeholderText: '',
  variant: 'none',
  size: 'middle',
  title: '',
  onHandleChange: (value: string[]) => {},
  children: [],
  propClass: '',
  wrapperClass: '',
  values: [],
  selectIcon: undefined,
  dropdownClassName: '',
  menuItemSelectedIcon: null,
  filterOption: undefined,
  showClearIcon: false,
  showSearchPlaceholder: true,
  tagRender: undefined,
  allowClear: true,
  onSearch: undefined,
  onBlur: undefined,
};

export const MultiSelect: React.FC<IMultiSelect> = ({
  size,
  variant,
  children,
  placeholderText,
  title,
  propClass,
  onHandleChange,
  optionFilterProp,
  wrapperClass,
  values,
  selectIcon,
  getPopupContainer,
  dropdownClassName,
  menuItemSelectedIcon,
  filterOption,
  showClearIcon,
  showSearchPlaceholder,
  tagRender,
  allowClear,
  onSearch,
  onBlur,
}) => (
  <div className={classNames(styles.multiSelectWrapper, wrapperClass)}>
    {title ? <Title level={4}>{title}</Title> : null}
    <Space direction="vertical" style={{ width: '100%', height: '90%' }}>
      <Select
        filterOption={filterOption}
        showSearch
        size={size}
        mode="multiple"
        showArrow
        className={classNames(styles.multiSelect, propClass)}
        allowClear={allowClear}
        style={{ width: '100%', height: '30%', borderRadius: '5px' }}
        placeholder={
          variant ? (
            <React.Fragment>
              <Row
                justify="start"
                align="middle"
                className={classNames(styles.multiSelectPlaceholder)}
              >
                {showSearchPlaceholder ? <Icons variant={variant} /> : <></>}
                <span style={{ paddingLeft: '5px' }}>{placeholderText}</span>
              </Row>
            </React.Fragment>
          ) : (
            <span>{placeholderText}</span>
          )
        }
        value={values}
        defaultValue={[]}
        onChange={onHandleChange}
        optionFilterProp={optionFilterProp}
        maxTagCount="responsive"
        popupClassName={classNames(styles.multiSelectDropdown, dropdownClassName)}
        suffixIcon={selectIcon}
        getPopupContainer={getPopupContainer}
        menuItemSelectedIcon={menuItemSelectedIcon}
        clearIcon={showClearIcon ? <Icons variant={'clear'} /> : null}
        {...(tagRender && { tagRender })}
        {...(onSearch && { onSearch })}
        {...(onBlur && { onBlur })}
      >
        {children}
      </Select>
    </Space>
  </div>
);

MultiSelect.defaultProps = defaultProps;

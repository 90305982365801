import { ArrowDown, CheckBox } from 'components/Icons';
import { Select, SelectProps, OptionType, Option } from 'components-antd';

import styles from './styles.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getUserId } from 'store/selectors/user';
import { useMemo } from 'react';
import { find, get } from 'lodash-es';

interface PermissionSelectorProps extends SelectProps {
  permissionOptions: OptionType[];
  permittedUsers: string[];
  setPermittedUsers: (permittedUsers: string[]) => void;
  permissionSelectClassName?: string;
}

export const selectAllOption = {
  key: 'everyone',
  value: '*',
  label: 'Everyone can see',
};

export const selectJustMeOption = {
  key: 'justme',
  value: '!',
  label: 'Me',
};

export const PermissionSelector = ({
  permissionOptions,
  permittedUsers,
  setPermittedUsers,
  permissionSelectClassName,
}: PermissionSelectorProps) => {
  const userId = useSelector(getUserId);

  const participantIds = permissionOptions?.map((user) => user.key);

  const handlePermissionChange = (selectedOptions) => {
    let updatedOptions = [...selectedOptions];

    if (
      selectedOptions?.filter((data) => data !== '*').length >= 1 &&
      selectedOptions[selectedOptions.length - 1] === selectAllOption.value
    ) {
      updatedOptions = [...participantIds, selectJustMeOption.value, selectAllOption.value];
    } else if (
      selectedOptions?.length > 1 &&
      !selectedOptions.includes(selectAllOption.value) &&
      selectedOptions[selectedOptions.length - 1] === selectJustMeOption.value
    ) {
      // updatedOptions = selectedOptions?.filter((data) => data === '!'); - It is not adding ME user
      updatedOptions = [selectJustMeOption.value, userId];
    } else if (!selectedOptions.includes(selectAllOption.value) && !selectedOptions?.length) {
      updatedOptions = [];
    }

    setPermittedUsers(updatedOptions);
  };

  const filteredLoggedInUser = useMemo(
    () => permissionOptions.filter((user) => user?.value !== userId),
    [permissionOptions, userId],
  );

  const renderOption = (option, i) => {
    return (
      <Option
        label={option.label}
        key={`${option.value}-option-${i}`}
        value={option.value}
        disabled={disabledOptions(option)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {option.label}
          {permittedUsers.includes(option.value) && (
            <CheckBox
              stroke={
                (permittedUsers.includes(selectAllOption.value) &&
                  option.value !== selectAllOption.value) ||
                option.value === selectJustMeOption.value
                  ? '#fa9daa'
                  : '#ff576d'
              }
              className={styles.checkBoxIcon}
            />
          )}
        </div>
      </Option>
    );
  };

  const disabledOptions = (option) =>
    (permittedUsers.includes(selectAllOption.value) && option.value !== selectAllOption.value) ||
    option.value === selectJustMeOption.value;

  return (
    <Select
      size="large"
      mode="multiple"
      suffixIcon={<ArrowDown color="#ADADAD" />}
      placeholder="Only you can access"
      className={classNames(styles.permissionSelector, permissionSelectClassName)}
      showArrow={true}
      value={permittedUsers}
      onChange={handlePermissionChange}
      tagRender={(props) => {
        const { value } = props;
        const label = get(find(filteredLoggedInUser, ['value', value]), 'label');
        const index = permittedUsers.indexOf(value);
        const isJustMeIncluded = permittedUsers.includes(selectJustMeOption.value);
        const isEveryoneIncluded = permittedUsers.includes(selectAllOption.value);
        const isLastItem = index === permittedUsers.length - 1;

        if (
          value === selectJustMeOption.value &&
          permittedUsers.length - 2 < participantIds.length
        ) {
          return (
            <span className={styles.customTag}>
              Me
              {isJustMeIncluded && (permittedUsers.length === 1 || permittedUsers[1] === userId) ? (
                ''
              ) : (
                <>,&nbsp;</>
              )}
            </span>
          );
        }

        if (permittedUsers.length - 1 > participantIds.length)
          return <span className={styles.customTag}>{index < 1 && 'Everyone can see'}</span>;

        if (index >= 2) return <></>;
        return (
          <span className={styles.customTag}>
            {label}
            {!isLastItem && (
              <span className={styles.closeIcon}>
                {index < permittedUsers.length - 1 && permittedUsers.length > 0 ? ', ' : ''}
              </span>
            )}
            {(index === 1 && isEveryoneIncluded && `${permittedUsers.length - 4} more`) ||
              (index === 1 &&
                !isEveryoneIncluded &&
                `${permittedUsers.length - 2 > 0 ? permittedUsers.length - 2 + ' more' : ''}`)}
            {isJustMeIncluded ? null : (
              <>
                {isLastItem ? ', ' : ''}
                {`${label}`}
              </>
            )}
          </span>
        );
      }}
      menuItemSelectedIcon={<></>}
      defaultValue={selectJustMeOption.value}
      popupClassName={styles.permissionOptions}
      filterOption={(input, option) =>
        String((option?.label as string) || '')
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      {[selectAllOption, selectJustMeOption, ...filteredLoggedInUser].map((option, i) => {
        return renderOption(option, i);
      })}
    </Select>
  );
};

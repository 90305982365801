import { ConfirmationModal, Option, Select } from 'components-antd';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getTransactionRolesDataDashboardSelector } from 'store/selectors/adminPanel';
import { Check } from 'components/Icons';

export const RoleSelectView = ({
  Name,
  roleAccess,
  TransactionRole,
  onChangeRoleAccess,
  roleId: selectedRoleId,
}) => {
  const [roleName, setRoleName] = useState('');

  const transactionRoles = useSelector(getTransactionRolesDataDashboardSelector);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const [roleConfirmId, setRoleConfirmId] = useState('');

  const modifyUserRole = (roleId) => {
    onChangeRoleAccess({ roleId, transactionRoleId: roleId, transactionAccess: roleAccess });
  };

  const onChangeRole = (roleId: string) => {
    if (selectedRoleId === roleId) return;

    if (!TransactionRole?.Id) {
      modifyUserRole(roleId);
    } else {
      const roleName = transactionRoles.find(({ Id }) => Id === roleId)?.DisplayTitle;
      setRoleName(roleName);
      setRoleConfirmId(roleId);
      setConfirmationOpen(true);
    }
  };

  const getOptions = () => {
    const options: Array<{ label: string; value: number }> = [];

    transactionRoles?.forEach((el) => {
      options.push({ label: el.DisplayTitle, value: el.Id });
    });

    return options;
  };

  const hideConfirmation = () => {
    setConfirmationOpen(false);
    setRoleConfirmId('');
  };

  const onRoleUpdateConfirmation = () => {
    hideConfirmation();
    modifyUserRole(roleConfirmId);
  };

  return (
    <div className={styles.roleSelectViewWrapper}>
      <Select
        showSearch
        className={`${styles.inputHolder}`}
        id="roleNameSelect"
        value={selectedRoleId}
        placement="bottomLeft"
        getPopupContainer={(node) => node}
        popupClassName={`${styles.inputHolderDropdown} mosaikSelectDropdown`}
        onChange={onChangeRole}
        filterOption={(input, option: any) => {
          return option?.label?.toLowerCase().includes(input.toLowerCase());
        }}
        placeholder="Select role"
      >
        {getOptions().map(({ label, value }) => (
          <Option key={value} value={value}>
            {label}
            {selectedRoleId === value ? <Check className={styles.checkIcon} /> : <></>}
          </Option>
        ))}
      </Select>

      <ConfirmationModal
        width={430}
        className={styles.roleModal}
        confirmText={`Are you sure you want to assign "${roleName}" transaction role to ${Name}?`}
        open={confirmationOpen}
        onOk={onRoleUpdateConfirmation}
        onCancel={hideConfirmation}
        closable={false}
        aria-modal="roleSelectModal"
      />
    </div>
  );
};

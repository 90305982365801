import { FC, useState } from 'react';
import styles from './styles.module.scss';
import { AntCard } from 'components-antd';
import { Tabs } from 'components-antd';
import { Sale } from './Sale';
import { Tax } from './Tax';
import { IAssessmentHistory, ISaleHistory } from 'store/reducers/radarPortfolio/types';

interface IHistoryCard {
  tax: IAssessmentHistory[];
  sale: ISaleHistory[];
}

export const HistoryCard: FC<IHistoryCard> = ({ tax, sale }) => {
  const [activeTab, setActiveTab] = useState('saleHistory');

  const tabs = [
    ...(sale?.length
      ? [
          {
            key: 'saleHistory',
            id: 'saleHistory',
            label: 'Sale History',
            children: <Sale data={sale} />,
          },
        ]
      : []),
    ...(tax?.length
      ? [
          {
            key: 'taxHistory',
            id: 'taxHistory',
            label: 'Tax History',
            children: <Tax data={tax} />,
          },
        ]
      : []),
  ];

  return (
    <AntCard className={styles.card}>
      <Tabs
        className={styles.tabs}
        items={tabs}
        defaultActiveKey={'saleHistory'}
        onTabClick={(id) => {
          setActiveTab(id);
        }}
      />
    </AntCard>
  );
};

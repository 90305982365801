import { Modal } from 'components-antd';

import Icon from 'pages/Workshop/Transactions/TransactionDocuments/Icons';
import { DocumentModalHeader } from './DocumentModalHeader';
import styles from './styles.module.scss';

type DocumentsContentModalType = {
  open: boolean;
  onClose: () => void;
  children: any;
  title: string;
};

export const DocumentsContentModal = (props: DocumentsContentModalType) => {
  const { open, onClose, children, title } = props;

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        width={675}
        footer={null}
        onCancel={() => {
          onClose();
        }}
        maskClosable={false}
        destroyOnClose
        className={styles.documentsContentModal}
        closeIcon={<Icon variant={Icon.CLOSE} />}
        maskStyle={{
          backdropFilter: 'blur(6px)',
        }}
      >
        <DocumentModalHeader heading={title} />
        {children}
      </Modal>
    </div>
  );
};

import { FC, memo } from 'react';

import { Filter, FilterProps } from './components';

import styles from './styles.module.scss';
import moment from 'moment';
import { icons } from './components/Filter/constants';
import { Categories } from './components/Filter/types';
import { useSelector } from 'react-redux';
import { getNeighborhoodActivityDataSelector } from '../../../../store/selectors/intel';
import {
  NeighborhoodCategoryTypes,
  NeighborhoodFilterTypes,
  NeighborhoodStatusTypes,
} from '../../../Radar/types';
import { Icons, IconVariant } from '../icons';
import { ImageGrid } from './components/ImageGrid';
import { Wrapper } from 'components';
import { openNewTab } from 'services/newTab';

const capitalizeString = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

const formatPrice = (price: string) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(Number(price));

export interface NeighborhoodTabProps extends Pick<FilterProps, 'onFilterChange'> {}

export const NeighborhoodTab: FC<NeighborhoodTabProps> = memo((props: NeighborhoodTabProps) => {
  const { onFilterChange } = props;

  const { neighborhoodActivityData, isPending } = useSelector(getNeighborhoodActivityDataSelector);

  const getBackground = (category: string) => {
    switch (category) {
      case 'New':
        return icons[Categories.NEW_LISTING].bgColor;
      case 'Status Changes':
        return icons[Categories.BACK_ON_THE_MARKET].bgColor;
      case 'Price Reductions':
        return icons[Categories.PRICE_REDUCTION].bgColor;
      case 'Price Increase':
        return icons[Categories.PRICE_INCREASE].bgColor;
      case 'Back on Market':
        return icons[Categories.BACK_ON_THE_MARKET].bgColor;
      case 'Open Houses':
        return icons[Categories.NEW_LISTING].bgColor;
      default:
        return icons[Categories.BACK_ON_THE_MARKET].bgColor;
    }
  };

  const mapCategoryAndStatusToIcon = (category: string, status: string) => {
    switch (category) {
      case NeighborhoodCategoryTypes.New:
        return <Icons variant={IconVariant.STATUS_NEW} />;
      case NeighborhoodCategoryTypes.StatusChanges:
        if (status === NeighborhoodStatusTypes.Sold) {
          return <Icons variant={IconVariant.STATUS_SOLD} />;
        }
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      case NeighborhoodCategoryTypes.PriceReductions:
        return <Icons variant={IconVariant.STATUS_PRICE_REDUCTIONS} />;
      case NeighborhoodCategoryTypes.PriceIncrease:
        return <Icons variant={IconVariant.STATUS_PRICE_INCREASE} />;
      case NeighborhoodCategoryTypes.BackOnMarket:
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      case NeighborhoodFilterTypes.WITHDRAWN:
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      case NeighborhoodFilterTypes.UNDER_CONTRACT:
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      case NeighborhoodFilterTypes.EXPIRED:
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      case NeighborhoodCategoryTypes.OpenHouses:
        return <Icons variant={IconVariant.STATUS_CHANGE} />;
      default:
        return null;
    }
  };

  const mapCategoryAndStatusToText = (category: string, status: string) => {
    switch (category) {
      case NeighborhoodCategoryTypes.PriceReductions:
        return 'Price Reduction';
      case NeighborhoodCategoryTypes.OpenHouses:
        return 'Open House';
      case NeighborhoodCategoryTypes.New:
        return 'New Listing';
      case NeighborhoodCategoryTypes.StatusChanges:
        switch (status) {
          case NeighborhoodStatusTypes.ActiveUnderContract:
            return 'Under Contract';
          case NeighborhoodStatusTypes.ComingSoon:
            return 'Coming Soon';
          case NeighborhoodStatusTypes.AcceptingBackups:
            return 'Accepting Backups';
          default:
            return status;
        }
      case NeighborhoodCategoryTypes.BackOnMarket:
        return 'Back on the Market';
      default:
        return category;
    }
  };

  const renderPrice = (category: string, price: string, previousPrice: string) => {
    if (
      category === NeighborhoodCategoryTypes.PriceReductions ||
      category === NeighborhoodCategoryTypes.PriceIncrease
    ) {
      return (
        <p className={styles.price}>
          <span className={styles.previousPrice}>{previousPrice}</span>
          <span className={styles.arrow}>&rarr;</span>
          <span>{price}</span>
        </p>
      );
    }

    return <p className={styles.price}>{price}</p>;
  };

  return (
    <div className={styles.pageWrapper}>
      <Wrapper className={styles.leftWrapper} isPending={isPending}>
        {!isPending && !neighborhoodActivityData?.data?.results?.length ? (
          <div className={styles.noProperties}>
            <p className={styles.title}>You’re all caught up!</p>
            <p className={styles.subtitle}>There’s no new neighborhood activity.</p>
          </div>
        ) : null}
        {neighborhoodActivityData?.data?.results?.map((activity) => {
          return (
            <div
              className={styles.card}
              onClick={() => openNewTab(`/properties/search/listing-detail/${activity.ExternalId}`)}
            >
              <div className={styles.heading}>
                <div className={styles.info}>
                  <div className={styles.typeWrapper}>
                    <div
                      className={styles.iconWrapper}
                      style={{ background: getBackground(activity.Category) }}
                    >
                      {mapCategoryAndStatusToIcon(activity.Category, activity.ActivityStatus)}
                    </div>
                    <div className={styles.type}>
                      {mapCategoryAndStatusToText(activity.Category, activity.ActivityStatus)}
                    </div>
                  </div>
                  <div className={styles.timeFromNow}>
                    {moment(activity.Updated || activity.CreatedDate).fromNow()}
                  </div>
                </div>
                <div className={styles.details}>
                  {renderPrice(
                    activity.Category,
                    formatPrice(activity.ListPrice),
                    formatPrice(activity.PreviousListPrice ?? '0'),
                  )}
                  <div className={styles.subDetails}>
                    <div>
                      <p className={styles.address}>{capitalizeString(activity.Address.Line1)}</p>
                      <p className={styles.addressSecondary}>{`${capitalizeString(
                        activity.Address.City,
                      )}, ${activity.Address.State} ${activity.Address.Zip}`}</p>
                    </div>
                    <div className={styles.distanceContainer}>
                      <Icons variant={IconVariant.MARKER} />
                      <p className={styles.distance}>
                        {activity.AddressDistance.toFixed(1) + ' mi'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {!!activity.PhotoUrls.length && (
                <ImageGrid
                  images={activity.PhotoUrls}
                  address={capitalizeString(activity.Address.Line1)}
                  price={formatPrice(activity.ListPrice)}
                />
              )}
            </div>
          );
        })}
      </Wrapper>
      <div className={styles.rightWrapper}>
        <Filter onFilterChange={onFilterChange} />
      </div>
    </div>
  );
});

import { useEffect, useState } from 'react';
import { AgentMemberSearchFilter, AgentMembersTable } from './components';

import { MemberTypes } from 'app-constants/enums/permissionSettings';
import { useDispatch } from 'react-redux';
import {
  getAllTransactionRolesDashboardEffect,
  getTeamTransactionPermissionsEffect,
} from 'store/effects';

export const AgentTeamMembers = () => {
  const [searchValue, setSearchValue] = useState('');
  const [memberType, setMemberType] = useState(MemberTypes.Admins);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const fetchTransactionPermisions = () => {
    setLoading(true);
    dispatch(
      getTeamTransactionPermissionsEffect({}, {}, () => {
        setLoading(false);
      }),
    );
  };

  useEffect(() => {
    dispatch(getAllTransactionRolesDashboardEffect());
    fetchTransactionPermisions();
  }, []);

  return (
    <div>
      <AgentMemberSearchFilter
        searchValue={searchValue}
        memberType={memberType}
        setMemberType={setMemberType}
        setSearchValue={setSearchValue}
      />

      <AgentMembersTable
        searchValue={searchValue}
        memberType={memberType}
        loadingMembers={loading}
        refetchPermissions={fetchTransactionPermisions}
      />
    </div>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Input, Modal } from 'components';
import { LocationService } from 'services';
import { appOpenModalEffect } from 'store/effects';
import { getModalSelector } from 'store/selectors/app';
import styles from './styles.module.scss';
import { useFormik } from 'formik';
import { schema } from './validation';
import {
  requestAddAvmAdjustmentEffect,
  requestAddValuationHomeValueEffect,
  requestAddValuationRentalValueEffect,
  requestCancelAvmAdjustmentEffect,
  requestEditAvmAdjustmentEffect,
} from 'store/effects/intel';
import { useEffect, useState } from 'react';
import { Checkbox } from 'components-antd';
import { Info } from 'components/Icons';
import { Tooltip } from 'components-antd';
import classNames from 'classnames';
import { requestAvmAdjustmentEffect } from 'store/effects/radarPortfolio';

const locationSrv = new LocationService();

const ValuesModal = ({ rental, params, avmData }) => {
  locationSrv.setLocation(location);
  const modal = useSelector(getModalSelector);
  const [isPending, setIsPending] = useState(false);
  const [mode, setMode] = useState('manual');
  const isAVM = mode === 'avm';

  const avmDataPresent = avmData?.length > 0;

  const dispatch = useDispatch();
  const label = `${avmDataPresent ? 'Edit' : 'Add'} ${rental ? 'Rental' : 'Home'} Value`;

  const onClose = () => {
    resetForm();
    dispatch(appOpenModalEffect({ id: null, open: false }));
  };

  const onCallback = () => {
    setIsPending(false);
    onClose();
  };

  const skip = modal?.id !== (rental ? 'valuesModalRental' : 'valuesModalHome');

  // useEffect(() => {
  //   dispatch(requestAvmAdjustmentEffect(params.addressId));
  // }, []);

  const formik = useFormik({
    initialValues: {
      value: avmData?.AdjustmentValuation || undefined,
      month: avmData?.Month || undefined,
      year: avmData?.Year || undefined,
      notes: '',
      Operation: avmData?.Operation || '+',
      IsRecurring: false,
    },
    validateOnMount: true,
    validationSchema: schema,
    onSubmit: ({ value, month, year, notes, Operation }) => {
      let cfg;
      setIsPending(true);
      if (avmDataPresent) {
        cfg = {
          body: {
            AdjustmentValuation: Number(value),
          },
          id: avmData?.Id,
        };
        dispatch(requestEditAvmAdjustmentEffect(cfg, {}, onCallback));
      } else if (isAVM) {
        const { address1, address2, addressId, clientId } = params;
        cfg = {
          body: {
            Address1: address1,
            Address2: address2,
            AddressId: addressId,
            ClientId: clientId,
            AdjustmentValuation: Number(value),
            Month: month,
            Year: year,
            Notes: notes,
            Operation,
            IsRental: rental,
          },
        };
        dispatch(requestAddAvmAdjustmentEffect(cfg, {}, onCallback));
      } else {
        cfg = {
          body: { value: Number(value), month, year, notes },
          params: new URLSearchParams(params).toString(),
        };
        if (rental) {
          dispatch(requestAddValuationRentalValueEffect(cfg, {}, onCallback));
        } else {
          dispatch(requestAddValuationHomeValueEffect(cfg, {}, onCallback));
        }
      }
    },
  });

  const { values, isValid, setFieldValue, handleSubmit, handleChange, handleBlur, resetForm } =
    formik;

  if (skip) return null;

  return (
    <Modal
      isOpen={modal.open}
      onClose={onClose}
      opacityLayerClassName={styles.opacityLayer}
      contentClassName={styles.modal}
      innerHolderClassName={styles.innerHolder}
    >
      <div className={styles.drawerInner}>
        <div className={styles.header}>
          <p className={styles.line1}>{label}</p>
          <p className={styles.line2}>{params?.address1}</p>
          <form className={styles.content} onSubmit={handleSubmit}>
            {/* {!avmDataPresent && (
              <div className={classNames(styles.marginTop, styles.checkboxes)}>
                <Checkbox
                  className="mosaikCheckboxRounded"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMode('manual');
                    }
                  }}
                  checked={!isAVM}
                >
                  <div className={styles.checkboxLabel}>
                    Manual{' '}
                    <Tooltip
                      placement="top"
                      overlayClassName={classNames(styles.tooltip, 'mosaikTooltip')}
                      title={
                        <p className={styles.descInfo}>
                          {
                            "Please use this option if you'd like to enter a specific value for the property."
                          }
                        </p>
                      }
                    >
                      <div className={styles.infoIcon}>
                        <Info size={'16'} />
                      </div>
                    </Tooltip>
                  </div>
                </Checkbox>
                <Checkbox
                  className="mosaikCheckboxRounded"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setMode('avm');
                    }
                  }}
                  checked={isAVM}
                >
                  <div className={styles.checkboxLabel}>
                    AVM Adjustment{' '}
                    <Tooltip
                      placement="top"
                      overlayClassName={classNames(styles.tooltip, 'mosaikTooltip')}
                      title={
                        <p className={styles.descInfo}>
                          {
                            "Please use this option if you'd like to add a value for the property using the AVM value as a basis, with your own adjustment factor applied."
                          }
                        </p>
                      }
                    >
                      <div className={styles.infoIcon}>
                        <Info size={'16'} />
                      </div>
                    </Tooltip>
                  </div>
                </Checkbox>
              </div>
            )} */}
            {isAVM || avmDataPresent ? (
              <Input
                label={'Adjustment Factor'}
                variant={Input.LIGHT_ROUND}
                name="value"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.value}
                placeholder="$0"
                isNumberFormat
                prefix={'$'}
                numberInputClassName={styles.adjustmentFactorInput}
                inputHolderClassName={styles.adjustmentFactor}
                iconLeft={
                  <div className={styles.factorIcon}>
                    <div
                      className={classNames(styles.icon, {
                        [styles.active]: values.Operation === '+',
                      })}
                      onClick={() => setFieldValue('Operation', '+')}
                    >
                      +
                    </div>
                    <div
                      className={classNames(styles.icon, {
                        [styles.active]: values.Operation === '-',
                      })}
                      onClick={() => setFieldValue('Operation', '-')}
                    >
                      -
                    </div>
                  </div>
                }
              />
            ) : (
              <Input
                label={'Value'}
                variant={Input.LIGHT_ROUND}
                name="value"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.value}
                placeholder="$0"
                isNumberFormat
                prefix={'$'}
              />
            )}
            {avmDataPresent && (
              <div className={styles.cancelContainer}>
                <Checkbox className="mosaikCheckboxRounded" checked>
                  <div className={styles.checkboxLabel}>
                    Recurring{' '}
                    <Tooltip
                      placement="top"
                      overlayClassName={classNames(styles.tooltip, 'mosaikTooltip')}
                      title={
                        <p className={styles.descInfo}>
                          {
                            'If you select this option, every time a new AVM value is generated for this property (typically monthly), the adjustment factor you set will be applied.'
                          }
                          <br />
                          <br />
                          {
                            'That value (AVM + or - adjustment factor) will automatically display as your agent value for hte property.'
                          }
                        </p>
                      }
                    >
                      <div className={styles.infoIcon}>
                        <Info size={'16'} />
                      </div>
                    </Tooltip>
                  </div>
                </Checkbox>
                <div
                  className={styles.cancel}
                  onClick={() => {
                    setIsPending(true);
                    dispatch(requestCancelAvmAdjustmentEffect(avmData?.Id, {}, onCallback));
                  }}
                >
                  Cancel Recurring
                </div>
              </div>
            )}
            {!avmDataPresent && (
              <DatePicker
                onChange={(e) => {
                  const date = new Date(e.target.value[0]);
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  setFieldValue('month', month);
                  setFieldValue('year', year);
                }}
                placeholder="MM/YY"
                variant={DatePicker.ROUNDED}
                label="Month"
                className={styles.datePicker}
                options={{
                  enableTime: false,
                  dateFormat: 'm-Y',
                  mode: 'single',
                  monthSelectorType: 'static',
                  maxDate: 'today',
                }}
                monthOnly={true}
                closeOnOutsideClick={true}
              />
            )}
            {isAVM && !avmDataPresent ? (
              <div className={styles.checkboxes}>
                <Checkbox
                  className="mosaikCheckboxRounded"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('IsRecurring', false);
                    }
                  }}
                  checked={!values.IsRecurring}
                >
                  <div className={styles.checkboxLabel}>One-Time</div>
                </Checkbox>
                <Checkbox
                  className="mosaikCheckboxRounded"
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('IsRecurring', true);
                    }
                  }}
                  checked={values.IsRecurring}
                >
                  <div className={styles.checkboxLabel}>
                    Recurring{' '}
                    <Tooltip
                      placement="top"
                      overlayClassName={classNames(styles.tooltip, 'mosaikTooltip')}
                      title={
                        <p className={styles.descInfo}>
                          {
                            'If you select this option, every time a new AVM value is generated for this property (typically monthly), the adjustment factor you set will be applied.'
                          }
                          <br />
                          <br />
                          {
                            'That value (AVM + or - adjustment factor) will automatically display as your agent value for hte property.'
                          }
                        </p>
                      }
                    >
                      <div className={styles.infoIcon}>
                        <Info size={'16'} />
                      </div>
                    </Tooltip>
                  </div>
                </Checkbox>
              </div>
            ) : null}
            {!avmDataPresent && (
              <Input
                variant={Input.LIGHT_ROUND}
                name="notes"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
                label="Notes"
                placeholder="Add note..."
                textArea={true}
              />
            )}
            <Button
              title="Done"
              type="submit"
              className={styles.button}
              disabled={!isValid}
              isPending={isPending}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ValuesModal;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawingManager, Polygon } from '@react-google-maps/api';
import { Modal } from 'components-antd';
import classNames from 'classnames';

import { getFeedIsMapDrawingModeSelector } from 'store/selectors/feed';
import { MapDrawControlsFeedWrapper } from 'pages/Properties/Feed/components/MapDrawControlsFeedWrapper';

import { Locations, Map } from 'components';
import { MapDraw } from 'components/Icons/MapDraw';
import Button from 'components/Button';
import styles from './styles.module.scss';
import { getZoomForBounds } from './helper';
import { LocationType } from 'types';
import { toggleFeedMapDrawingModeAction } from 'store/actions/feed';
import { cloneDeep } from 'lodash-es';
import {
  getAgentPropertiesMarkersDrawSelector,
  getSavedSearchesPropertiesMarkersDrawSelector,
} from 'store/selectors/feedv3';
import { useAgentSearchInstance } from 'pages/Properties/AgentFeed/hooks/useAgentSearchInstance';
import { useSearchInstanceV3Effect } from 'pages/Properties/ClientFeed/hooks/useSearchInstanceV3Effect';
import { getUserRolesMapSelector } from 'store/selectors/user';

const CustomLocationModal = (props) => {
  const dispatch = useDispatch();
  const {
    setIsModalOpen,
    isModalOpen,
    onResult,
    multipleDraw,
    polygonLocations,
    setPolygonLocations,
    showDrawMarkers,
  } = props;
  const [initialValue] = useState(cloneDeep(polygonLocations));
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [location, setLocation] = useState<any>(null);
  const isDrawingMode = useSelector(getFeedIsMapDrawingModeSelector);
  const [worldDim, setWorldDim] = useState({ height: 256, width: 256 });
  const [center, setCenter] = useState({ lat: 39.8283, lng: -98.5795 });
  const [mapZoom, setMapZoom] = useState(4);
  const drawingManager = useRef<google.maps.drawing.DrawingManager>();
  const [showDrawHint, setShowDrawHint] = useState<boolean>(true);

  const { isAgent } = useSelector(getUserRolesMapSelector);
  const { agentPropertiesMarkers, isPending: isPendingAgent } = useSelector(
    getAgentPropertiesMarkersDrawSelector,
  );
  const getAgentSearchInstance = useAgentSearchInstance();
  const { propertiesMarkers, isPending: isPendingClient } = useSelector(
    getSavedSearchesPropertiesMarkersDrawSelector,
  );
  const getSearchInstanceV3 = useSearchInstanceV3Effect();

  const handleSave = () => {
    onResult(polygonLocations);
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setPolygonLocations(initialValue);
    setIsModalOpen(false);
  };

  const polygonOptions: google.maps.PolygonOptions = {
    strokeColor: '#252D44',
    fillColor: '#252D44',
    fillOpacity: 0.08,
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          setMapZoom(12);
        },
        (error) => {
          console.error('Error retrieving location:', error);
        },
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    if (polygonLocations && polygonLocations.length > 0) setCenterForCustomPolygon();
    else getCurrentLocation();

    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      // Set up a listener to detect permission state changes
      permissionStatus.onchange = () => {
        if (permissionStatus.state === 'granted') {
          getCurrentLocation();
        }
      };
    });
  }, []);

  useEffect(() => {
    if (polygon) {
      polygon.setMap(null);
    }
    if (polygonLocations?.length && !isDrawingMode) {
      setPolygon(geoJsonPolygonToGmaps(polygonLocations?.[0]?.Polygon));
    }
  }, [polygonLocations]);

  useEffect(() => {
    if (isDrawingMode) {
      if (polygon) {
        polygon.setMap(null);
      }
      setPolygon(null);
    }
  }, [isDrawingMode]);

  const setCenterForCustomPolygon = () => {
    const mapContainer = document.querySelector(`.${styles.mapWrapper}`) as HTMLElement;
    const bounds = new window.google.maps.LatLngBounds();
    let latSum = 0;
    let lngSum = 0;
    let totalPoints = 0;

    if (mapContainer) {
      setWorldDim({
        height: mapContainer.offsetHeight,
        width: mapContainer.offsetWidth,
      });
    }

    polygonLocations.forEach(({ Polygon: polygon }) => {
      const { coordinates } = polygon;
      if (coordinates !== null) {
        coordinates[0].forEach((coord) => {
          lngSum += coord[0];
          latSum += coord[1];
          totalPoints++;
          bounds.extend(new window.google.maps.LatLng(coord[1], coord[0]));
        });
      }
    });

    if (totalPoints > 0) {
      const center = {
        lat: latSum / totalPoints,
        lng: lngSum / totalPoints,
      };
      const zoom = getZoomForBounds(bounds, worldDim);
      setMapZoom(zoom);
      setCenter(center);
    }
  };

  function geoJsonPolygonToGmaps(geoJsonPolygon: any): google.maps.Polygon {
    const gmapsPolygon = new google.maps.Polygon();
    const coordinates = geoJsonPolygon.coordinates[0]
      .slice(0, -1) // remove the last point which is the same as the first in geojson
      .map(([lng, lat]) => new google.maps.LatLng(lat, lng));
    gmapsPolygon.setPaths(coordinates);
    return gmapsPolygon;
  }

  const handlerLoadDrawingManager = (drawingManagerInstance) => {
    drawingManager.current = drawingManagerInstance;
  };

  const handlePolygonComplete = async (polygon) => {
    const geojsonPolygon: any = await gmapsPolygonToGeoJson(polygon);
    if (geojsonPolygon?.coordinates?.[0]?.length < 4) {
      polygon.setMap(null);
      setPolygon(null);
      return;
    }
    setPolygon(polygon);
    const newPolygon = {
      Type: LocationType.Polygon,
      Polygon: geojsonPolygon,
    };
    const Locations = [...polygonLocations, newPolygon];
    setPolygonLocations(Locations);
    dispatch(toggleFeedMapDrawingModeAction(false));
    setCenterForCustomPolygon();
    fetchProperties(Locations);
  };

  const fetchProperties = (Locations) => {
    if (Locations?.length && showDrawMarkers) {
      const body = {
        criterias: { Locations },
      };
      isAgent
        ? getAgentSearchInstance(body, undefined, undefined, undefined, undefined, undefined, true)
        : getSearchInstanceV3(body, undefined, undefined, true);
    }
  };

  async function gmapsPolygonToGeoJson(polygon: google.maps.Polygon): Promise<object> {
    var dataLayer = new window.google.maps.Data();
    dataLayer.add(
      new window.google.maps.Data.Feature({
        geometry: new window.google.maps.Data.Polygon([polygon.getPath().getArray()]),
      }),
    );

    return new Promise((resolve) => {
      dataLayer.toGeoJson((geoJsonValue) => {
        const firstFeature = (geoJsonValue as any).features[0];
        const geojsonPolygon = {
          type: 'Polygon',
          coordinates: firstFeature.geometry.coordinates,
        };
        resolve(geojsonPolygon);
      });
    });
  }

  const loading = isPendingAgent || isPendingClient;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={handleClose}
      className={styles.inviteModal}
      cancelButtonStyles={styles.crossButton}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.inviteHeader}>
        <MapDraw size={32} />
        <h2 className={styles.inviteHeading}>Draw Locations</h2>
      </div>
      <Locations
        name="locations"
        label={'Desired Locations'}
        placeholder="Search Locations"
        placeholderClassName={styles.border}
        searchWrapperClassName={styles.border}
        valuesWrapperClassName={styles.border}
        onResult={(_, preparedData) => {
          setPolygonLocations([]);
          setLocation(preparedData);
          setCenter(preparedData?.[0]?.Center);
          setMapZoom(12);
        }}
        testid="desired_locations"
        multiple={false}
        variant={Locations.ROUND}
        value={location || []}
        rounded={true}
        customLoading={loading}
      />
      <div>
        <div className={styles.mapWrapper}>
          <span
            onClick={() => {
              setShowDrawHint(false);
            }}
          >
            <Map
              popupClassName={styles.popupClassName}
              center={center}
              mapContainerClassName={styles.map}
              zoom={mapZoom}
              controls={{ center: true }}
              noFitBounds={!showDrawMarkers}
              markersMap={
                polygonLocations?.length && showDrawMarkers && !loading && !isDrawingMode
                  ? isAgent
                    ? agentPropertiesMarkers
                    : propertiesMarkers
                  : []
              }
            >
              <DrawingManager
                onLoad={handlerLoadDrawingManager}
                drawingMode={isDrawingMode ? window.google.maps.drawing.OverlayType.POLYGON : null}
                onPolygonComplete={handlePolygonComplete}
                options={{
                  polygonOptions,
                  drawingControl: false,
                  drawingControlOptions: {
                    drawingModes: [window.google.maps.drawing.OverlayType.POLYGON], // Enable drawing polygons
                  },
                }}
              />
              {polygonLocations?.length && (
                <Polygon
                  paths={polygonLocations.map(({ Polygon }) =>
                    geoJsonPolygonToGmaps(Polygon).getPath(),
                  )}
                  options={polygonOptions}
                />
              )}
            </Map>
          </span>
          <div className={styles.mapControls}>
            <div className={styles.mapControlContainer}>
              {!loading && (
                <MapDrawControlsFeedWrapper
                  multipleDraw={multipleDraw}
                  setPolygonLocations={setPolygonLocations}
                  polygonLocations={polygonLocations}
                  showDrawHint={showDrawHint}
                  setShowDrawHint={setShowDrawHint}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          onClick={() => handleSave()}
          className={classNames(styles.button, styles.deleteButton)}
          title="Save"
        />
      </div>
    </Modal>
  );
};

export default CustomLocationModal;
